import React, { useContext, useState, useEffect, useRef } from 'react';
import TrackList from './TrackList'
import withRouter from 'components/helpers/withRouter';
import { WidgetHead } from 'components/common/DisplayElems';
import { TracksContext } from 'context/TracksContext';
import { BounceLoader } from "react-spinners";
import AlbumList from './Album/AlbumList';

function UpdateBanner({setIsBannerTracksUpdating, banner, setOffset}) {
    const { requestUpdateBannerTracks, removeUpdateBannerTrack, bannerTracksUp, resetUpdateBannerTrack, isBannerTrackUpdating, isBannerAdded, createBanner, removeBannerTrack, bannerTracks} = useContext(TracksContext);

    const handelRemoveTrack = (track) => {
        removeUpdateBannerTrack(track)
    }
    const handleCancel = () => {
        setIsBannerTracksUpdating(false);
        resetUpdateBannerTrack();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Construct the data object to send to API
        const formData = {
            bannerId: banner.id,
            setIsBannerTracksUpdating
        };
        setOffset(0);
        // Call API submission function from context
        requestUpdateBannerTracks(formData);
    };

    // useEffect(() => {
    //     if (isBannerAdding === true) {
    //         //getTracks(fields);
    //     }
    // }, [isBannerAdding]);

    return <>
        <div className="col-xl-12 m-0 p-0">
            {isBannerTrackUpdating && <>
                <div className='loading'>
                    <div className='loader'>
                        <BounceLoader loading={true} size={60} color='#34bfa3' />
                        <span className="loading-text">Loading...</span>
                    </div>
                </div>
            </>}
            <div className="m-0">
                {/* <WidgetHead label="Update Banner"/> */}
                <div className="d-flex m-3 p-1 pt-4 pb-4 banner-form">
                    <div className="col-xs-12 col-sm-12 col-lg-12 padding-remove">
                        <div className="row">
                            <div className="col-12" key={'_bannerTracks'}>
                                <div className="d-flex col-12 flex-wrap" key={'_bannerTracksBtn'}>
                                    
                                    {bannerTracksUp && bannerTracksUp.length > 0 && bannerTracksUp.map((track) => (
                                        <div className="button-container" key={track.id + '_bannerTracks'}>
                                            <button className="close-button" key={track.id + '_bannerTracksBtn'}> {track.songName || track.name} <span className="close-icon" onClick={() => handelRemoveTrack(track)} key={track.id + '_bannerTracksBtnX'}>×</span></button>
                                        </div>
                                    ))}
                                </div>
                                <div className="row mt-2 mb-0">
                                    <div className="col-lg-1">
                                        <button className="btn btn-sm btn-primary blue-button"
                                            disabled={(bannerTracksUp.length === 0)}
                                            type="submit" onClick={handleSubmit}>
                                            <strong>Update banner</strong>
                                        </button>
                                    </div>
                                    <div className="col-lg-2">
                                        <button className="btn btn-sm btn-danger"
                                            type="submit" onClick={handleCancel}>
                                            <strong>Cancel</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {banner.type === 'album' &&
                    <AlbumList setIsBannerTracksUpdating={true} />
                }
                {banner.type === 'tracks' &&
                    <TrackList setIsBannerTracksUpdating={true} />
                }
            </div>
        </div>
    </>
}

export default withRouter(UpdateBanner);
