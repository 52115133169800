import React from 'react';
import {createRequestHelper} from './helpers/requestHelper';
import consumeToContext from './helpers/consumeToContext';
import {AuthContext} from './AuthContext';

export const FeedbackContext = React.createContext();

const feedbackHelper = createRequestHelper('feedbackProfiles');
const starHelper = createRequestHelper('stars');

class FeedbackContextContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...feedbackHelper.initialState,
            ...starHelper.initialState,
        };

        this.funcs = {
            updateFeedback: this.updateFeedback,
            updateStar: this.updateStar,
            getFeedbacks: this.getFeedbacks,
            getStars: this.getStars
        };
    }

    updateStar = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/feedbackProfiles/star`,
                data
            });
            this.setState(starHelper.result(result));
        } catch (error) {
            this.setState(starHelper.error(error));
        }
    };

    updateFeedback = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/feedbackProfiles`,
                data
            });
            this.setState(feedbackHelper.result(result));
        } catch (error) {
            this.setState(feedbackHelper.error(error));
        }
    };

    getFeedbacks = async () => {
        this.setState(feedbackHelper.processing());
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'get',
                path: `/feedbackProfiles`
            });
            this.setState(feedbackHelper.result(result));
        } catch (error) {
            this.setState(feedbackHelper.error(error));
        }
    };

    getStars = async () => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'get',
                path: `/feedbackProfiles/stars`
            });
            this.setState(starHelper.result(result));
        } catch (error) {
            this.setState(starHelper.error(error));
        }
    };

    render() {
        return (
            <FeedbackContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </FeedbackContext.Provider>
        );
    }
}

export default consumeToContext('auth', AuthContext)(FeedbackContextContainer);


