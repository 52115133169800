import React, { useContext, useState, useEffect } from "react";
import classnames from 'classnames';

import { WidgetHead } from "components/common/DisplayElems";
import { TextField } from "components/common/Form";
import AddedTracks from "./AddedTracks";
import TrackList from "components/Widgets/Tracks/TrackList";

import { CuratedSetContext } from "context/CuratedSetContext";
import withRouter from 'components/helpers/withRouter';

function TracksContainer({onClose, curatedSet, history: routeHistory}) {
    const {
        addTrackToCuratedSet,
        removeTrackFromCuratedSet,
        getCuratedTracks,
        getDetailedCuratedTracks,
        curatedTracksResult,
        curatedDetailedTracksResult
    } = useContext(CuratedSetContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [
        currentRouteHistoryLocationKey,
        setCurrentRouteHistoryLocationKey
    ] = useState(null);
    const isRequiredToRedirectBack = () => currentRouteHistoryLocationKey &&
        currentRouteHistoryLocationKey !== routeHistory.location.key;
    useEffect(() => {
        if (currentRouteHistoryLocationKey === null) {
            setCurrentRouteHistoryLocationKey(
                routeHistory.location.key
            );
        }
        if (isRequiredToRedirectBack()) onClose();
    }, [routeHistory.location.key]);

    useEffect(() => {
        getCuratedTracks(curatedSet);
    }, [curatedSet]);

    useEffect(() => {
        getDetailedCuratedTracks(curatedSet.id);
    }, [curatedTracksResult]);

    const curatedTracks = (curatedTracksResult) && curatedTracksResult.map(el => el.trackId);
    const curatedTrackVersions = (curatedTracksResult) && curatedTracksResult.map(el => el.mediaId);

    const handleAttachTrack = (elem) => {
        if (curatedTracks.includes(elem.trackId)) {
            if (curatedTrackVersions.includes(elem.id)) {
                removeTrackFromCuratedSet({
                    mediaId: elem.id,
                    curatedSetId: curatedSet.curatedSetId
                });

                return;
            } else {
                const attachedTrack = curatedTracksResult.find(el => el.trackId === elem.trackId);
                removeTrackFromCuratedSet({
                    mediaId: attachedTrack.mediaId,
                    curatedSetId: curatedSet.curatedSetId
                });
            }
        }

        addTrackToCuratedSet({
            trackId: elem.trackId,
            mediaId: elem.id,
            curatedSetId: curatedSet.curatedSetId
        });
    };

    const subTableButtons = [
        {
            label: elem => curatedTrackVersions && curatedTrackVersions.includes(elem.id) ? 'Detach' : 'Attach',
            action: elem => handleAttachTrack(elem),
            className: elem => curatedTrackVersions && curatedTrackVersions.includes(elem.id) ? 'btn-danger' : 'btn-primary'
        }
    ];

    return (
        <> <div className="col-xl-12">
                <div className="m-portlet">
                    <WidgetHead
                        label={curatedSet.name}
                        button={{
                            label: "Close",
                            onClick: () => onClose()
                        }}
                    />

                    <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                        <div className="col-md-4">
                            <div>
                                <img
                                    key={curatedSet.image || 'nokey'}
                                    className={classnames("img-thumbnail rounded m-widget3__img")}
                                    style={{height: '200px', minWidth: '200px'}}
                                    src={curatedSet.image}
                                    alt="thumbnail"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <TextField
                                value={curatedSet.categoryName}
                                name="Category"
                                label="Category"
                                type="text"
                                disabled="disabled"
                            />
                            <TextField
                                value={curatedSet.genreName}
                                name="Genre"
                                label="Genre"
                                type="text"
                                disabled="disabled"
                            />
                        </div>
                    </div>

                    <AddedTracks
                        curatedDetailedTracksResult={curatedDetailedTracksResult}
                        subTableButtons={subTableButtons}
                    />

                </div>
            </div>

            <TrackList subTableButtons={subTableButtons}/> </>
    );
}

export default withRouter(TracksContainer);
