import React, {useState, useContext, useEffect} from 'react';
import classnames from 'classnames';
import {TracksContext} from 'context/TracksContext';
import {BaseDialog} from "components/common/Dialogs";
import {TextField} from "../../common/Form";
import styles from '../../common/Form/TextField.module.scss';

export default function UpdateTrackDialog({onClose, onSave, editedElem}) {
    if (editedElem && editedElem.status && editedElem.status.error) {
        onClose();
    }
    const {genresResult: {genres, versions},allPresets,fetchPresets,updateTracksNameGenre} = useContext(TracksContext);
    const initFields = editedElem;
    console.log(editedElem,"editedElem-----");
    const [fields, setFields] = useState(initFields);
    const [presets, setPresets] = useState([]);
    
    useEffect(()=>{
        if(typeof allPresets == 'undefined'){
            fetchPresets();
        }
    },[])
    useEffect(()=>{
        if(typeof allPresets != 'undefined' && presets.length == 0){
            console.log(presets,"allPresets-------11")
            setPresets(allPresets);
            console.log(allPresets,presets,"allPresets-------")
        }
    },[allPresets])
    console.log(presets,"allPresets-------221")
    console.log(fields,"fields-------221")
    return (
        <BaseDialog
            id="addNewVideoDialog"
            label="Update uploaded track's Metadata"
            onClose={()=>{onClose()}}>
            <form onSubmit={(e) => {
                e.preventDefault();
                updateTracksNameGenre(fields);
                onClose();
            }}>
                <div className="form-group">
                    <TextField
                        key="songName"
                        label="Song Name"
                        required
                        placeholder="Song Name"
                        name="songName"
                        defaultValue={fields.songName}
                        onChange={event => setFields({...fields, songName: event.target.value})}/>
                    <TextField
                        key="Artist"
                        label="Artist"
                        required
                        placeholder="Artist Name"
                        name="artist"
                        defaultValue={fields.artist}
                        onChange={event => setFields({...fields, artist: event.target.value})}/>
                    <div className={classnames(styles['form-text-field'], 'form-group m-form__group row ')}>
                        <label htmlFor="genre" className='col-12 mb-3'>Genre:</label>
                        <select
                            required
                            value={fields.genre || ""}
                            onChange={event => setFields({...fields, genre: event.target.value})}
                            className="form-control"
                            id="genre">
                            <option disabled value=""> -- select an option --</option>
                            {genres.map((genre,key) => <option key={key} value={genre}>{genre}</option>)}
                        </select>   
                    </div>
                    <div className={classnames(styles['form-text-field'], 'form-group m-form__group row ')}>
                        <label htmlFor="preset" className='col-12 mb-3'>Preset:</label>
                        <select
                            required
                            value={fields.presetId || ""}
                            onChange={event => setFields({...fields, preset: event.target.value})}
                            className="form-control"
                            id="preset">
                            <option disabled value=""> -- select an option --</option>
                            {(presets.length != 0) && presets.map((preset,key) => <option key={key} value={preset.id}>{preset.name}</option>)}
                        </select>
                    </div>
                    <TextField
                        required
                        key="BPM"
                        label="BPM"
                        placeholder="100"
                        type="number"
                        name="bpm"
                        defaultValue={fields.bpm}
                        onChange={event => setFields({...fields, bpm: event.target.value})}/>
                </div>
                <div className="d-flex flex-row justify-content-between row">
                    <button
                        type="button"
                        className="btn btn-danger w-25"
                        onClick={() => onClose()}
                        data-dismiss="modal">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-success w-25"
                        onClick={() => onSave(fields)}
                        data-dismiss="modal">
                        Save
                    </button>
                </div>
            </form>
        </BaseDialog>)
}
