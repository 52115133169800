import React, { useState } from "react";

import { BaseDialog } from "components/common/Dialogs";

export default function DeactivateDialog({ onClose, onDeactivate }) {
    return (
        <BaseDialog
            id="userDeactivateDialog"
            label={`Are you sure you want to deactivate this user? It will also cancel and terminate the subscription of this user.`}
            onClose={onClose}
        >
            <div className="d-flex flex-row justify-content-between">
                <button
                    type="button"
                    className="btn btn-primary w-25"
                    onClick={() => onClose()}
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="btn btn-danger w-50"
                    onClick={() => onDeactivate()}
                    data-dismiss="modal"
                >
                    Continue
                </button>
            </div>
        </BaseDialog>
    );
}
