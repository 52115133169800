import React from 'react';
import PropTypes from 'prop-types';

Button.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
};

export default function Button({label, id, className, onClick, disabled}) {
    return (
        <div className={"m-login__form-action " + className}>
            <button
                id={id || "m_login_signin_submit"}
                onClick={onClick}
                className="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                disabled={disabled}>
                {label}
            </button>
        </div>
    );
}
