import t from 'helpers/getText';
export default function (key, field) {
    let value = null;
    if (field) {
        const fieldKey = `errors.${field}.${key}`;
        value = t(fieldKey);
    } else {
        value = t(`errors.${key}`);
    }

    if (!key)
        return '';

    return value;
}
