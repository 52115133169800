import React from 'react';

function Checkbox({className, value, inputClassName, name, label, onChange, ...additionalProps}) {
    return (
        <div className={'form-group m-form__group m-checkbox-list m-xl-3 ' + className}>
            <label
                className={'m-checkbox ' + inputClassName}
                htmlFor={name}>
                <input
                    type="checkbox"
                    checked={value}
                    name={name}
                    onChange={event => {
                        onChange(event.target && event.target.value === "on")
                    }}
                    {...additionalProps}/>
                {label}
                <span></span>
            </label>
        </div>
    )
}
Checkbox.type = 'checkbox';

export default Checkbox
