import React, {useState} from 'react';

import {BaseDialog} from "components/common/Dialogs";

export default function DeleteDialog({onClose, onDelete, deletedElem, disabled}) {
    const [fields, setFields] = useState({link: ''});
    return (
        <BaseDialog
            id="addNewVideoDialog"
            label={`Are you sure you want to delete ${deletedElem.link || deletedElem.filename}?`}
            onClose={onClose}>
                <div className="d-flex flex-row justify-content-between">
                    <button
                        type="button"
                        className="btn btn-primary w-25"
                        onClick={() => onClose()}
                        disabled={disabled}
                        data-dismiss="modal">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-danger w-50"
                        disabled={disabled}
                        onClick={() => onDelete({mediaId: deletedElem.id})}
                        data-dismiss="modal">
                        Delete
                    </button>
                </div>
        </BaseDialog>)
}
