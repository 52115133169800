import React, {useContext} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";

import {AuthContext} from 'context/AuthContext';
import {Navbars} from 'components/common/ControlElems';
import GuestRoutes from './GuestRoutes';
import BaseUserRoutes from "./BaseUserRoutes";


export default function AppRouters(props) {
    const {
        user,
    } = useContext(AuthContext);

    const roleRoutes = getRoutes(user, props);

    const routes = [
        ...roleRoutes
    ];

    return (
        <Router>
            <Navbars user={user}>
                <Switch>
                    {routes.map((Element, index) => <Element.type key={index} {...Element.props} />)}
                </Switch>
            </Navbars>
        </Router>
    );
}

function getRoutes(user, props) {
    if (user) {
        return BaseUserRoutes(props);
    } else {
        return GuestRoutes(props);
    }
}
