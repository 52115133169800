export function handleChange(key, fields, handler, event) {
    handler({...fields, [key]: event.target.value});

}

export function handleSubmit(login, fields, event) {
    event.preventDefault();
    event.stopPropagation();
    login(fields);
}

const functions = {
    handleChange,
    handleSubmit
}

export default functions;
