import React, {useContext, useState, useEffect} from 'react';
import moment from 'moment';
import {TableWithPaginator, WidgetHead} from 'components/common/DisplayElems';
import {ContentContext} from 'context/ContentContext';
import {TextField, Select} from 'components/common/Form';
import withRouter from 'components/helpers/withRouter';

const DEFAULT_FILTERS = {id: null, name: '', active: null, offset: 0, limit: 5};

function HeavyRotation({history: routeHistory}) {
    const {heavyRotationResult, getHeavyRotationContent} = useContext(ContentContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);

    useEffect(() => {
        (() => getHeavyRotationContent(fields))();
    }, [fields]);

    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                <WidgetHead label="Heavy rotations settings"/>
                <WidgetHead label="Filter"/>
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                    <TextField
                        value={fields.id}
                        name="Id"
                        label="Id"
                        type="number"
                        min="0"
                        placeholder="Id"
                        className="w-25 mr-5"
                        onChange={(event) => handleSetFields('id', fields, event, setFields)}/>
                    <TextField
                        value={fields.name}
                        name="Name"
                        label="Name"
                        type="text"
                        placeholder="Name"
                        className="w-25 mr-5"
                        onChange={(event) => handleSetFields('name', fields, event, setFields)}/>
                    <Select name="Active"
                            label="Active"
                            className="w-25 mr-5"
                            value={fields.active}
                            options={[{value: 'Not set', text: 'Not set'}, {value: false, text: 'No'},
                                {value: true, text: 'Yes'}]}
                            onChange={(event) => handleSetFields('active', fields, event, setFields)}/>
                </div>
                <TableWithPaginator
                    buttons={[
                        {
                            label: 'Info',
                            action: (elem) => routeHistory.push('/artist/' + elem.id),
                            className: 'btn-primary w-50'
                        }
                    ]}
                    tableElems={
                        ['id', 'name', getActive.bind(this, 'heavyRotation'),
                            getPeriod.bind(this, 'heavyRotation'), getPeriod.bind(this, 'spotlight')]}
                    tableLabel="Artists"
                    headers={['ID', 'Name', 'Active', 'Dates for Heavy Rotation', 'Dates for Spotlight', 'Actions']}
                    data={heavyRotationResult.list}
                    offset={fields.offset}
                    limit={fields.limit}
                    count={heavyRotationResult.count}
                    options={[{value: 5, text: '5'}, {value: 10, text: '10'},
                                {value: 20, text: '20'}, {value: 50, text: '50'}]}
                    offsetOnChange={(value) => handleSetFields('offset', fields, {target: {value}}, setFields)}
                    limitOnChange={(event) => handleSetFields('limit', fields, event, setFields)}/>
            </div>
        </div>
    )
}


function getPeriod(neededType, artist) {
    let result = '-';
    if (artist && artist.period) {
        const {from, to} = artist.period.find(({type}) => type === neededType) || {};
        if (from && to) {
            result = `${moment(from).format('DD MMM YYYY')} - ${moment(to).format('DD MMM YYYY')}`
        }
    }
    return result;
}

function getActive(neededType, artist) {;
    return artist.active ? 'yes' : 'no';
}

function handleSetFields(key, fields, event, handler) {
    const newFields = {...fields};
    const value = event.target.value;
    newFields[key] = value;
    if (key !== 'offset') newFields.offset = 0;
    handler(newFields);
}

export default withRouter(HeavyRotation);
