import React, {useContext} from 'react';
import PropTypes from 'prop-types';

export default function consumeToContext(propName, Context) {
    return function (WrappedComponent) {
        const hoc = props => {
            const context = useContext(Context);

            const wrappedProps = {
                ...props,
                [propName]: context,
            };

            return <WrappedComponent {...wrappedProps}/>;
        };

        hoc.displayName = `consumeToContext(${propName})`;

        return hoc;
    }
}
