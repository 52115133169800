import React, {Component} from 'react';
import AuthContextContainer from 'context/AuthContext.js';
import UserRegisterContextContainer from 'context/UserRegisterContext';
import ContentContextContainer from "context/ContentContext";
import FeedbackContextContainer from "context/FeedbackContext";
import TracksContextContainer from "context/TracksContext";
import TransactionsContextContainer from "context/TransactionsContext";
import CuratedSetContext from "context/CuratedSetContext";
import PaypalHistoryContextContainer from "context/PaypalHistoryContext";
import Routers from 'routers';

class App extends Component {
    renderContextProviders(contextProviders, children) {
        if (contextProviders.length === 0)
            return children;

        const [ContextProvider, ...restContextProviders] = contextProviders;

        return <ContextProvider>{this.renderContextProviders(restContextProviders, children)}</ContextProvider>;
    }

    render() {
        const contextProviders = [
            AuthContextContainer,
            UserRegisterContextContainer,
            ContentContextContainer,
            FeedbackContextContainer,
            TracksContextContainer,
            TransactionsContextContainer,
            CuratedSetContext,
            PaypalHistoryContextContainer
         ];

        return (
            <div>
                {this.renderContextProviders(contextProviders, <Routers/>)}
            </div>
        );
    }
}

export default App;
