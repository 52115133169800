import React, {useRef} from 'react';
import PropTypes from 'prop-types';

UploadButton.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default function UploadButton({label, max, buttonClassName, className, accept, onChange, divStyle}) {
    const input = useRef(null);
    window.f = input;
    return (
        <div style={divStyle} className={"m-login__form-action " + className}>
            <input
                type="file"
                ref={input}
                max={max || 1}
                onChange={onChange}
                accept={accept || "image/png, image/jpeg"}
                style={{display: 'none'}}/>
                <button
                    className={"btn btn-primary m-xl-3 " + buttonClassName}
                    type="text"
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    if (input.current) {
                        input.current.value = null;
                        input.current.click();
                    }
                    }}>{label}</button>
        </div>
    );
}
