import React, {useContext, useState, useEffect, useRef} from 'react';
import {ContentContext} from "context/ContentContext";
import {UploadButton} from 'components/common/ControlElems';


const FIELDS = [
    {type: 'name', label: 'Name'},
    {type: 'bio', label: 'Bio'}
];

export default function SocialLinks() {
    const {artistResult: {id, avatar, cover, ...all}, uploadMedia} = useContext(ContentContext);
    return (
        <div className="col-xl-6 d-flex flex-row justify-content-sm-start align-items-sm-start">
            <div>
                <h4>Avatar</h4>
                <div>
                    <img
                        key={avatar || 'nokey'}
                        className="img-thumbnail rounded m-widget3__img"
                        style={{height: '200px', minWidth: '200px'}}
                        src={avatar}/>
                    <UploadButton
                        label="Upload new"
                        onChange={({target: {files}}) => {
                        uploadMedia(files[0], 'avatar', id)
                    }}/>
                </div>
            </div>
            <div className="ml-xl-5">
                <h4>Cover</h4>
                <div>
                    <img
                        key={cover || 'nokey'}
                        className="img-thumbnail rounded"
                        style={{height: '200px', minWidth: '200px'}}
                        src={cover}/>
                    <UploadButton
                        label="Upload new"
                        onChange={({target: {files}}) => {
                            uploadMedia(files[0], 'cover', id)
                    }}/>
                </div>
            </div>
        </div>
    )
}
