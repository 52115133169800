import React, { useContext, useState, useEffect, useRef } from 'react';
import { default as TableWithPaginator } from '../TableWithPaginator';
import { TracksContext } from 'context/TracksContext';
import { TextField, Select } from 'components/common/Form';

const DEFAULT_FILTERS = { searchQuery: '', genre: '', sortField: 'createdAt', type: 'audio', offset: 0, limit: 5 };
// setIsBannerTracksUpdating
export default function AlbumList({ setIsBannerTracksUpdating = false, bannerType }) {
    const { albumFetching, albumsResult, getAlbums, genresResult: { genres }, bannerTracks, removeUpdateBannerTrack } = useContext(TracksContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const [selectedTracks, setSelectedTracks] = useState([]);

    const { albums = [], count = 0 } = albumsResult;
    const listWithVersions = albums.map(album => ({
        id: album.id,
        name: album.name,
        artiste: album.artiste,
        genre: album.genre,
        tracks: album.tracks // Assuming tracks are included in the album object
    }));

    const timer = useRef(null);

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => getAlbums(fields), 1000);
        return () => clearTimeout(timer.current);
    }, [fields]);

    const handleTrackSelection = (album) => {
        if (album.tracks) {
            setSelectedTracks(prevTracks => [...prevTracks, ...album.tracks]);
        }
    };

    const handleRemoveTrack = (track) => {
        setSelectedTracks(prevTracks => prevTracks.filter(t => t.id !== track.id));
        removeUpdateBannerTrack(track);
    };

    return (
        <div className="p-4 m-3 album-list">
            <h5 className="text-center text-danger mt-3">Please select the albums for banner. *</h5>
            <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4 justify-content-md-between">
                <TextField
                    value={fields.searchQuery}
                    name="Search"
                    label="Search"
                    type="text"
                    placeholder="Search"
                    className="w-25 mt-3 mr-5 label_Inline"
                    onChange={(event) => handleSetFields('searchQuery', fields, event, setFields)}
                />
                {/* <Select 
                    name="Sort Field"
                    label="Sort Field"
                    className="w-25 mr-5"
                    value={fields.sortField}
                    options={[{ value: 'createdAt', text: 'Latest Releases' }, { value: 'downloads', text: 'Top AlbumList' }]}
                    onChange={(event) => handleSetFields('sortField', fields, event, setFields)}
                /> */}
                <Select 
                    name="Genre"
                    label="Genre"
                    className="w-25 mr-5 label_Inline"
                    value={fields.genre}
                    options={[{ value: '', text: 'All' }, ...genres.map(genre => ({ value: genre, text: genre }))]}
                    onChange={(event) => handleSetFields('genre', fields, event, setFields)}
                />
            </div>
            <TableWithPaginator
                tableWidth={['100px', '400px', '300px', '100px', '100px', '100px']}
                tableElems={['id', 'name', 'artiste', 'genre']}
                headers={['ID', 'Album Name', 'Artist', 'Genre']}
                data={listWithVersions}
                offset={fields.offset}
                limit={fields.limit}
                count={count}
                options={[{ value: 5, text: '5' }, { value: 10, text: '10' }, { value: 20, text: '20' }, { value: 50, text: '50' }]}
                offsetOnChange={(value) => handleSetFields('offset', fields, { target: { value } }, setFields)}
                limitOnChange={(event) => handleSetFields('limit', fields, event, setFields)}
                isDataFetching={albumFetching}
                setIsBannerTracksUpdating={setIsBannerTracksUpdating}
                handleTrackSelection={handleTrackSelection}
            />
            {bannerType === 'tracks' && selectedTracks.length > 0 && (
                <div className="d-flex col-12 flex-wrap mb-4" key={'_bannerTracksBtn'}>
                    {selectedTracks.map((track) => (
                        <div className="button-container" key={track.id + '_bannerTracks'}>
                            <button className="close-button" key={track.id + '_bannerTracksBtn'}>
                                {track.songName}
                                <span className="close-icon" onClick={() => handleRemoveTrack(track)} key={track.id + '_bannerTracksBtnX'}>
                                    ×
                                </span>
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

// Add the handleSetFields function here
function handleSetFields(key, fields, event, handler) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = value;
    if (key !== 'offset') newFields.offset = 0; // Reset offset when a filter changes
    handler(newFields);
}
