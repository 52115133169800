import superagent from 'superagent';
import {httpApiPrefixPath} from 'sources/api/ApiPaths';

export default function makeRequest(httpRequest) {
    const {method, path, params, data, files} = httpRequest;
    const fullPath = getFullPath(httpApiPrefixPath, path);
    const request = superagent[method](fullPath);
    // console.log('Current timeout value:', request._timeout);
    const timeoutInMilliseconds = 20 * 60 * 1000;
    request.timeout(timeoutInMilliseconds);
    // console.log('2 Current timeout value:', request._timeout);
    if (params) {
        request.query(params);
    }

    if (files) {
        Object.keys(files || {}).forEach(key => {
            request.attach(key, files[key]);
        });

        Object.keys(data || {}).forEach(key => {
            request.field(key, data[key]);
        });
    }
    else {
        if (data) {
            request.send(data);
        }
    }

    return new HttpClient(request, httpRequest);

}

function getFullPath(prefix, path) {
    return withoutRightSlash(prefix) + '/' + withoutLeftSlash(path);
}

function withoutRightSlash(prefix) {
    if (prefix.slice(-1) === '/')
        return prefix.substr(0, prefix.length - 1);

    return prefix;
}

function withoutLeftSlash(suffix) {
    if (suffix[0] === '/')
        return suffix.substr(1);

    return suffix;
}

class HttpClient {

    constructor(request, options) {
        this.request = request;
        this.options = options;
    }

    send() {
        return new Promise((resolve, reject) => {
          this.request.timeout(1200000); // Set the timeout value in milliseconds (e.g., 2 minutes)
    
          this.request.end((err, { body } = {}) => {
            if (err) {
              if (err.timeout) {
                reject(new Error('Request timed out')); // Handle timeout error
              } else {
                reject(body || err); // Handle other errors
              }
            } else {
              resolve(body);
            }
          });
        });
      }

    cancel() {
        this.request.abort();
    }

    onProgress(listener) {
        if (this.options.progressEvents)
            this.request.on('progress', listener);
    }

    offProgress(listener) {
        if (this.options.progressEvents)
            this.request.off('progress', listener);
    }
}
