import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function TextField({className, value, inputClassName, name, label, placeholder, ...additionalProps}) {
    const localTime = (value === undefined) ? new Date() : new Date(value);
    const normalTime = new Date(+localTime + localTime.getTimezoneOffset()*1000*60);
    console.log(value, localTime)
    return (
      <div className='form-group m-form__group d-flex flex-column justify-content-sm-start align-items-sm-start m-xl-3'>
          {label && <label htmlFor={name} className={classnames('col-md-4')}>{label}</label>}
          <DatePicker
              value={value && moment(normalTime.toISOString()).format('DD MMM YYYY')}
              {...additionalProps}
              className={classnames('form-control m-input col-md-8')}/>
        </div>
    )
}
