import React from 'react';
import PropTypes from 'prop-types';

Error.propTypes = {
    text: PropTypes.string
};

export default function Error({text}) {
    return (
        <div className="form-control-feedback m-error-1 text-center">
            {text}
        </div>
    );
}
