import React, {useState, useContext} from 'react';

import {BaseDialog} from "components/common/Dialogs";
import UploadButton from "../ControlElems/UploadButton";
import {ContentContext} from "../../../context/ContentContext";

export default function AddDialog({onClose, onSave, editedElem, disabled, addType}) {
    const { uploadVideo } = useContext(ContentContext);
    const [trackType, setTrackType] = useState(editedElem ? editedElem.type||'youtube' : 'youtube');
    const initFields = editedElem ? {
            link: editedElem.link,
            artistName: editedElem.artistName,
            trackName: editedElem.trackName,
            type: editedElem.type}
        : { link: '',
            type:'youtube',
            artistName: '',
            trackName: ''};
    const [fields, setFields] = useState(initFields);
    return (
        <BaseDialog
            id="addNewVideoDialog"
            label={editedElem ? "Edit the video" : "Add the new video"}
            onClose={onClose}>
            <form onSubmit={(event) => {
                handleSubmit({event, editedElem, fields, onSave, uploadVideo, trackType})
            }}>
                <div className="form-group">
                    <label htmlFor="type">Type:</label>
                    <select
                        required
                        defaultValue={editedElem ? editedElem.type : ''}
                        onChange={ event => {
                            setFields({...fields, type: event.target.value});
                            setTrackType(event.target.value)}
                        }
                        className="form-control"
                        id="type">
                        <option value={'youtube'}>YouTube</option>
                        <option value={'local'}>Local</option>
                    </select>
                    <label htmlFor="link">Source:</label>
                    {trackType === 'youtube'
                        ? <input
                            required
                            defaultValue={editedElem ? editedElem.link : ''}
                            type="link"
                            onChange={(event) => setFields({...fields, link: event.target.value})}
                            className="form-control"
                            id="link"/>
                        : <div style={{display:'flex', flexDirection:'row'}}>
                            <UploadButton
                                divStyle={{margin:'-12px 0 -14px -12px'}}
                                accept={"video/mp4"}
                                label={"Upload file"}
                                onChange={event => setFields({...fields, file: event.target.files[0]})}
                            />
                            <p style={{
                                display:'flex',
                                flexDirection:'column',
                                justifyContent:'center',
                                maxWidth:'75%',
                                overflow:'hidden',
                                marginLeft:'1rem',
                                whiteSpace:'nowrap'
                            }}
                            >
                                {fields.file && fields.file.name}
                            </p>
                          </div>
                    }
                    {addType === 'splash' &&
                        <>
                            <label htmlFor="artist">Artist name:</label>
                            <input
                                required
                                defaultValue={editedElem ? editedElem.artistName : ''}
                                type="text"
                                onChange={(event) => setFields({...fields, artistName: event.target.value})}
                                className="form-control"
                                id="artist"/>
                            <label htmlFor="track">Track name:</label>
                            <input
                                required
                                defaultValue={editedElem ? editedElem.trackName : ''}
                                type="text"
                                onChange={(event) => setFields({...fields, trackName: event.target.value})}
                                className="form-control"
                                id="track"/>
                        </>
                    }
                </div>
                <div className="d-flex flex-row justify-content-between">
                    <button
                        type="button"
                        className="btn btn-danger w-25"
                        disabled={disabled}
                        onClick={() => onClose()}
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-success w-50"
                        disabled={disabled}
                        data-dismiss="modal"

                    >
                        Save
                    </button>
                </div>
            </form>
        </BaseDialog>)
}

async function handleSubmit({event, editedElem, fields, onSave, uploadVideo, trackType}) {
    event.preventDefault();
    event.stopPropagation();

    const params = {...fields};
    params.type = trackType;
    const link = (trackType === 'local') && fields.file
        ? await uploadVideo(fields.file)
        : fields.link;
    if (link) params.link = link;
    if (editedElem) params.mediaId = editedElem.id;
    onSave(params);
}
