import React from "react";
import { createRequestHelper } from "./helpers/requestHelper";
import consumeToContext from "./helpers/consumeToContext";
import { AuthContext } from "./AuthContext";
import {
    getUpdatedTrackState,
    getPathForHeavyRotation,
    normailezeHeavyRotationResult,
    getUpdatedArtistState,
    getPathForDJUserList,
    getInvalidUsersListPath,
    normalizeDJUserListResult,
    normalizeDJUserDetailsResult,
    getPathForDJUserDetails,
    getPathForDJUserTransHistory,
    normalizeDJUserTransHistoryResult,
    sleep
} from "context/helpers/contentContextHelper";
import UpdateStatus from "components/Widgets/DJUserDetails/DetailsConstants";
import SearchStatus from "components/Widgets/DJUsers/Constants";
import { jsonToCSV } from "react-papaparse";

export const ContentContext = React.createContext();

const splashHelper = createRequestHelper("splash", { list: [], count: 0 });
const addSplashHelper = createRequestHelper("addSplash");
const heavyRotationhHelper = createRequestHelper("heavyRotation", {
    list: [],
    count: 0
});
const runScriptHelper = createRequestHelper("runScript");
const deleteSplashHelper = createRequestHelper("deleteSplash");
const artistHelper = createRequestHelper("artist");
const tracksHelper = createRequestHelper("tracks", { list: [], count: 0 });
const deleteTrackHelper = createRequestHelper("deleteTrack");
const updateArtistHelper = createRequestHelper("updateArtist");
const busyStoplightPeriodsHelper = createRequestHelper(
    "busySpotlightPeriods",
    []
);
const djUsersHelper = createRequestHelper("djUsers", { list: [], count: 0 });
const djUsersTransHistoryHelper = createRequestHelper("djUsersTransHistory", {
    list: [],
    count: 0
});
const djUserDetailsHelper = createRequestHelper("djUserDetails");
const invalidUsersHelper = createRequestHelper("invalidUsers", {
    list: [],
    count: 0
});
const userIdBySubHelper = createRequestHelper("userIdBySub", []);
const userIdByUserInfoHelper = createRequestHelper("userIdByUserInfo", []);
const insertTransactionsHelper = createRequestHelper("insertTransactions", null);
const lockUserHelper = createRequestHelper("lockUser");
const forceUpdatePasswordHelper = createRequestHelper("forceUpdatePassword");
const updateProfileByIdHelper = createRequestHelper("updateProfileById");
const subscribeHelper = createRequestHelper("subscribe");
const transHistoryHelper = createRequestHelper("transHistory");
const subscriptionHelper = createRequestHelper("subscription");
const unsubscribeUserHelper = createRequestHelper("unsubscribeUser");

const TRACK_UPLOAD_STATUSES = {
    upload: { text: 'Uploading...', color: 'blue'},
    inQueue: { text: 'Waiting to upload', color: 'yellow'},
    requiredInfo: { text: 'Requires updated info to upload', color: 'orange'},
    error: { text: 'Failed to upload', color: 'red'}
};

class ContentContextContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...splashHelper.initialState,
            ...addSplashHelper.initialState,
            ...deleteSplashHelper.initialState,
            ...heavyRotationhHelper.initialState,
            ...artistHelper.initialState,
            ...updateArtistHelper.initialState,
            ...busyStoplightPeriodsHelper.initialState,
            ...djUsersHelper.initialState,
            ...djUsersTransHistoryHelper.initialState,
            ...djUserDetailsHelper.initialState,
            ...forceUpdatePasswordHelper.initialState,
            ...updateProfileByIdHelper.initialState,
            ...subscribeHelper.initialState,
            ...invalidUsersHelper.initialState,
            ...lockUserHelper.initialState,
            ...userIdBySubHelper.initialState,
            ...insertTransactionsHelper.initialState,
            ...transHistoryHelper.initialState,
            ...subscriptionHelper.initialState,
            ...unsubscribeUserHelper.initialState,
            artistResult: null,
            tracksToUpload: []
        };

        this.funcs = {
            getSplashContent: this.getSplashContent,
            addSplash: this.addSplash,
            addSplashClear: this.addSplashClear,
            deleteSplash: this.deleteSplash,
            deleteSplashClear: this.deleteSplashClear,
            getHeavyRotationContent: this.getHeavyRotationContent,
            runScript: this.runScript,
            createSyncConsoleInterval: this.createSyncConsoleInterval,
            stopScript: this.stopScript,
            getArtist: this.getArtist,
            updateTrackStatus: this.updateTrackStatus,
            updateArtist: this.handleUpdateArtist,
            uploadMedia: this.handleUploadMedia,
            getBusySpotlightPeriods: this.getBusySpotlightPeriods,
            updatePeriods: this.handleUpdateRequest.bind(
                this,
                "post",
                `/artists/setPeriod`,
                "period"
            ),
            updateSocialLinks: this.handleUpdateRequest.bind(
                this,
                "post",
                `/artists/socialLinks/update`,
                "socialLink"
            ),
            addArtistVideo: this.handleUpdateRequest.bind(
                this,
                "post",
                `/artists/addVideo`,
                "coverVideo"
            ),
            deleteArtistVideo: this.handleUpdateRequest.bind(
                this,
                "delete",
                `/artists/deleteVideo`,
                "coverVideo"
            ),
            updateArtistVideo: this.handleUpdateRequest.bind(
                this,
                "post",
                `/artists/updateVideo`,
                "coverVideo"
            ),
            handleVideoRequestClear: this.handleVideoRequestClear,
            uploadVideo: this.uploadVideo,
            setNullArtist: this.setNullArtist,
            getDJUserList: this.getDJUserList,
            getDJUserDetails: this.getDJUserDetails,
            forceUpdatePassword: this.forceUpdatePassword,
            updateProfileById: this.updateProfileById,
            getDJUserTransHistory: this.getDJUserTransHistory,
            subscribe: this.subscribe,
            clearSubscribe: this.clearSubscribe,
            getInvalidUserList: this.getInvalidUserList,
            lockoutUser: this.lockoutUser,
            getUserIdBySub: this.getUserIdBySub,
            getUserIdByUserInfo: this.getUserIdByUserInfo,
            insertTransactions: this.insertTransactions,
            getTranactionsData: this.getTranactionsData,
            getTransHistory: this.getTransHistory,
            unsubscribeUser: this.unsubscribeUser,
            
        };
    }

    setNullArtist = () => {
        this.setState({ artistResult: null });
    };

    handleVideoRequestClear = () => {
        this.setState(updateArtistHelper.clear());
    };

    getBusySpotlightPeriods = async () => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path: "/artists/busySpotlightPeriods"
            });
            this.setState(busyStoplightPeriodsHelper.result(result));
        } catch (error) {
            this.setState(busyStoplightPeriodsHelper.error(error));
        }
    };

    updateTrackStatus = async data => {
        this.setState(updateArtistHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: "/artists/track/update",
                data
            });
            this.setState({
                ...getUpdatedTrackState({ state: this.state, result }),
                ...updateArtistHelper.result(true)
            });
        } catch (error) {
            this.setState(artistHelper.error(error));
        }
    };

    handleUploadMedia = async (file, type, artistId) => {
        this.setState(updateArtistHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/artists/${artistId}/${type}/uploadMedia`,
                files: { file }
            });
            this.setState({
                ...getUpdatedArtistState({
                    state: this.state,
                    result: result.link,
                    key: type
                }),
                ...updateArtistHelper.result(true)
            });
        } catch (error) {
            this.setState(artistHelper.error(error));
        }
    };

    handleUpdateArtist = async data => {
        this.setState(updateArtistHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: "/artists/update",
                data
            });
            this.setState({
                ...getUpdatedArtistState({
                    state: this.state,
                    result: result.name,
                    key: "name"
                }),
                ...getUpdatedArtistState({
                    state: this.state,
                    result: result.bio,
                    key: "bio"
                }),
                ...updateArtistHelper.result(true)
            });
        } catch (error) {
            this.setState(artistHelper.error(error));
        }
    };

    deleteNullsFromObject = obj => {
        const preData = {};
        for (let key in obj) {
            if (key !== null) preData[key] = obj[key];
        }
        return preData;
    };

    handleUpdateRequest = async (method, path, key, data) => {
        this.setState(updateArtistHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const preData = this.deleteNullsFromObject(data);
            if (preData.active === undefined)
                preData.active = this.state.artistResult.active;
            const result = await makeAuthenticateHttpRequest({
                method,
                path,
                data: preData
            });
            const artistResult = {
                ...this.state.artistResult,
                period: result.period
            };
            if (result.rotationActive !== undefined)
                artistResult.active = result.rotationActive;
            this.setState({
                ...getUpdatedArtistState({ state: this.state, result, key }),
                ...updateArtistHelper.result(true),
                artistResult
            });
        } catch (error) {
            this.setState(artistHelper.error(error));
        }
    };

    getArtist = async ({ id, limit, offset }) => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const artistResult = await makeAuthenticateHttpRequest({
                method: "get",
                path: `/artists/fetchOne?id=${id}&tracksOffset=${offset}&tracksLimit=${limit}`
            });
            this.setState(artistHelper.result(artistResult));
            this.setState({ artistResult: artistResult });
        } catch (error) {
            this.setState(artistHelper.error(error));
        }
    };

    getSplashContent = async () => {
        this.setState(splashHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path: `/staticMedias`
            });
            this.setState(splashHelper.result(result));
        } catch (error) {
            this.setState(splashHelper.error(error));
        }
    };

    getHeavyRotationContent = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        const path = getPathForHeavyRotation(data);
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path
            });
            this.setState(
                heavyRotationhHelper.result(
                    normailezeHeavyRotationResult(result)
                )
            );
        } catch (error) {
            this.setState(heavyRotationhHelper.error(error));
        }
    };

    runScript = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(runScriptHelper.processing());
            await makeAuthenticateHttpRequest({
                method: "post",
                path: "/admins/runScript",
                data
            });
        } catch (error) {
            this.setState(runScriptHelper.error(error));
        }
    };

    createSyncConsoleInterval = timeout => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            runScriptHelper.clear();
            return setInterval(async () => {
                const result = await makeAuthenticateHttpRequest({
                    method: "post",
                    path: "/admins/getConsole"
                });
                if (result.end) {
                    this.setState(runScriptHelper.result(result.console));
                } else if (result.console) {
                    this.setState(runScriptHelper.processing(result.console));
                }
            }, timeout);
        } catch (error) {
            this.setState(runScriptHelper.error(error));
        }
    };

    stopScript = async () => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            await makeAuthenticateHttpRequest({
                method: "post",
                path: "/admins/stopScript"
            });
        } catch (error) {
            this.setState(runScriptHelper.error(error));
        }
    };

    getInvalidUserList = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        const path = getInvalidUsersListPath(data);
        try {
            this.setState(invalidUsersHelper.processing());
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path
            });
            this.setState(
                invalidUsersHelper.result(result)
            );
        } catch (error) {
            this.setState(invalidUsersHelper.error(error));
        }
    };
    
    getDJUserList = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        const path = getPathForDJUserList(data);
        try {
            this.setState(djUsersHelper.processing());
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path
            });
            this.setState(
                djUsersHelper.result(normalizeDJUserListResult(result))
            );
        } catch (error) {
            this.setState(djUsersHelper.error(error));
        }
    };

    getDJUserTransHistory = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        const path = getPathForDJUserTransHistory(data);
        try {
            this.setState(djUsersTransHistoryHelper.processing());
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path
            });
            this.setState(
                djUsersTransHistoryHelper.result(
                    normalizeDJUserTransHistoryResult(result)
                )
            );
        } catch (error) {
            this.setState(djUsersTransHistoryHelper.error(error));
        }
    };

    forceUpdatePassword = async data => {
        this.setState(forceUpdatePasswordHelper.clear());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(forceUpdatePasswordHelper.result("Processing"));
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/admins/forceSetPassword?id=${data.id}&password=${
                    data.password
                }`
            });
            this.setState(forceUpdatePasswordHelper.result(result));
            setTimeout(
                () => this.setState(forceUpdatePasswordHelper.clear()),
                1000
            );
        } catch (error) {
            this.setState(forceUpdatePasswordHelper.error(error));
        }
    };

    lockoutUser = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(lockUserHelper.processing());
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/admins/lockoutuser`,
                data: {
                    id: data.id,
                    fields: {
                        active: 0
                    }
                }
            });
            const { invalidUsersResult } = this.state;
            const user = invalidUsersResult.list.find(e => e.id === data.id);
            user.allow_user = 0;
            this.setState({invalidUsersResult: {
                list: [...invalidUsersResult.list], count: invalidUsersResult.count}
            });
            this.setState(lockUserHelper.result('SUCCESS'));
        } catch (error) {
            this.setState(lockUserHelper.error(error));
        }
    }

    getUserIdBySub = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(userIdBySubHelper.processing());
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/admins/useridbysubscriprtion`,
                data: {
                    list: data
                }
            });
            this.setState(userIdBySubHelper.result(result.list));
        } catch (error) {
            this.setState(userIdBySubHelper.error(error));
        }
    }

    getUserIdByUserInfo = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(userIdByUserInfoHelper.processing());
            let batch = 0;
            let result = [];
            while (batch < data.length) {
                const batchArray = data.slice(batch, batch + 100);
                const batchResult = await makeAuthenticateHttpRequest({
                    method: "post",
                    path: `/admins/useridbyuserinfo`,
                    data: {
                        list: batchArray
                    }
                });
                result = {
                    ...result,
                    ...batchResult.list,
                };
                await sleep(100);
                batch += 100;
            }
            this.setState(userIdByUserInfoHelper.result(result));
        } catch (error) {
            this.setState(userIdByUserInfoHelper.error(error));
        }
    }

    insertTransactions = async data => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(insertTransactionsHelper.processing());
            let batch = 0;
            let result = 0;
            let sdResult = 0;
            let uidResult = 0;
            while (batch < data.length) {
                const batchArray = data.slice(batch, batch + 100);
                const batchResult = await makeAuthenticateHttpRequest({
                    method: "post",
                    path: `/admins/addtrans`,
                    data: {
                        list: batchArray
                    }
                });
                result += batchResult.count;
                sdResult += batchResult.countDate;
                uidResult += batchResult.countUserId;
                await sleep(100);
                batch += 100;
            }
            this.setState(insertTransactionsHelper.result({count: result, countDate: sdResult, countUserId: uidResult}));
        } catch (error) {
            this.setState(insertTransactionsHelper.error(error));
        }
    }

    getDJUserDetails = async id => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        const path = getPathForDJUserDetails(id);
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "get",
                path
            });
            this.setState(
                djUserDetailsHelper.result(normalizeDJUserDetailsResult(result))
            );
        } catch (error) {
            this.setState(djUserDetailsHelper.error(error));
        }
    };

    updateProfileById = async (id, data) => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            this.setState(
                updateProfileByIdHelper.result(UpdateStatus.UPDATE_PROCESSING)
            );
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/admins/updateUserProfileById`,
                data: {
                    id: id,
                    fields: data
                }
            });
            this.setState(
                djUserDetailsHelper.result(normalizeDJUserDetailsResult(result))
            );
            this.setState(
                updateProfileByIdHelper.result(UpdateStatus.UPDATE_SUCCESS)
            );
        } catch (error) {
            this.setState(djUserDetailsHelper.error(error));
        }
    };

    subscribe = async (id, data) => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        this.setState(subscribeHelper.processing());
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/admins/subscribe`,
                params: {
                    id: id,
                    fields: data
                }
            });
            if (result.result === "success") {
                this.setState(subscribeHelper.result(result));
            } else {
                this.setState(subscribeHelper.error(result));
            }
        } catch (error) {
            this.setState(subscribeHelper.error(error));
        }
    };

    clearSubscribe = async () => {
        this.setState({
            ...subscribeHelper.initialState
        });
    };

    uploadVideo = async file => {
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        return await makeAuthenticateHttpRequest({
            method: "post",
            path: "/staticMedias/uploadVideo",
            files: { file }
        });
    };

    addSplashClear = () => {
        this.setState(addSplashHelper.clear());
    };

    addSplash = async fields => {
        this.setState(addSplashHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                path: `/staticMedias`,
                data: fields
            });
            this.setState(addSplashHelper.result(result));
        } catch (error) {
            this.setState(addSplashHelper.error(error));
        }
    };

    deleteSplashClear = () => {
        this.setState(deleteSplashHelper.clear());
    };

    deleteSplash = async fields => {
        this.setState(deleteSplashHelper.processing());
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "delete",
                path: `/staticMedias`,
                data: fields
            });
            this.setState(deleteSplashHelper.result(result));
        } catch (error) {
            this.setState(deleteSplashHelper.error(error));
        }
    };


    getTranactionsData = async (fields) => {
        this.setState(transHistoryHelper.processing(true));
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                'Content-Type': 'application/json',
                path: `/transactions/getUnpaidDJs`,
                data: fields
            });
            
            this.setState(transHistoryHelper.result(result));
        } catch (error) {
            this.setState(transHistoryHelper.error(error));
        }
        
    };

  
    getTransHistory = async (data) => {
        this.setState(subscriptionHelper.processing(true));
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                'Content-Type': 'application/json',
                path: `/transactions/getTransHistory`,
                data: JSON.stringify(data)
            });
            this.setState(subscriptionHelper.result(result));
        } catch (error) {
            this.setState(subscriptionHelper.error(error));
        }
    };

    unsubscribeUser = async (data) => {
        this.setState(unsubscribeUserHelper.processing(true));
        const {
            auth: { makeAuthenticateHttpRequest }
        } = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: "post",
                'Content-Type': 'application/json',
                path: `/transactions/unsubscribeUser`,
                data: data
            });
            this.setState(unsubscribeUserHelper.result(result));
        } catch (error) {
            this.setState(unsubscribeUserHelper.error(error));
        }
    };




    render() {
        return (
            <ContentContext.Provider value={{ ...this.state, ...this.funcs }}>
                {this.props.children}
            </ContentContext.Provider>
        );
    }
}

export default consumeToContext("auth", AuthContext)(ContentContextContainer);
