import React, {useContext, useState} from 'react';
import {AuthContext} from 'context/AuthContext';
import parseServerValidationErrors from 'sources/api/parseServerValidationErrors';

import {Container, TextField, Title, utils} from "components/common/Form";
import {Button, Error} from "components/common/ControlElems";


export default function Login() {
    const [fields, setFields] = useState({
        email: '',
        password: ''
    });

    const {
        login,
        loginProcessing,
        loginError,
    } = useContext(AuthContext);
    const serverErrors = parseServerValidationErrors(loginError);
    const {user: userErrors, email: emailErrors} = serverErrors;
    const commonError = userErrors || emailErrors;

    return (
        <div className="m-grid m-grid--hor m-grid--root m-page">
            <div
                className="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
                id="m_login">
                <div className="m-grid__item m-grid__item--fluid	m-login__wrapper">
                    <div className="m-login__container">
                        <div className="m-login__signin">
                            <Title
                                containerClassName="m-login__head"
                                inputClassName="m-login__title"
                                title="Sign In To Admin"/>
                            <Container
                                className="m-login__form"
                                handleSubmit={utils.handleSubmit.bind(this, login, fields)}>
                                <TextField
                                    autoComplete="off"
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    onChange={utils.handleChange.bind(this, 'email', fields, setFields)}
                                    required/>
                                <TextField
                                    autoComplete="off"
                                    name="password"
                                    placeholder="Password"
                                    type="password"
                                    min={6}
                                    inputClassName="m-login__form-input--last"
                                    onChange={utils.handleChange.bind(this, 'password', fields, setFields)}
                                    required/>
                                {commonError && <Error text={commonError}/>}
                                <Button
                                    label="Sign In"
                                    type="submit"
                                    disabled={loginProcessing}/>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}



