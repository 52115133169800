import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import LoginPage from 'components/login/LoginPage';
import {TrackProcess} from 'components/Widgets/Tracks';
export default function GuestRoutes() {
    return [
        <Route exact path="/" component={LoginPage}/>,
        <Route path="/processemotions/guest" component={TrackProcess}/>,
        <Redirect to="/"/>
    ];
}

GuestRoutes.propTypes = {};
