import React, {useContext, useState, useEffect, useRef} from 'react';
import {TableWithPaginator, WidgetHead} from 'components/common/DisplayElems';
import {TracksContext} from 'context/TracksContext';
import {TextField, Select} from 'components/common/Form';
import UpdateTrackDialog from "./UpdateTrackDialog";


const DEFAULT_FILTERS = {searchQuery: '', genre: '', sortField: 'createdAt', type: 'audio', offset: 0, limit: 5};
const versionsButtonsStyle = {
    maxWidth: '1.5rem',
    minWidth: '1rem',
    padding: '0',
    borderRadius: '1.5rem',
    maxHeight: '1.5rem'
};

export default function TrackList({mainTableButtons = [], subTableButtons= [], headTableButton= [], setUpdatedElem = null, updatedElem= []}) {
    const {tracksResult, getTracks, deleteTrackResult, genresResult:{genres},updateTracksNameGenre} = useContext(TracksContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const {list} = tracksResult;

    const [showVersion, setShowVersion] = useState(null);

    const listWithVersions = list.map(elem => {
        if (showVersion && elem.id === showVersion.id) {
            elem.subTable = elem.trackVersions.filter(filterTrackVersions.bind(this, showVersion));
        } else {
            elem.subTable = null;
        }
        return elem;
    });

    const timer = useRef(null);

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => getTracks(fields), 1000);
        return () => clearTimeout(timer.current);
    }, [fields]);

    useEffect(() => {
        if (deleteTrackResult) {
            getTracks(fields);
        }
    }, [deleteTrackResult]);

    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                <WidgetHead label="FRP Media"/>
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                    <TextField
                        value={fields.searchQuery}
                        name="Search"
                        label="Search"
                        type="text"
                        placeholder="Search"
                        className="w-25 mt-3 mr-5"
                        onChange={(event) => handleSetFields('searchQuery', fields, event, setFields)}/>
                    <Select name="Sort Field"
                            label="Sort Field"
                            className="w-25 mr-5"
                            value={fields.sortField}
                            options={[{value: 'createdAt', text: 'Latest Releases'}, {value: 'downloads', text: 'Top TrackList'}]}
                            onChange={(event) => handleSetFields('sortField', fields, event, setFields)}/>
                    <Select name="Genre"
                            label="Genre"
                            className="w-25 mr-5"
                            value={fields.genre}
                            options={[{value: '', text: 'All'}, ...genres.map(genre => {return {value: genre, text: genre}})]}
                            onChange={(event) => handleSetFields('genre', fields, event, setFields)}/>
                </div>
                <TableWithPaginator
                    tableWidth={['100px','400px','300px','100px', '100px', '100px', '100px', '500px']}
                    buttons={[...['A', 'O', 'I', 'R'].map(label => {
                        return {
                            label,
                            action: (elem) => onShowVersions(showVersion, {id: elem.id, type: label}, setShowVersion),
                            className: 'btn-primary',
                            style: versionsButtonsStyle
                        };
                        }),...mainTableButtons
                    ]}
                    tableElems={['id', 'songName', 'artiste', 'key', 'bpm', 'genre', 'isDeleted']}
                    tableLabel="TrackList"
                    headers={['id', 'Song Name', 'Artist', 'Key', 'BPM', 'Genre', 'Hidden', 'Actions']}
                    headButton={headTableButton}
                    data={listWithVersions}
                    offset={fields.offset}
                    subTable={{
                        tableElems: ['id', 'fileName', 'versionType', 'key', 'bpm', 'genre', 'isDeleted'],
                        tableWidth: ['100px','400px','300px','100px', '100px', '100px', '100px', '500px'],
                        background: "lightgrey",
                        buttons: subTableButtons
                    }}
                    limit={fields.limit}
                    count={tracksResult.count}
                    options={[{value: 5, text: '5'}, {value: 10, text: '10'},
                                {value: 20, text: '20'}, {value: 50, text: '50'}]}
                    offsetOnChange={(value) => handleSetFields('offset', fields, {target: {value}}, setFields)}
                    isDataFetching={true}
                    limitOnChange={(event) => handleSetFields('limit', fields, event, setFields)}/>
                    {updatedElem && updatedElem.length > 0 && typeof setUpdatedElem === 'function' &&
                        <UpdateTrackDialog
                             editedElem={updatedElem}
                             onSave={() => updateTracksNameGenre(updatedElem)}
                             onClose={() => setUpdatedElem(null)}
                        />
                    }
            </div>
        </div>
    )
}



function onShowVersions(currentShowVersion, newState, setShowVersion) {
    if (currentShowVersion && currentShowVersion.id === newState.id && currentShowVersion.type === newState.type) {
        setShowVersion(null);
    } else {
        setShowVersion(newState);
    }
}

function filterTrackVersions({type}, elem) {
    const O_FILTER = [
        "Dirty",
        "Clean",
        "Acapella",
        "Acapella Clean",
        "Acapella Dirty",
        "Instrumental",
        "Radio",
        "Radio Edit"
    ];
    const I_FILTER = ["Intro Clean", "Intro Dirty"];
    const R_FILTER = [
        "Remix Clean",
        "Remix Dirty",
        "Remix Instrumental",
        "Remix Acapella",
        "Remix Super Clean"
    ];
    console.log(elem, type);
    switch (type) {
        case "A":
            return true;
            break;
        case "O":
            return O_FILTER.includes(elem.versionType) && !elem.subversionType;
            break;
        case "I":
            return I_FILTER.includes(elem.versionType) || (elem.subversionType && elem.versionType !== elem.subversionType);
            break;
        case "R":
            return R_FILTER.includes(elem.name);
            break;
        default:
            break;
    }
}

function handleSetFields(key, fields, event, handler) {
    const newFields = {...fields};
    const value = event.target.value;
    newFields[key] = value;
    if (key !== 'offset') newFields.offset = 0;
    handler(newFields);
}
