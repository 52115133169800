import React, {useState, useContext, useEffect} from 'react';
import {WidgetHead} from 'components/common/DisplayElems';
import {AddDialog, DeleteDialog} from 'components/common/Dialogs';
import {ContentContext} from "context/ContentContext";
import VideoList from './VideosList';

export default function VideoSection() {
    const {artistResult, deleteArtistVideo, addArtistVideo, updateArtistVideo,
        updateArtistProcessing, updateArtistResult, handleVideoRequestClear} = useContext(ContentContext);

    const [showedDialog, showDialog] = useState(null);
    const [deletedElem, setDeletedElem] = useState(null);

    useEffect(() => {
        if (updateArtistResult) {
            showDialog(null);
            setDeletedElem(null);
            handleVideoRequestClear();
        }
    }, [updateArtistResult]);

    return <>
        <WidgetHead label="Videos"/>
        <VideoList
            artistResult={artistResult}
            setDeletedElem={setDeletedElem}
            updateArtistVideo={updateArtistVideo}/>
        <button
            id="addMoreVideo"
            className="btn btn-primary m-xl-4"
            onClick={() => showDialog('addVideo')}>Add more</button>
        {showedDialog === 'addVideo' &&
            <AddDialog
                disabled={updateArtistResult || updateArtistProcessing}
                onSave={data => addArtistVideo({...data, artistId: artistResult.id})}
                onClose={() => showDialog(null)}/>}
        {deletedElem &&
            <DeleteDialog
                deletedElem={deletedElem}
                disabled={updateArtistResult || updateArtistProcessing}
                onDelete={data => deleteArtistVideo({id: deletedElem.id, artistId: artistResult.id})}
                onClose={setDeletedElem.bind(this, null)}/>}
    </>;
}
