import React from 'react';
import PropTypes from 'prop-types';

Title.propTypes = {
    containerClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    title: PropTypes.string,
};

export default function Title({containerClassName, inputClassName, title}) {
    return (
        <div className={containerClassName}>
            <h3 className={inputClassName}>{title}</h3>
        </div>
    )
}
