import React, { useContext, useState } from 'react';
import withRouter from 'components/helpers/withRouter';
import { BounceLoader } from 'react-spinners';
import AlbumList from './Album/AlbumList';
import { TracksContext } from 'context/TracksContext';

function UpdateAlbumBanner({ isAlbumBannerUpdating, setIsAlbumBannerUpdating, banner }) {
    const { requestUpdateBannerTracks, removeUpdateBannerTrack, bannerTracksUp, bannerAlbumsUp, resetUpdateBannerTrack, isBannerTrackUpdating } = useContext(TracksContext);
    const [isBannerTracksUpdating, setIsBannerTracksUpdating] = useState(false); // Consolidate state naming

    const handleRemoveTrack = (track) => {
        removeUpdateBannerTrack(track);
    };

    const handleCancel = () => {
        setIsAlbumBannerUpdating(false); // Use the setter function to close the update process
        resetUpdateBannerTrack(); // Reset any tracks
    };

    const handleUpdateBanner = () => {
        setIsBannerTracksUpdating(true); // Indicate the banner is updating

        requestUpdateBannerTracks({
            bannerId: banner.id,
            setIsBannerTracksUpdating: setIsBannerTracksUpdating, // Pass setter function
        });
    };
    console.log('bannerTracksUp', bannerTracksUp);
    console.log('bannerAlbumsUp-----', bannerAlbumsUp);


    
    return (
        <div className="col-xl-12 m-0 p-0">
            {isBannerTrackUpdating && (
                <div className="loading">
                    <div className="loader">
                        <BounceLoader loading={true} size={60} color="#34bfa3" />
                        <span className="loading-text">Loading...</span>
                    </div>
                </div>
            )}

            <div className="m-0">
                <div className="d-flex m-3 p-1 pt-4 pb-4 banner-form">
                    <div className="col-xs-12 col-sm-12 col-lg-12 padding-remove">
                        <div className="row">
                            <div className="col-12" key={'_bannerTracks'}>
                                <div className="d-flex col-12 flex-wrap" key={'_bannerTracksBtn'}>
                                    {bannerTracksUp &&
                                        bannerTracksUp.length > 0 &&
                                        bannerTracksUp.map((track) => (
                                            <div className="button-container" key={track.id}>
                                                <button className="close-button" key={track.id + '_button'}>
                                                    {track.name}
                                                    <span className="close-icon" onClick={() => handleRemoveTrack(track)} key={track.id + '_close'}>
                                                        ×
                                                    </span>
                                                </button>
                                            </div>
                                        ))}
                                </div>
                                <div className="row mt-2 mb-0">
                                    <div className="col-lg-1">
                                        <button
                                            className="btn btn-sm btn-primary blue-button"
                                            disabled={bannerTracksUp.length === 0}
                                            type="button"
                                            onClick={handleUpdateBanner} // Call handleUpdateBanner here
                                        >
                                            <strong>Update banner</strong>
                                        </button>
                                    </div>
                                    <div className="col-lg-2">
                                        <button className="btn btn-sm btn-danger" type="button" onClick={handleCancel}>
                                            <strong>Cancel</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AlbumList setIsBannerTracksUpdating={setIsBannerTracksUpdating} /> 
                {/* Pass setter to AlbumList */}
            </div>
        </div>
    );
}

export default withRouter(UpdateAlbumBanner);
