import React from 'react';
import classnames from 'classnames';
import styles from './TextField.module.scss';

function TextField({className, inputClassName, labelClassName, name, label, ...additionalProps}) {
    return (
        <div className={classnames(styles['form-text-field'], 'form-group m-form__group ', className)}>
            {label && <label htmlFor={name} className={classnames('col-md-4')}>{`${label}: `}</label>}
            <input
                className={classnames('form-control m-input col-md-8', inputClassName)}
                type="text"
                placeholder="Email"
                name={name}
                autoComplete="off"
                {...additionalProps}/>
        </div>
    )
}
TextField.type = 'text';

export default TextField
