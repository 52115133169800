import React, {useContext, useState, useEffect, useRef} from 'react';
import TrackList from './TrackList'
import withRouter from 'components/helpers/withRouter';

import {TracksContext} from 'context/TracksContext';

function Tracks({history: routeHistory}) {
    const {toggleHidden} = useContext(TracksContext);
    const [updatedElem, setUpdatedElem] = useState(false);
    return <TrackList
    updatedElem={updatedElem}
    setUpdatedElem={setUpdatedElem}
        headTableButton={{label: 'Add tracks', onClick: () => routeHistory.push('/addTracks')}}
        subTableButtons={[
            {
                label: elem => elem.isDeleted === 'true' ? 'Show' : 'Hide',
                action: elem => toggleHidden({id: elem.id, type: 'version', isDeleted: elem.isDeleted === "false"}),
                className: elem => elem.isDeleted === 'true' ? 'btn-primary' : 'btn-danger'
            }
        ]}
        mainTableButtons={[
            {
                label: elem => elem.isDeleted === 'true' ? 'Show' : 'Hide',
                action: elem => toggleHidden({id: elem.id, type: 'track', isDeleted: elem.isDeleted === "false"}),
                className: elem => elem.isDeleted === 'true' ? 'btn-primary  btn-sm' : 'btn-danger  btn-sm'
            },
            {
                label: 'Edit',
                action: elem => setUpdatedElem(elem),
                className: 'btn-warning btn-sm'
            }
        ]}
    />
}

export default withRouter(Tracks);
