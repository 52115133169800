import React from 'react';
import makeHttpRequest from 'sources/api/http/makeHttpRequest';
import bindFunctions from './helpers/bindFunctions';
import {createRequestHelper} from './helpers/requestHelper';
import {isUnauthorizeError} from "sources/api/ApiError";

export const AuthContext = React.createContext();


const loginRequestHelper = createRequestHelper('login');
const logoutRequestHelper = createRequestHelper('logout');
const resetPasswordRequestHelper = createRequestHelper('resetPassword');
const confirmResetPasswordRequestHelper = createRequestHelper('confirmResetPassword');


export default class AuthContextContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...loginRequestHelper.initialState,
            ...logoutRequestHelper.initialState,
            ...resetPasswordRequestHelper.initialState,
            ...confirmResetPasswordRequestHelper.initialState,
            user: null,
            triedLoginByToken: false
        };

        this.funcs = bindFunctions(this, {
            login: this.login,
            logout: this.logout,
            loginWithToken: this.loginWithToken,
            resetPassword: this.resetPassword,
            confirmResetPassword: this.confirmResetPassword,
            setUser: this.setUser,
            makeAuthenticateHttpRequest: this.makeAuthenticateHttpRequest,
            updateProfile: this.updateProfile
        });
    }

    async componentDidMount() {
        await this.loginWithToken();
        this.setState({
            triedLoginByToken: true,
        })
    }

    logout = async () => {
        try {

            const result = await makeHttpRequest({
                method: 'post',
                path: '/users/logout'
            });
            this.setState({
                user: null
            });
        }
        catch (error) {
            this.setState(logoutRequestHelper.error(error));
        }
    }

    async login({email, password}) {
        this.setState(loginRequestHelper.processing());
        try {
            const result = await makeHttpRequest({
                method: 'post',
                path: '/admins/login-custom',
                data: {login: email, password}
            });
            this.setState({
                user: result.user,
                ...loginRequestHelper.clear()
            });

        }
        catch (error) {
            this.setState(loginRequestHelper.error(error));
        }
    }

     updateProfile = async (fields) => {
        try {
             const result = await makeHttpRequest({
                 method: 'post',
                 path: '/users/updateProfile',
                 data:{fields}
             });
            await this.loginWithToken();
         }
         catch (error) {
             console.log("Error update: ", error);
         }
    }

    async loginWithToken() {
        try {
            const result = await makeHttpRequest({
                method: 'post',
                path: '/admins/loginWithToken'
            });
            this.setUser(result.user);
        }
        catch (error) {
        }
    }

    async resetPassword({email}) {
        this.setState(resetPasswordRequestHelper.processing());
        try {
            const result = await makeHttpRequest({
                method: 'post',
                path: '/users/resetPassword',
                data: {email}
            });
            this.setState(resetPasswordRequestHelper.result(result));
        }
        catch (error) {
            this.setState(resetPasswordRequestHelper.error(error));
        }
    }

    async confirmResetPassword(token, {newPassword}) {
        this.setState(confirmResetPasswordRequestHelper.processing());
        try {
            await makeHttpRequest({
                method: 'post',
                path: '/users/confirmResetPassword',
                data: {newPassword, token}
            });

            await this.loginWithToken();
        }
        catch (error) {
            this.setState(confirmResetPasswordRequestHelper.error(error));
        }
    }

    makeAuthenticateHttpRequest = async (requestOptions) => {
        if (!this.state.user) throw Error('Authorise required for subscription');
        try {
            const result = await makeHttpRequest(requestOptions);
            return result;
        }
        catch (error) {
            if (isUnauthorizeError(error)) {
                this.setState({user: null});
            }
            throw error;
        }
    }

    setUser = (user) => {
        this.setState({user});
    };

    render() {
        const {triedLoginByToken} = this.state;

        if (!triedLoginByToken)
            return null;
        return (
            <AuthContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}
