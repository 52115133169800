import React, { useState, useContext, useEffect, useRef } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { CSVReader } from "react-papaparse";
import { ContentContext } from "../../../context/ContentContext";
import moment from "moment";
import ReactPaginate from "react-paginate";

import "../PaypalHistory/style.css";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";
var base64 = require("base-64");
const pconfig = require("../../../paypal.config.json");

export default function PaypalHistory() {
    const data_dd = useContext(ContentContext);
    const {
        getTranactionsData,
        transHistoryResult,
        transHistoryProcessing,
        unsubscribeUser,
        unsubscribeUserResult
    } = useContext(ContentContext);

    const [tansResult, setTansResult] = useState(-1);
    const [status, setStatus] = useState("");
    const [subscriptionId, setSubscriptionId] = useState(undefined);
    //const {list} = tracksResult;
    const [activePage, setActivePage] = useState(10);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(12);
    const [selected, setselected] = useState(0);
    // const [paypalToken, setPaypalToken] = useState('');

    const timer = useRef(null);
    const scrollDiv = useRef(null);

    const DEFAULT_FILTERS = { limit, offset };
    const [fields, setFields] = useState(DEFAULT_FILTERS);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getTranactionsData(fields);
        };
        fetchData();
    }, [fields]);

    const fields_validation = e => {
        //setSubscriptionId(subsId);
        setStatus("clicked");

        // if(from === undefined){
        //     setErrorFrom("error");
        //     scrollDiv.current.scrollIntoView({behavior: 'smooth'})
        // } else {
        //     setErrorFrom("");
        // }

        // if(to === undefined){
        //     setErrorTo("error");
        //     scrollDiv.current.scrollIntoView({behavior: 'smooth'})
        // } else {
        //     setErrorTo("");
        // }
        // if(from !== undefined && to !== undefined ){

        // } else{
        //     setStatus('');
        // }
    };

    const onPageChange = async data => {
        let selected = data.selected;
        setselected(selected);
        let offset = Math.ceil(selected * limit);
        setOffset(offset);
        const fields = { limit, offset };
        await getTranactionsData(fields);
    };

    const unsubsUser = async data => {
        //const data = {userid}
        await unsubscribeUser(data);
    };

    useEffect(() => {
        if (unsubscribeUserResult && unsubscribeUserResult.data === "success") {
            const fields = { limit, offset };
            const run = async () => {
                await getTranactionsData(fields);
            };
            run();
        }
    }, [unsubscribeUserResult]);

    const accessKey = base64.encode(
        `${pconfig.paypal_user}:${pconfig.paypal_pass}`
    );

    const getPayPalToken = async accessKey => {
        var data = qs.stringify({ grant_type: "client_credentials" });
        const config: AxiosRequestConfig = {
            method: "post",
            url: "https://api.sandbox.paypal.com/v1/oauth2/token",
            headers: {
                Authorization: `Basic ${accessKey}`,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            data: data
        };

        try {
            var token = "";
            const res: AxiosResponse = await axios(config);
            if (res) {
                token = await res.data.access_token;
            }
            return token;
        } catch (error) {
            console.log(error);
            return;
        }
    };

    const unsubscriber_User = async (e, data) => {
        const subscptId = data[0];
        if (window.confirm("Do you want to block user?")) {
            const userID = data[1];
            const subscriptionId = data[2];
            var dataFields = { userID, subscriptionId };
            await unsubsUser(dataFields);
            await getTranactionsData(fields);
        }

        // API
        // const ptoken = await getPayPalToken(accessKey);

        // const config : AxiosRequestConfig = {
        //         method: 'post',
        //         url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/I-K9CW5HYPUYDB/cancel/`,
        //         headers: {
        //           'Authorization': `Bearer ${ptoken}`,
        //           'Content-Type': 'application/x-www-form-urlencoded'
        //         }
        //     };

        // if(subscptId && ptoken){
        //     try {
        //         const res: AxiosResponse = await axios(config);
        //         if (res) {
        //             const resData  = await res.data;
        //             console.log("Token here:::::::::: ", resData)
        //         }

        //     } catch (error) {
        //        console.log(error)
        ///  return;
        //     }
        // }
    };

    // useEffect( () => {
    //     const run  = async () => {
    //        await  getPayPalToken(accessKey);
    //     }
    //    if(paypalToken === 0){
    //        run();
    //    }

    // }, [paypalToken])

    // useEffect( () => {
    //    const run  = async () => {
    //        await unsubscriber_User(subsID, paypalToken);
    //    }
    //    run();
    // }, [paypalToken]);

    return (
        <div>
            {/* {transHistoryResult !== true && status === "clicked" && (
                <Redirect to={`/subscdetails/I-K9CW5HYPUYDB`} />
            )} */}
            {transHistoryResult === true && (
                <em>
                    <b>Loading data....</b>
                </em>
            )}
            {transHistoryResult &&
                transHistoryResult.data &&
                transHistoryResult.data.length > 0 && (
                    <>
                        <table className="table m-table m-table--head-bg-success">
                            <thead>
                                <tr>
                                    <th>User ID</th>
                                    <th>User Name</th>
                                    <th>Eamil</th>
                                    <th>Subscription Id</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th>Created Time</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transHistoryResult.data.map((item, index) => (
                                    <tr key={index}>
                                        <th>{item.userId}</th>
                                        <th>{item.name}</th>
                                        <th>{item.email}</th>
                                        <th>{item.subscriptionId}</th>
                                        <th>{item.amount}</th>
                                        <th>{item.type}</th>
                                        <th>{item.status}</th>
                                        <th>{item.created_time}</th>
                                        <th>
                                            <ul className="list-inline actionsUl">
                                                <li className="list-inline-item">
                                                    <a
                                                        onClick={e =>
                                                            unsubscriber_User(
                                                                e,
                                                                [
                                                                    "I-K9CW5HYPUYDB",
                                                                    item.userId,
                                                                    item.subscriptionId
                                                                ]
                                                            )
                                                        }
                                                        title={
                                                            "Stop Subscriptions"
                                                        }
                                                        data-subs_id="165464654"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-slash-circle text-danger"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
                                                        </svg>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                <NavLink to={`/djuserdetails/${item.userId}`} className="m-menu__link m-menu__toggle">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="19"
                                                            height="19"
                                                            fill="currentColor"
                                                            className="bi bi-eye"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                        </svg>
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </th>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}

            {!transHistoryProcessing &&
                transHistoryResult &&
                transHistoryResult.data.length < 1 && (
                    <p>
                        <b>No result Found!</b>
                    </p>
                )}
            {transHistoryResult && transHistoryResult.totalCount > limit && (
                <div className="btn-send-djs">
                    <ReactPaginate
                        previousLabel={""}
                        nextLabel={""}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                            transHistoryResult.totalCount / limit
                        )}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={onPageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        forcePage={selected}
                    />
                </div>
            )}
        </div>
    );
}

function handleSetFields(key, fields, event, handler) {
    // const newFields = { ...fields };
    // const value = event.target.value;
    // newFields[key] = parseInt(value);
    // if (key !== "offset") newFields.offset = 0;
    // handler(newFields);
}
