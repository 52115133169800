import React from 'react';
import classnames from 'classnames';

import {BaseDialog} from "components/common/Dialogs";
import {TextField} from 'components/common/Form';
import styles from "./AddCuratedSetDialog.module.scss";

export default function DeleteCuratedSetDialog(props) {
    const {curatedSet = {}, onApprove, onClose} = props;

    return (
        <BaseDialog
            id="curatedDeleteDialog"
            label="Delete curated set"
            classes={styles["custom-modal-style"]}
            onClose={onClose}
        >
            <h5>Are you sure you want to delete this curated set?</h5>
            <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                <div className="col-md-4">
                    <div>
                        <img
                            key={curatedSet.image || 'nokey'}
                            className={classnames("img-thumbnail rounded m-widget3__img")}
                            style={{height: '200px', minWidth: '200px'}}
                            src={curatedSet.image}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <TextField
                        value={curatedSet.name}
                        name="Name"
                        label="Name"
                        type="text"
                        disabled="disabled"
                    />
                    <TextField
                        value={curatedSet.categoryName}
                        name="Category"
                        label="Category"
                        type="text"
                        disabled="disabled"
                    />
                    <TextField
                        value={curatedSet.genreName}
                        name="Genre"
                        label="Genre"
                        type="text"
                        disabled="disabled"
                    />
                </div>
            </div>
            
            <div className="d-flex flex-row justify-content-sm-end pl-xl-4 pt-xl-4">
                <button
                    type="submit"
                    className="btn btn-danger w-25 m-1"
                    onClick={() => onApprove(curatedSet.id)}
                    data-dismiss="modal"
                >
                    Yes
                </button>
                <button
                    type="cancel"
                    className="btn btn-primary w-25 m-1"
                    onClick={onClose}
                    data-dismiss="modal"
                >
                    No
                </button>
            </div>
        </BaseDialog>
    );
}