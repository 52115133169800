import _ from 'lodash';
import {errors} from "./texts";

const text = {
    ...errors
};

export default function getText(key, placeholder) {
    return _.get(text, key, placeholder || key);
}

export function getTextOrNull(key, placeholder) {
    return _.get(text, key, null);
}
