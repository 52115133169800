import React, {useState} from 'react';
import Bannerlist from './Bannerlist'
import withRouter from 'components/helpers/withRouter';
import {WidgetHead} from 'components/common/DisplayElems';

function Banners() {
    const [bannerWidgetHeadLabel, setBannerWidgetHeadLabel] = useState("All Banner");
    return <>
        <div className="m-portlet">
            <WidgetHead label={bannerWidgetHeadLabel}/>
            <Bannerlist
                setBannerWidgetHeadLabel={setBannerWidgetHeadLabel}
            />
        </div>
        </>
}

export default withRouter(Banners);
