import React, { useState, useContext, useEffect } from "react";
import { CSVReader } from "react-papaparse";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { TableWithPaginator, WidgetHead } from "components/common/DisplayElems";
import { Button, Error } from "components/common/ControlElems";
import { ContentContext } from "context/ContentContext";
import classnames from "classnames";
import styles from "./PaypalTransaction.module.scss";

const DEFAULT_FILTERS = {
    offset: 0,
    limit: 5
};

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const formatYear = date => {
    const words = date.split("/");
    words[0] = ("0" + words[0]).slice(-2);
    return words.join("/");
};

export default function PaypalTransaction() {
    const {
        userIdBySubResult,
        userIdBySubProcessing,
        getUserIdBySub,
        userIdByUserInfoResult,
        userIdByUserInfoProcessing,
        getUserIdByUserInfo,
        insertTransactions,
        insertTransactionsResult,
        insertTransactionsProcessing
    } = useContext(ContentContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);
    const [dispTrans, setDispTrans] = useState([]);
    const [processResult, setProcessResult] = useState(-1);
    const [submitDateResult, setSDResult] = useState(-1);
    const [userIdResult, setUIdResult] = useState(-1);

    useEffect(() => {
        const subTrans = transactions.slice(
            fields.offset,
            fields.limit + fields.offset
        );
        setDispTrans(subTrans);
    }, [transactions, fields]);

    useEffect(() => {
        const transWithUserIds = transactions.map(e => {
            return {
                ...e,
                userId: userIdBySubResult[e.subscriptionId]
                    ? userIdBySubResult[e.subscriptionId]
                    : null
            };
        });
        setTransactions(transWithUserIds);
    }, [userIdBySubResult]);

    useEffect(() => {
        const transWithUserIds = transactions.map(e => {
            if (!e.userId) {
                return {
                    ...e,
                    userId: userIdByUserInfoResult[e.email]
                        ? userIdByUserInfoResult[e.email]
                        : null
                };
            }
            return e;
        });
        setTransactions(transWithUserIds);
    }, [userIdByUserInfoResult]);

    useEffect(() => {
        if (insertTransactionsResult) {
            setSDResult(insertTransactionsResult.countDate);
            setUIdResult(insertTransactionsResult.countUserId);
            setProcessResult(insertTransactionsResult.count);
        }
    }, [insertTransactionsResult])

    const handleOnDrop = data => {
        const trans = [];
        for (let i = 1; i < data.length; i++) {
            const row = data[i].data;
            if (!row[0] || row[0] === "") break;
            const submitDate = dayjs
                .tz(`${row[0]} ${row[1]}`, `${row[2]}`)
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
            trans.push({
                transactionId: row[12],
                type: "paypal",
                submitDate,
                transactionStatus: row[5].toLowerCase(),
                invoiceNumber: row[25],
                amount: parseFloat(row[7]),
                subscriptionId: row[24],
                migration_id: parseInt(row[26]),
                userId: null,
                userName: row[3],
                address: `${row[29]} ${row[30]}`,
                city: row[31],
                state: row[32],
                zip: row[33],
                country: row[34],
                phoneNumber: row[35],
                email: row[10]
            });
        }
        setTransactions([...trans]);
        getUserIdBySub(trans.map(e => e.subscriptionId));
        getUserIdByUserInfo(trans.map(e => { return { name: e.userName, email: e.email } }));
    };

    const handleOnError = (err, file, inputElem, reason) => {
        setError(error);
    };

    const handleOnRemoveFile = data => {
        setTransactions([]);
        setProcessResult(-1);
    };
    return (
        <div>
            <div className="m-portlet">
                <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    noDrag
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                >
                    <span>Click to upload.</span>
                </CSVReader>
                {error && <Error text={JSON.stringify(error)} />}
                <div className="pl-xl-4 pt-xl-4 col-md-6 ">
                    <Button
                        className="mt-4"
                        label={insertTransactionsProcessing ? "Processing..." : "Process"}
                        disabled={
                            transactions.length === 0 ||
                            insertTransactionsProcessing ||
                            userIdBySubProcessing ||
                            userIdByUserInfoProcessing
                        }
                        onClick={event => insertTransactions(transactions)}
                    />
                </div>
                {processResult >= 0 && (
                    <div className="alert alert-info">
                        {processResult}/{transactions.length} Transactions are successfully inserted to the database.<br/>
                        {submitDateResult}/{transactions.length} submit date are successfully updated in the database.<br/>
                        {userIdResult}/{transactions.length} userids are successfully updated in the database.
                    </div>
                )}
                <TableWithPaginator
                    tableElems={[
                        "transactionId",
                        "transactionStatus",
                        "userName",
                        "submitDate",
                        "email",
                        "userId",
                        "subscriptionId",
                        "amount"
                    ]}
                    tableLabel="PaypalTransaction"
                    headers={[
                        "TransId",
                        "Status",
                        "UserName",
                        "SubmitDate",
                        "Email",
                        "userId",
                        "SubId",
                        "Amount"
                    ]}
                    data={dispTrans}
                    offset={fields.offset}
                    limit={fields.limit}
                    count={transactions.length}
                    options={[
                        { value: 5, text: "5" },
                        { value: 10, text: "10" },
                        { value: 20, text: "20" },
                        { value: 50, text: "50" }
                    ]}
                    offsetOnChange={value =>
                        handleSetFields(
                            "offset",
                            fields,
                            { target: { value } },
                            setFields
                        )
                    }
                    limitOnChange={event =>
                        handleSetFields("limit", fields, event, setFields)
                    }
                />
            </div>
        </div>
    );
}

function handleSetFields(key, fields, event, handler) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = parseInt(value);
    if (key !== "offset") newFields.offset = 0;
    handler(newFields);
}
