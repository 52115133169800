import React, {useContext, useState, useEffect} from 'react';
import {ContentContext} from "context/ContentContext";
import {TableWithPaginator, WidgetHead} from 'components/common/DisplayElems';
import VideoSection from './VideoSection';
import AvatarAndCover from './AvatarAndCover';
import NameAndBio from './NameAndBio';
import RotationAndSpotlight from './RotationAndSpotlight';
import SocialLinks from './SocialLinks';
import {Button} from "components/common/ControlElems/Button";


const DEFAULT_FILTERS = {offset: 0, limit: 5};
const SELECT_OPTIONS = [{value: 5, text: '5'}, {value: 10, text: '10'}, {value: 20, text: '20'},
    {value: 50, text: '50'}];

export default function Artist({match: {params: {id}}}) {
    const {getArtist, artistResult, updateTrackStatus, updatePeriods} = useContext(ContentContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);

    useEffect(() => {
        getArtist({id, ...fields});
    }, [fields]);

    if (!artistResult) {
        return <h3 className = "m-subheader__title m-subheader__title--separator" > LOADING </h3>
    }

    return (
        <div className="col-xl-12">
            <div className="m-portlet pd-xl-5">
                <WidgetHead label="Artist details page"/>
                <div className="col-xl-12 mt-xl-0 d-flex flex-column justify-content-start align-items-sm-start">
                    <AvatarAndCover/>
                    <div className="col-xl-12 mt-xl-5 d-flex flex-row justify-content-between align-items-sm-start">
                        <NameAndBio/>
                        <SocialLinks/>
                    </div>
                </div>
                <RotationAndSpotlight/>
                <div className="col-xl-12">
                <TableWithPaginator
                    data={artistResult.track}
                    headers={['Id', 'Name', 'Show on artist page']}
                    tableElems={['id', 'songName']}
                    tableLabel="TrackList"
                    offset={fields.offset}
                    limit={fields.limit}
                    count={artistResult.tracksCount}
                    select={{
                        checkHandler: (elem) => Boolean(elem.status === 'A'),
                        action: (event, elem) => updateTrackStatus({id: elem.id, status: elem.status === 'A' ? 'C' : 'A'})
                    }}
                    options={SELECT_OPTIONS}
                    offsetOnChange={(value) => handleSetFields('offset', fields, {target: {value}}, setFields)}
                    limitOnChange={(event) => handleSetFields('limit', fields, event, setFields)}/>
                <VideoSection/>
                </div>
            </div>
        </div>
    )
}

function handleSetFields(key, fields, event, handler) {
    const newFields = {...fields};
    const value = event.target.value;
    newFields[key] = value;
    handler(newFields);
}



