export function createRequestHelper(targetKey, initialValue) {
    const processingKey = targetKey + 'Processing';
    const resultKey = targetKey + 'Result';
    const errorKey = targetKey + 'Error';

    return {
        initialState: {
            [processingKey]: false,
            [resultKey]: initialValue || null,
            [errorKey]: null,
        },

        processing(resultObject) {
            return {
                [processingKey]: true,
                [resultKey]: resultObject || initialValue || null,
                [errorKey]: null,
            };
        },

        result(resultObject) {
            return {
                [processingKey]: false,
                [resultKey]: resultObject,
                [errorKey]: null,
            };
        },

        error(errorObject) {
            return {
                [processingKey]: false,
                [resultKey]: null,
                [errorKey]: errorObject,
            };
        },

        clear() {
            return {
                [processingKey]: false,
                [resultKey]: initialValue || null,
                [errorKey]: null,
            }
        }
    }
}

