import React, { useState, useContext, useEffect } from "react";
import { MoonLoader } from "react-spinners";
import { TableWithPaginator } from "components/common/DisplayElems";
import { ContentContext } from "context/ContentContext";
import DeactivateDialog from "components/common/Dialogs/DeactivateDialog";
import classnames from "classnames";
import styles from "./InvalidUsers.module.scss";

const DEFAULT_FILTERS = {
    offset: 0,
    limit: 5
};

export default function InvalidUsers() {
    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const [deactivateDialog, setDeactivateDialog] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const {
        invalidUsersResult,
        invalidUsersProcessing,
        getInvalidUserList,
        lockoutUser,
        lockUserResult,
        lockUserProcessing
    } = useContext(ContentContext);

    useEffect(() => {
        (() => getInvalidUserList(fields))();
    }, [fields.limit, fields.offset]);

    if (!invalidUsersResult) {
        return <div />;
    }

    const handleLock = data => {
        setCurrentId(data.id);
        setDeactivateDialog(true);
    };
    if (invalidUsersProcessing) {
        return (
            <div className="m-portlet">
                <div className={styles["loading-indicator"]}>
                    <MoonLoader loading={true} size={60} />
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="m-portlet">
                <TableWithPaginator
                    buttons={[
                        {
                            label: lockUserProcessing
                                ? "Processing..."
                                : "Lock",
                            action: handleLock,
                            className: "btn-primary w-60",
                            disabled: lockUserProcessing
                        }
                    ]}
                    tableElems={[
                        "id",
                        "email",
                        "fullName",
                        "login",
                        "allow_user",
                        "phone",
                        "date_added",
                        "downloadDate"
                    ]}
                    tableLabel="Invalid Users that are using the platform"
                    headers={[
                        "Id",
                        "Email",
                        "FullName",
                        "Login",
                        "Status",
                        "Phone",
                        "CreatedDate",
                        "Last Download",
                        "Actions"
                    ]}
                    data={invalidUsersResult.list}
                    offset={fields.offset}
                    limit={fields.limit}
                    count={invalidUsersResult.count}
                    options={[
                        { value: 5, text: "5" },
                        { value: 10, text: "10" },
                        { value: 20, text: "20" },
                        { value: 50, text: "50" }
                    ]}
                    offsetOnChange={value =>
                        handleSetFields(
                            "offset",
                            fields,
                            { target: { value } },
                            setFields
                        )
                    }
                    limitOnChange={event =>
                        handleSetFields("limit", fields, event, setFields)
                    }
                />
            </div>
            {deactivateDialog && (
                <DeactivateDialog
                    onClose={event => setDeactivateDialog(false)}
                    onDeactivate={event =>
                        handleDeactivate(
                            currentId,
                            fields,
                            lockoutUser,
                            setDeactivateDialog
                        )
                    }
                />
            )}
        </div>
    );
}

function handleSetFields(key, fields, event, handler) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = value;
    if (key !== "offset") newFields.offset = 0;
    handler(newFields);
}

function handleDeactivate(id, fields, lockoutUser, setDeactivateDialog) {
    setDeactivateDialog(false);
    lockoutUser({ id });
}
