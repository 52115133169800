import React, {useContext, useEffect} from 'react';

import {WidgetHead} from 'components/common/DisplayElems';
import {ContentContext} from 'context/ContentContext';
import RunScriptForm from './RunScriptForm';
import ConsoleContent from './ConsoleContent';
import withRouter from 'components/helpers/withRouter';

import './RunScripts.css'

const refreshConsoleInterval = 5000;

function RunScriptComponent() {
    const {
        runScriptResult, runScriptProcessing,
        runScript, createSyncConsoleInterval, stopScript
    } = useContext(ContentContext);

    useEffect(() => {
        const consoleInterval = createSyncConsoleInterval(refreshConsoleInterval);

        return () => {
            clearInterval(consoleInterval)
        }
    }, []);

    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                <WidgetHead label="Run Scripts"/>
                <div className="runScriptContainer">
                    <RunScriptForm runScript={runScript} isProcessing={runScriptProcessing} stopScript={stopScript}/>
                </div>
                <ConsoleContent consoleContent={runScriptResult} isProcessing={runScriptProcessing}/>
            </div>
        </div>
    )
}

export default withRouter(RunScriptComponent);
