import React from 'react';
import {Splash} from 'components/Widgets/Splash';
import {HeavyRotation} from 'components/Widgets/HeavyRotation';

export default function Dashboard() {
    return (
        <div>
            <Splash/>
            <HeavyRotation/>
        </div>
    )
}
