import React from 'react';
import consumeToContext from './helpers/consumeToContext';
import {AuthContext} from './AuthContext';
import {createRequestHelper} from "./helpers/requestHelper";


export const TransactionsContext = React.createContext();

const canceledSubscriptionsListHelper = createRequestHelper('canceledSubscriptionsList');
const cancelSubscriptionHelper = createRequestHelper('cancelSubscription');
const declinedTransactionListHelper = createRequestHelper('declinedTransactionList');

class TransactionsContextContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...canceledSubscriptionsListHelper.initialState,
            subscriptionsList: [],
            subscriptionsCount: 0,
        };

        this.funcs = {
            getCanceledSubscriptionsList: this.getCanceledSubscriptionsList,
            cancelSubscriptions: this.cancelSubscriptions
        };
    }


    getCanceledSubscriptionsList = async (data) => {
        this.setState(canceledSubscriptionsListHelper.processing());
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/transactions/getCanceledSubscriptions`,
                data,
            });
            this.setState({
                ...canceledSubscriptionsListHelper.clear(),
                subscriptionsList: result || [],
                subscriptionsCount: (result || []).length,
            });
        } catch (error) {
            this.setState(canceledSubscriptionsListHelper.error(error));
        }
    };

    cancelSubscriptions = async (ids, cb) => {
        this.setState(cancelSubscriptionHelper.processing());
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/transactions/cancelSubscriptions`,
                data: {ids},
            });

            this.setState({
                ...cancelSubscriptionHelper.clear(),
            });

            cb && cb();
            return result && result.isOK;
        } catch (error) {
            this.setState(cancelSubscriptionHelper.error(error));
        }
    };






    render() {
        return (
            <TransactionsContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </TransactionsContext.Provider>
        );
    }
}


export default consumeToContext('auth', AuthContext)(TransactionsContextContainer);


