import React from 'react';
import {WidgetHead} from 'components/common/DisplayElems';

export default ({consoleContent, isProcessing}) => {
    if (isProcessing) {
        consoleContent += '...PROCESSING'
    }

    return <div className="consoleContent">
        <WidgetHead label="Console"/>
        {consoleContent}
    </div>
}
