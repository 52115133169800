import React from 'react';
import {createRequestHelper} from './helpers/requestHelper';
import consumeToContext from './helpers/consumeToContext';
import {AuthContext} from './AuthContext';
import _ from 'lodash';
import {CATEGORY_ID, GENRE_ID} from "../helpers/constants";

const curatedTracksHelper = createRequestHelper('curatedTracks', []);
const curatedDetailedTracksHelper = createRequestHelper('curatedDetailedTracks', []);
const getCuratedSetHelper = createRequestHelper('curatedSet', {list: [], count: 0});

export const CuratedSetContext = React.createContext();

class CuratedSetContextContainer extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            ...getCuratedSetHelper.initialState,
            ...curatedTracksHelper.initialState,
            ...curatedDetailedTracksHelper.initialState,
            curatedTracksResult: [],
        };

        this.funcs = {
            getCuratedSets: this.getCuratedSets,
            editCuratedSet: this.editCuratedSet,
            createCuratedSet: this.createCuratedSet,
            setCuratedActivity: this.setCuratedActivity,
            deleteCuratedSet: this.deleteCuratedSet,
            addTrackToCuratedSet: this.addTrackToCuratedSet,
            removeTrackFromCuratedSet: this.removeTrackFromCuratedSet,
            getCuratedTracks: this.getCuratedTracks,
            getDetailedCuratedTracks: this.getDetailedCuratedTracks
        };
    }

    addTrackToCuratedSet = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            await makeAuthenticateHttpRequest({
                method: 'post',
                path: '/curatedSets/addTrack',
                data
            });
            const curatedSetTrackList = this.state.curatedTracksResult;
            this.setState(curatedTracksHelper.result([
                ...curatedSetTrackList,
                {
                    trackId: data.trackId,
                    mediaId: data.mediaId
                }
            ]));
        } catch (error) {
            this.setState(getCuratedSetHelper.error(error));
        }
    };

    removeTrackFromCuratedSet = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            await makeAuthenticateHttpRequest({
                method: 'post',
                path: '/curatedSets/removeTrack',
                data
            });
            const curatedSetTrackList = this.state.curatedTracksResult;
            this.setState(curatedTracksHelper.result(curatedSetTrackList.filter(el => el.mediaId !== data.mediaId)));
        } catch (error) {
            this.setState(getCuratedSetHelper.error(error));
        }
    };

    getCuratedTracks = async ({curatedSetId}) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'get',
                path: `/curatedSets/getCuratedSetMembers?curatedSetId=${curatedSetId}`
            });
            this.setState(curatedTracksHelper.result(result));
        } catch (error) {
            console.warn(error)
            this.setState(curatedTracksHelper.error(error));
        }
    };

    getDetailedCuratedTracks = async (curatedSetId) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const result = await makeAuthenticateHttpRequest({
                method: 'post',
                path: `/curatedSets/getCuratedSetTracks?curatedSetsList=${[curatedSetId]}&withHiddenVersions=${true}`
            });

            result[curatedSetId]
                ? this.setState(curatedDetailedTracksHelper.result(result[curatedSetId]))
                : this.setState(curatedDetailedTracksHelper.result([]));
        } catch (error) {
            console.warn(error);
            this.setState(curatedDetailedTracksHelper.error(error));
        }
    }

    getCuratedSets = async ({limit, offset, searchQuery = '', genreId = undefined, categoryId = undefined}) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const requestData = {
                name: searchQuery.trim(),
                genreIds: genreId === undefined ? undefined : [genreId],
                categoryId,
                limit,
                offset,
                allSets: true
            };
            const result = await makeAuthenticateHttpRequest({
                method: 'post',
                path: "/curatedSets/getList",
                data: requestData
            });
            this.setState(getCuratedSetHelper.result(result));
        } catch (error) {
            console.warn(error)
            this.setState(getCuratedSetHelper.error(error));
        }
    };

    setCuratedActivity = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            await makeAuthenticateHttpRequest({
                method: 'post',
                path: '/admins/setCuratedActivity',
                data
            });
        } catch (error) {
            this.setState(getCuratedSetHelper.error(error));
        }
    }

    deleteCuratedSet = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            await makeAuthenticateHttpRequest({
                method: 'post',
                path: '/admins/deleteCuratedSet',
                data
            });
        } catch (error) {
            this.setState(getCuratedSetHelper.error(error));
        }
    };

    createCuratedSet = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            await makeAuthenticateHttpRequest({
                method: 'post',
                path: '/admins/createCuratedSet',
                data: _.pick(data, [GENRE_ID, 'name', CATEGORY_ID]),
                files: {file: data.files[0]}
            });
        } catch (error) {
            this.setState(getCuratedSetHelper.error(error));
        }
    };

    editCuratedSet = async (data) => {
        const {auth: {makeAuthenticateHttpRequest}} = this.props;
        try {
            const files = data.files && {file: data.files[0]};

            await makeAuthenticateHttpRequest({
                method: 'post',
                path: '/admins/editCuratedSet',
                data: _.pick(data, ['name', GENRE_ID, CATEGORY_ID, 'curatedSetId']), files
            });
        } catch (error) {
            this.setState(getCuratedSetHelper.error(error));
        }
    };

    render() {
        return (
            <CuratedSetContext.Provider value={{...this.state, ...this.funcs}}>
                {this.props.children}
            </CuratedSetContext.Provider>
        );
    }
}

export default consumeToContext('auth', AuthContext)(CuratedSetContextContainer);


