import React from 'react';
import PropTypes from 'prop-types';

Tabs.propTypes = {
    tabs: PropTypes.array,
    current: PropTypes.any,
    onChoose: PropTypes.func,
    className: PropTypes.string
};

export default function Tabs({onChoose, tabs, current, className}) {
    return (
        <div className="m-portlet--body">
        <ul className={"nav nav-tabs " + className} role="tablist">
            {tabs.map(tab => {
                return (<li className={'show nav-link ' + (tab.value === current ? 'active' : '')}>
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={() => onChoose(tab.value)}>
                        {tab.text}
                    </div>
                </li>)
            })}
        </ul>
        </div>
    );
}
