import React, {useContext, useEffect} from 'react';
import {ContentContext} from "context/ContentContext";
import moment from 'moment';
import {WidgetHead} from 'components/common/DisplayElems';
import {Datepicker, Checkbox} from 'components/common/Form';


const FIELDS = [
    {type: 'name', label: 'Name'},
    {type: 'bio', label: 'Bio'}
];

export default function SocialLinks() {
    const {artistResult, updatePeriods, getBusySpotlightPeriods, busySpotlightPeriodsResult} = useContext(ContentContext);
    useEffect(() => {
            getBusySpotlightPeriods();
        }, []);

    const heavyRotationPeriod = getPeriod(artistResult, 'heavyRotation');
    const spotlightPeriod = getPeriod(artistResult, 'spotlight');
    const id = artistResult.id;

    const excludeDates = getExcludeDates(busySpotlightPeriodsResult, spotlightPeriod);

    return <>
            <WidgetHead label="Heavy Rotation"/>
            <div className="col-xl-12 mt-xl-2 d-flex flex-row justify-content-start align-items-sm-center">
                <Checkbox
                    label="Active"
                    value={artistResult.active}
                    onChange={handleSetPeriod.bind(this, heavyRotationPeriod, 'heavyRotation', 'active',
                        updatePeriods, id, [], !artistResult.active)}
                />
            </div>
            <div className="col-xl-12 mt-xl-2 d-flex flex-row justify-content-start align-items-sm-center">
                <Datepicker
                    label="Active from"
                    disabled={!artistResult.active}
                    value={heavyRotationPeriod.from}
                    maxDate={heavyRotationPeriod.to}
                    onChange={handleSetPeriod.bind(this, heavyRotationPeriod, 'heavyRotation', 'from',
                    updatePeriods, id, [])}
                />
                <Datepicker
                    label="Active to"
                    disabled={!artistResult.active}
                    minDate={heavyRotationPeriod.from}
                    onChange={handleSetPeriod.bind(this, heavyRotationPeriod, 'heavyRotation', 'to',
                        updatePeriods, id, [])}
                    value={heavyRotationPeriod.to}
                />

            </div>
            <WidgetHead label="Artist spotlight"/>
            <div className="col-xl-12 mt-xl-2 d-flex flex-row justify-content-start align-items-sm-center">
                <Checkbox
                    label="Active"
                    disabled={Object.keys(spotlightPeriod).length === 0}
                    value={spotlightPeriod.active}
                    onChange={handleSetPeriod.bind(this, spotlightPeriod, 'spotlight', 'active',
                        updatePeriods, id, [], !spotlightPeriod.active)}
                />
            </div>
            <div className="col-xl-12 mt-xl-2 d-flex flex-row justify-content-start align-items-sm-center">
                <Datepicker
                    label="Active from"
                    value={spotlightPeriod.from}
                    excludeDates={excludeDates}
                    maxDate={spotlightPeriod.to}
                    onChange={handleSetPeriod.bind(this, spotlightPeriod, 'spotlight', 'from',
                        updatePeriods, id, excludeDates)}
                />
                <Datepicker
                    label="Active to"
                    excludeDates={excludeDates}
                    minDate={spotlightPeriod.from}
                    onChange={handleSetPeriod.bind(this, spotlightPeriod, 'spotlight', 'to',
                        updatePeriods, id, excludeDates)}
                    value={spotlightPeriod.to}
                />
            </div>
        </>
}

function getMaxDateForSpotlight(spotlightPeriod, excludeDates) {
    let result = undefined;
    const {to, from} = spotlightPeriod;
    const fromDate = moment(from).toDate();
    const excludeDatesWithTo = [...excludeDates, fromDate].sort(timeSort).reverse();
    if (to) {
        result = moment(to).toDate();
        for (const datumn of excludeDatesWithTo) {
            if (datumn === fromDate) {
                break;
            }
            result = moment(datumn).subtract(1, 'days').toDate();
        }
    }
    return result;
}

function getMinDateForSpotlight(spotlightPeriod, excludeDates) {
    let result = undefined;
    const {from, to} = spotlightPeriod;
    const toDate = moment(to).toDate();
    const excludeDatesWithTo = [...excludeDates, toDate].sort(timeSort);
    if (from) {
        result = moment(from).toDate();
        for (const datumn of excludeDatesWithTo) {
            if (datumn === toDate) {
                break;
            }
            result = moment(datumn).add(1, 'days').toDate();
        }
    }
    return result;
}

function handleSetPeriod(oldPeriod, type, key, handler, artistId, excludeDates, value) {
    const normalValue = (key === 'from' || key === 'to')
        ? new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(),0,0,0,0))
        : value;
    const result = {...oldPeriod, artistId: Number.parseInt(artistId), type};
    result[key] = normalValue;
    if (key === 'to') {
        result.from = result.from ?  getMinDateForSpotlight({...result, to: normalValue}, excludeDates) : normalValue;
    } else if (key === 'from') {
         result.to = result.to ?  getMaxDateForSpotlight({...result, from: normalValue}, excludeDates) : normalValue;
    }
    handler(result);
}

function getPeriod(artist, neededType) {
    let result = {};
    if (artist && artist.period) {
        result = artist.period.find(({type}) => type === neededType) || {};
    }
    return result;
}

function getExcludeDates(busySpotlightPeriodsResult, currentPeriod) {
    const result = [];
    for (const period of busySpotlightPeriodsResult) {
        const {from, to, id} = period;
        if (currentPeriod && currentPeriod.id === id) continue;
        const start = moment(from);
        const end = moment(to);
        result.push(start.toDate());
        result.push(end.toDate());
        while (end.diff(start, 'days') > 0) {
            start.add(1, 'day');
            result.push(start.toDate());
        }
    }
    return result.sort(timeSort);
}


function timeSort(a,b) {
    return --a - --b;
}
