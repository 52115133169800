import React from 'react';
import PropTypes from 'prop-types';

import {WidgetHead} from './index';


Table.propTypes = {
    headers: PropTypes.array.isRequired,
    tableElems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    tableLabel: PropTypes.string,
    headButton: PropTypes.object,
    buttons: PropTypes.array
};
const TABLE_WIDTH = ['100px','160px','500px','252px','252px'];
export default function Table({isDataFetching='', headers, tableElems, tableWidth = TABLE_WIDTH,  data: rawData, tableLabel, headButton,
                                  buttons, select, subTable}) {
    let data = rawData;
    let tableWidthToUse = tableWidth;
    if (subTable) {
        data = addSubtableToTable(data, subTable);
        tableWidthToUse = subTable.tableWidth || tableWidth;
    }
// console.log(data.length,"data---table");
    return [<WidgetHead key="widgetHead" label={tableLabel} button={headButton}/>,
            <div key="widgetBody" className="m-portlet__body">
                <div className="m-section">
                    <div className="m-section__content">
                        <table className="table m-table m-table--head-bg-success">
                            <thead key="head">
                            <tr>
                                {headers.map((header, index) => {
                                    return (
                                        <th
                                            className={index === headers.length - 1 ? "text-center" : ''}
                                            key={header}>
                                            {header}
                                        </th>);
                                    })}
                            </tr>
                            </thead>
                            <tbody key="body">
                            {data.length === 0 && isDataFetching !== '' && isDataFetching === true &&
                                <>
                                    <tr>
                                        <th className="text-center" colSpan={8} >
                                            Loading....
                                        </th>
                                    </tr>
                                </>
                            }
                            {data.length > 0 && data.map((elem, index) => {
                                return (
                                    <tr
                                        key={elem.id || index}
                                        style={{ background: elem.isSubtable && subTable.background }}
                                    >
                                        {(elem.isSubtable ? subTable.tableElems : tableElems).map((key, columnIndex) => {
                                            return (
                                                <th
                                                    style={{ width: tableWidthToUse[columnIndex] }}
                                                    key={key}>
                                                    {typeof(key) === 'string' ? elem[key] : key(elem)}
                                                </th>);
                                        })}
                                        {renderButtons(buttons, elem, subTable)}
                                        {select &&
                                            <th className="text-center">
                                                <label className="h-100 form-check-label col-xl-12 d-flex flex-row justify-content-sm-center align-items-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={select.checkHandler(elem)}
                                                        onChange={(event => select.action(event, elem))}
                                                        className="checkbox form-check-input"/>
                                                </label>
                                            </th>
                                            }
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    ];
}

function renderButtons(commonButtons, elem, subTable) {
    let buttons = commonButtons;
    if (elem.isSubtable) {
        buttons = subTable.buttons;
    }
    if (!buttons) return null;
    return (
        <th className="d-flex fx-row justify-content-end align-items-center">
            {buttons.map(button => {
                return (
                    <button
                        style={{minWidth:'80px', ...button.style}}
                        key={elem.id + button.label}
                        className={'btn m-1 ' + (typeof(button.className) === 'function' ? button.className(elem) : button.className)}
                        onClick={button.action.bind(this, elem)}
                        disabled={button.disabled}>
                            {typeof(button.label) === 'function' ? button.label(elem) : button.label}
                        </button>);
            })}
        </th>
    );
}

function addSubtableToTable(data) {
    return data.reduce((result, elem) => {
        result.push(elem);
        if (elem.subTable) {
            elem.subTable.forEach(subTableElem => {
                subTableElem.isSubtable = true;
                result.push(subTableElem);
            })
        }
        return result;
    }, [])
}
