import React, {useContext, useState, useEffect, useRef} from 'react';
import {ContentContext} from "context/ContentContext";
import {TextField, Textarea} from 'components/common/Form';

const FIELDS = [
    {type: 'name', label: 'Name'},
    {type: 'bio', label: 'Bio'}
];

export default function SocialLinks() {
    const {artistResult: {name, bio}, artistResult, updateArtist, updateSocialLinks, setNullArtist} = useContext(ContentContext);
    const [fields, setFields] = useState({});
    const timer = useRef(null);
    useEffect(updateOnTimer.bind(this, fields, timer, updateArtist, updateSocialLinks, artistResult),[fields]);
    useEffect(() => {
        return setNullArtist;
    },[]);
    return (
        <div className="col-xl-6">
            <TextField
                label="Name"
                placeholder="Name"
                defaultValue={name}
                onChange={event => {
                    handleSetFields({value: event.target.value, field: FIELDS[0], fields, setFields})
                }}/>
            <Textarea
                label="Bio"
                placeholder="Bio"
                defaultValue={bio}
                onChange={event => {
                    handleSetFields({value: event.target.value, field: FIELDS[1], fields, setFields})
                }}/>
        </div>
    )
}

function updateOnTimer(fields, timer, updateArtist,  updateSocialLinks, artist) {
    if (timer.current === null) {
        timer.current = undefined;
        return;
    }
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
        const result = {};

        FIELDS.forEach(field => {
            const currentValue = artist[field.type];
            if (currentValue !== fields[field.type]) {
                result[field.type] = fields[field.type];
            }
        });
        if (result.bio) updateSocialLinks({bio: result.bio, artistId: artist.id});
        if (result.name) updateArtist({name: result.name, artistId: artist.id});
    }, 2000);

    return () => clearTimeout(timer.current);
}

function handleSetFields({value, field, fields, setFields}) {
    const newFields = {...fields};
    newFields[field.type] = value;
    setFields(newFields);
}
