import React from 'react';
import PropTypes from 'prop-types';

export default function TextField({className, inputClassName, name, label, ...additionalProps}) {
    return (
      <div className={'form-group m-form__group ' + className}>
          {label && <label htmlFor={name}>{label}</label>}
        <textarea
            className={'form-control m-input ' + inputClassName}
            placeholder="Email"
            name="email"
            autoComplete="off"
            {...additionalProps}/>
        </div>
    )
}
