import React, {useContext, useEffect, useState, useRef} from 'react';
import uuidv1 from 'uuid/v1';
import {WidgetHead} from '../../common/DisplayElems';
import { Table } from './';
import {TracksContext} from 'context/TracksContext';
import {useDropzone} from 'react-dropzone'
import {BounceLoader} from "react-spinners";
import {parse} from 'id3-parser';
import {convertFileToBuffer} from 'id3-parser/lib/universal/helpers';
import {Select} from '../../common/Form';
const METADATA_MAP = [['bpm', 'bpm'], ['artist', 'artist'], ['genre', 'genre'], ['initial-key', 'key']];

const types = ['Clean', 'Dirty', 'Instrumental', 'Acapella', 'Super Clean', 'Remix Clean', 'Remix Dirty',
    'Remix Instrumental', 'Remix Acapella', 'Remix Super Clean', 'Acapella Clean', 'Acapella Dirty', 'Intro Clean',
    'Intro Dirty', 'Main'];

function AddTracks() {
    const {trackUploadingRes, trackUploading, fetchPresets, allPresets, uploadAllTrack, cancelAllUploads, setTracksToUpload, tracksToUpload, getGenresAndCategories, genresResult: {genres}} = useContext(TracksContext);
    const {getInputProps} = useDropzone({onDrop: onDrop.bind(this, setTracksToUpload)})
    const inputRef = useRef(null);
    const [albumArtist, setAlbumArtist] = useState('');
    const [preset, setPreset] = useState('');
    const [isAlbum, setIsAlbum] = useState(false);
    const [albumName, setAlbumName] = useState('');
    const [genre, setGenre] = useState('');
    const [isUploading, setIsUploading] =useState(false);
    const [presets, setPresetd] = useState([{value: '', text: 'All'}]);
    const [Genres, setGenres] = useState([{value: '', text: 'All'}]);
    // console.log(tracksToUpload,"tracksToUpload-----3");

    useEffect(()=>{
        (isAlbum !== true) && setAlbumName('') &&  setAlbumArtist('');
    },[isAlbum])

    useEffect(()=>{
        if (inputRef.current) {
            inputRef.current.value = null;
        }
    },[getInputProps])

    useEffect(()=>{
        if (trackUploading === false && trackUploadingRes.status === 'processed') {
            setTimeout(() => {
                setIsUploading(trackUploading);
            }, 2000);
        }else{
            setIsUploading(trackUploading);
        }
        
    },[trackUploading])

    useEffect(()=>{
        getGenresAndCategories();
        fetchPresets()
    },[])

    useEffect(()=>{
        (preset.toLowerCase() !== 'promo albums') ? setIsAlbum(false) :  setIsAlbum(true);
    },[genre,preset])
    
    useEffect(() => {
        if (Array.isArray(allPresets) && allPresets.length > 0) {
            const sortedAllPresets = [...allPresets].filter((p) => {
                return (p.name.toLowerCase() !== 'acapellas' && p.name.toLowerCase() !== 'instrumentals');
            }).sort((a, b) => a.name.localeCompare(b.name));
            
            console.log(sortedAllPresets, 'sortedAllPresets---------');
            const mappedAllPresets = sortedAllPresets.map((preset) => ({
                value: preset.name,
                text: preset.name,
            }));
            setPresetd([{ value: '', text: '--NONE--' }, ...mappedAllPresets]);
        }
    }, [allPresets]);

    useEffect(() => {
        if (Array.isArray(genres) && genres.length > 0) {
            const sortedGenres = [...genres].sort((a, b) => a.localeCompare(b));
            const mappedGenres = sortedGenres.map((genre) => ({
                value: genre,
                text: genre,
            }));
            setGenres([{ value: '', text: '--NONE--' }, ...mappedGenres]);
        }
    }, [genres]);
    // console.log(genres, 'genres-----')
    useEffect(()=>{
        const hasVersion = tracksToUpload.every((t) => t.metadata.version && t.metadata.version.length > 0);
        setIsAllTrackHasVersion(hasVersion);
    },[tracksToUpload,genres,allPresets])

    const handleCancelAllUploads = () => {
        setPreset('')
        setGenre('')
        setIsUploading(false);
        cancelAllUploads();
    };
      
    const handleSaveAllUploads = async () => {
        setIsUploading(true);
        await uploadAllTrack({preset, genre, tracksToUpload, isAlbum, albumArtist, albumName});
        setPreset('')
        setGenre('')
    };

    const overlayStyles = {
        background: 'rgb(255 255 255 / 77%)',
        position: 'absolute',
        top: 0,
        height: '100%',
        zIndex: 1,
        width: '100%',
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        flexWrap:'wrap',
        alignContent:'center',
      };

      const trackUploadingTxt = {
        color: trackUploadingRes.color,
        width:'100%',
        textAlign:'center',
        fontSize:'16px',
        padding:'10px',
        fontWeight:'600',
      }
      
      const [isAllTrackHasVersion, setIsAllTrackHasVersion] = useState(false);
    //   console.log(tracksToUpload,isAllTrackHasVersion, 'tracksToUpload-------------xxxxxx')
    //   console.log(tracksToUpload.length === 0,genre === '',preset === '',isAllTrackHasVersion,tracksToUpload.length === 0 || genre !== '' || preset !== '' || isAllTrackHasVersion, "tracksToUpload.length !== 0 , genre !== '' , preset !== '' , isAllTrackHasVersion === false")
    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                {(isUploading === true) &&
                    <>
                        <div style={overlayStyles}>                         
                            <BounceLoader loading={true} size={60} color='#34bfa3'/>
                            <span style={trackUploadingTxt}>{trackUploadingRes.text}</span>
                        </div>
                    </>
                }
                <WidgetHead label="AddTracks"/>
                <div className="d-flex">
                    <div className="d-flex flex-row justify-content-sm-start col-3 pl-xl-4 pt-xl-4">
                        <input 
                            {...getInputProps()} 
                            style={{display: 'block'}} 
                            className='form-control' 
                            ref={inputRef}
                            disabled={ preset === '' }
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-sm-start col-3 pl-xl-4 pt-xl-4">
                        <Select name="presets"
                            label="Presets"
                            className="w-100 mr-5"
                            value={preset}
                            options={presets}
                            onChange={(event) => setPreset(event.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-sm-start col-3 pl-xl-4 pt-xl-4">
                        <Select name="Genre"
                            label="Genre"
                            className="w-100 mr-5"
                            value={genre}
                            options={Genres}
                            onChange={(event) => setGenre(event.target.value)}
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-sm-center col-3 pl-xl-4 pt-xl-4">
                        <button
                            style={{minWidth:'80px'}}
                            className={'btn m-1 btn-danger'}
                            onClick={handleSaveAllUploads}
                            disabled={tracksToUpload.length === 0 || genre === '' || preset === '' || !isAllTrackHasVersion}
                        >
                            SAVE ALL
                        </button>
                        <button
                            style={{minWidth:'80px'}}
                            className={'btn m-1 btn-default'}
                            onClick={handleCancelAllUploads}
                            disabled={tracksToUpload.length === 0}
                        >
                            CANCEL ALL UPLOADS
                        </button>
                    </div>
                </div>
                    {(isAlbum === true) && (
                        <>
                        <div className="d-flex flex-row">
                            <div className="col-3"></div>
                            <div className="col-3 d-flex align-items-center">
                                <label htmlFor='artist' className={'col-md-4'}>Artist</label>
                                <input type='text' name='artist' value={albumArtist} className={'w-100 mr-5 form-control'} onChange={(event) => setAlbumArtist(event.target.value)} />
                            </div>
                            <div className="col-3 d-flex align-items-center ">
                                <label htmlFor='albumName' className={'col-md-4'}>Album Name</label>
                                <input type='text' name='albumName' value={albumName} className={'w-100 mr-5 form-control'} onChange={(event) => setAlbumName(event.target.value)} />
                            </div>
                            <div className="col-3"></div>
                        </div>
                        </>
                    )}
                    <Table
                        tableLabel="TrackList Queue"
                        tableWidth={['252px','300px','50px','100px']}
                        headers={['#', 'Artist', 'Song Name', 'Version','Status','']}
                        data={(tracksToUpload.length > 0) ? tracksToUpload.map(elem => {return {...elem.metadata, id: elem.id, link: elem.title, file: elem.file || 'file'}}) : []}
                        isUploading = {isUploading}
                        preset={preset}
                    />
            </div>
        </div>
    )
}

const onDrop = async (setTracksToUpload, files) => {
    const filesWithMetadata = [];
    files = [...files].reverse();
    for (const file of files) {
        try {
        const metadata = await new Promise((res, rej) => {
            convertFileToBuffer(file).then(parse).then(tags => {
                res(normalizeMetadata(tags));
            }).catch(e => rej(e));
        });

        if (metadata) {
            filesWithMetadata.push({file, metadata: {...metadata, title: file.path}, id: uuidv1()});

        }
        } catch (e) {
            filesWithMetadata.push({file, error: 'Invalid file'})
        }
    }
    console.log(filesWithMetadata,"filesWithMetadata-----");
    setTracksToUpload(filesWithMetadata);
};

function normalizeMetadata(metadata) {
    const normalizedMetadata = {};
    METADATA_MAP.forEach(([rawKey, normKey]) => {
        if (metadata[rawKey]) {
            normalizedMetadata[normKey] = metadata[rawKey];
        }
    });

    normalizedMetadata.version = getVersion({songName: metadata.title});
    normalizedMetadata.subversion = getVersion({songName: metadata.title, isSubversion: true});
    normalizedMetadata.songName = getSongName(metadata.title);
    return normalizedMetadata;
}

function getSongName(rawSongName) {
    return rawSongName && rawSongName.replace(/\((.*?)\)/g, '');
}

function getVersion({songName, isSubversion}) {
    let versions = songName && songName.match(/\((.*?)\)/g);

    if (!versions && !isSubversion) {
        return null;
    } else if (!versions) {
        return null;
    }
    versions = versions.map(version => version.replace(/(\(|\))/g, ''));

    let result = null;

    versions.forEach(version => {
        if (types.includes(version) && !isSubversion) {
            result = version;
        } else if (!types.includes(version) && isSubversion) {
            result = version;
        }
    });
    return result;
}

export default AddTracks;