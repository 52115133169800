import React from 'react';
import classnames from 'classnames';
import styles from './Select.module.scss';
import PropTypes from 'prop-types';

export default function Select({className, label, name, onChange, options, labelClass='col-md-4', ...additionProps}) {
    return (
        <div className={classnames(className, styles['form-select'])}>
            {label && <label htmlFor={name} className={classnames(labelClass)}>{label}</label>}
            <select
                className="form-control m-input col-md-8"
                onChange={(event) => onChange(event)}
                {...additionProps}
                id={name}>
                {options.map(option => <option key={option.text} value={option.value}>{option.text}</option>)}
            </select>
        </div>
    )
}
