const errors = {
    "errors": {
        "email": {
            "not-found": "No user with such email",
            "not-verified": "Email isn't verified",
            "custom": "Malformed email",
            "duplicate": "Email is busy "
        },
        "login": {
            "duplicate": "Login is busy"
        },
        "user": {
            "doesnt-exist": "User not exist",
            "forbidden": "The password is incorrect"
        }
    }
}

export default errors;
