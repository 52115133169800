import React, {useContext, useState, useEffect} from 'react';
import {Table} from 'components/common/DisplayElems';
import {ContentContext} from "context/ContentContext";

import {AddDialog, DeleteDialog} from 'components/common/Dialogs';

export default function Splash() {
    const {splashResult, getSplashContent,
        addSplash, addSplashResult, addSplashClear,
        deleteSplash, deleteSplashResult, deleteSplashClear
    } = useContext(ContentContext);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [deletedElem, setDeletedElem] = useState(false);
    const [editedElem, setEditedElem] = useState(false);

    useEffect(() => {
        (() => getSplashContent())();
    }, []);

    useEffect(() => {
        if (addSplashResult) {
            setShowAddDialog(false);
            setEditedElem(false);
            addSplashClear();
            getSplashContent();
        }
    }, [addSplashResult]);

    useEffect(() => {
        if (deleteSplashResult) {
            setDeletedElem(false);
            deleteSplashClear();
            getSplashContent();
        }
    }, [deleteSplashResult]);

    return (

        <div className="col-xl-12">
            <div className="m-portlet">
                <Table
                    buttons={[
                        {label: 'Edit', action: setEditedElem, className: 'btn-primary'},
                        {label: 'Delete', action: setDeletedElem, className: 'btn-danger'}]}
                    tableElems={['id', 'type', 'link', 'trackName', 'artistName']}
                    headButton={{label: 'Add the new video', onClick: () => setShowAddDialog( true)}}
                    tableLabel="Splash page settings"
                    headers={['Position', 'Type', 'Source link', 'Track', 'Artist', 'Actions']}
                    data={splashResult.list}/>
                {(showAddDialog || editedElem) &&
                    <AddDialog
                        addType={'splash'}
                        editedElem={editedElem}
                        onSave={addSplash.bind(this)}
                        onClose={() => {
                            setShowAddDialog(false);
                            setEditedElem(false);
                        }}/>}
                {deletedElem &&
                    <DeleteDialog
                        deletedElem={deletedElem}
                        onDelete={deleteSplash.bind(this)}
                        onClose={setDeletedElem.bind(this, false)}/>}
            </div>
        </div>
    )
}
