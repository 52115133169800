import React, { useContext, useState, useEffect } from "react";
import classnames from "classnames";
import { TableWithPaginator, WidgetHead } from "components/common/DisplayElems";
import { TextField } from "components/common/Form";
import { ContentContext } from "context/ContentContext";
import styles from "./DJUsers.module.scss";

const DEFAULT_FILTERS = {
    id: null,
    email: "",
    djName: "",
    username: "",
    loginid: "",
    migId: null,
    authEmail: "",
    paypalEmail: "",
    billingName: "",
    billingAddress: "",
    authSubId: "",
    paypalSubId: "",
    offset: 0,
    limit: 5
};

export default function DJUsers({ history: routeHistory }) {
    const { djUsersResult, djUsersProcessing, getDJUserList } = useContext(
        ContentContext
    );
    const [fields, setFields] = useState(DEFAULT_FILTERS);

    useEffect(() => {
        (() => getDJUserList(fields))();
    }, [fields.limit, fields.offset]);

    if (!djUsersResult) {
        return <div />;
    }
    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                <WidgetHead label="DJ users" />
                <WidgetHead
                    label="Filter"
                    button={{
                        label: djUsersProcessing ? "Searching..." : "Search",
                        onClick: e => getDJUserList(fields),
                        disabled: djUsersProcessing
                    }}
                />
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                    <div className={classnames(styles["left-panel"])}>
                        <TextField
                            value={fields.id}
                            name="Id"
                            label="Id"
                            type="number"
                            min="0"
                            placeholder="Id"
                            onChange={event =>
                                handleSetFields("id", fields, event, setFields)
                            }
                        />
                        <TextField
                            value={fields.email}
                            name="Email"
                            label="Email"
                            type="text"
                            placeholder="Email"
                            onChange={event =>
                                handleSetFields(
                                    "email",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.djName}
                            name="DjName"
                            label="DjName"
                            type="text"
                            placeholder="DjName"
                            onChange={event =>
                                handleSetFields(
                                    "djName",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.username}
                            name="FullName"
                            label="FullName"
                            type="text"
                            placeholder="FullName"
                            onChange={event =>
                                handleSetFields(
                                    "username",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.loginid}
                            name="Login"
                            label="Login"
                            type="text"
                            placeholder="Login"
                            onChange={event =>
                                handleSetFields(
                                    "loginid",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.migId}
                            name="Migration ID"
                            label="Migration ID"
                            type="text"
                            placeholder="Migration ID"
                            onChange={event =>
                                handleSetFields(
                                    "migId",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                    </div>
                    <div className={classnames(styles["right-panel"])}>
                        <TextField
                            value={fields.authEmail}
                            name="Auth Email"
                            label="Auth Email"
                            type="text"
                            placeholder="Auth Email"
                            onChange={event =>
                                handleSetFields(
                                    "authEmail",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.paypalEmail}
                            name="Paypal Email"
                            label="Paypal Email"
                            type="text"
                            placeholder="Paypal Email"
                            onChange={event =>
                                handleSetFields(
                                    "paypalEmail",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.billingName}
                            name="Billing Name"
                            label="Billing Name"
                            type="text"
                            placeholder="Billing Name"
                            onChange={event =>
                                handleSetFields(
                                    "billingName",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.billingAddress}
                            name="Billing Address"
                            label="Billing Address"
                            type="text"
                            placeholder="Billing Address"
                            onChange={event =>
                                handleSetFields(
                                    "billingAddress",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.paypalSubId}
                            name="Paypal SubId"
                            label="Paypal SubId"
                            type="text"
                            placeholder="Paypal Subscription Id"
                            onChange={event =>
                                handleSetFields(
                                    "paypalSubId",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                        <TextField
                            value={fields.authSubId}
                            name="Auth SubId"
                            label="Auth SubId"
                            type="text"
                            placeholder="Auth Subscription Id"
                            onChange={event =>
                                handleSetFields(
                                    "authSubId",
                                    fields,
                                    event,
                                    setFields
                                )
                            }
                        />
                    </div>
                </div>
                <TableWithPaginator
                    buttons={[
                        {
                            label: "Info",
                            action: elem =>
                                routeHistory.push("/djuserdetails/" + elem.id),
                            className: "btn-primary w-60"
                        }
                    ]}
                    tableElems={[
                        "id",
                        "email",
                        "djName",
                        "fullName",
                        "login",
                        "payment_type",
                        "allow_user",
                        "phone",
                        "date_added"
                    ]}
                    tableLabel="DJUsers"
                    headers={[
                        "Id",
                        "Email",
                        "DjName",
                        "FullName",
                        "Login",
                        "PaymentType",
                        "Status",
                        "Phone",
                        "CreatedDate",
                        "Actions"
                    ]}
                    data={djUsersResult.list}
                    offset={fields.offset}
                    limit={fields.limit}
                    count={djUsersResult.count}
                    options={[
                        { value: 5, text: "5" },
                        { value: 10, text: "10" },
                        { value: 20, text: "20" },
                        { value: 50, text: "50" }
                    ]}
                    offsetOnChange={value =>
                        handleSetFields(
                            "offset",
                            fields,
                            { target: { value } },
                            setFields
                        )
                    }
                    limitOnChange={event =>
                        handleSetFields("limit", fields, event, setFields)
                    }
                />
            </div>
        </div>
    );
}

function handleSetFields(key, fields, event, handler) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = value;
    if (key !== "offset") newFields.offset = 0;
    handler(newFields);
}
