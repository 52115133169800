import React, { useState, useContext, useEffect } from "react";

import { BaseDialog } from "components/common/Dialogs";
import { ContentContext } from "context/ContentContext";
import { TextField } from "../../common/Form";
import { fieldProps } from "./formFieldHelper";
import { Error } from "components/common/ControlElems";
import validatePaymentFields from "./validator/validatePaymentFields";
import styles from "./BillingDialog.module.scss";

export default function BillingDialog({
    onClose,
    userId,
    transHistory,
    setUserInfo,
    userInfo
}) {
    const {
        subscribe,
        clearSubscribe,
        subscribeProcessing,
        subscribeError,
        subscribeResult
    } = useContext(ContentContext);
    useEffect(() => {
        if (subscribeResult) {
            if (transHistory && subscribeResult.transInfo) {
                transHistory.list.pop();
                transHistory.list.unshift(subscribeResult.transInfo);
            }
            const newInfo = { ...userInfo };
            newInfo.active = 10;
            setUserInfo(newInfo);
            onClose();
        }

        return () => {
            clearSubscribe();
        };
    }, [subscribeResult]);

    const [fields, setFields] = useState({
        cardNumber: "",
        month: "",
        year: "",
        fname: "",
        lname: "",
        cvc: "",
        address: "",
        state: "",
        city: "",
        zipcode: "",
        country: "",
        email: "",
        phone: "",
        months: ""
    });
    const [isSubmit, setSubmit] = useState(false);

    const errors = isSubmit ? validatePaymentFields(fields) : {};
    const errorText =
        Object.keys(errors).length > 0
            ? errors[`${Object.keys(errors)[0]}`]
            : "";
    return (
        <BaseDialog
            id="billingDialog"
            label="User Billing"
            classes={styles["custom-modal-style"]}
            onClose={onClose}
        >
            <div className="d-flex form-group">
                <div className="col-md-6">
                    <TextField
                        key="cardNumber"
                        label="Card Number"
                        required
                        placeholder="Card Number"
                        {...fieldProps("cardNumber", "string", {
                            fields,
                            setFields: handleSetFields.bind(
                                this,
                                setFields,
                                "cardNumber"
                            )
                        })}
                    />
                    <TextField
                        key="month"
                        label="Month"
                        required
                        placeholder="Month"
                        {...fieldProps("month", "text", {
                            fields,
                            setFields: handleSetFields.bind(
                                this,
                                setFields,
                                "month"
                            )
                        })}
                    />
                    <TextField
                        key="year"
                        label="Year"
                        required
                        placeholder="Year"
                        {...fieldProps("year", "text", {
                            fields,
                            setFields: handleSetFields.bind(
                                this,
                                setFields,
                                "year"
                            )
                        })}
                    />
                    <TextField
                        key="cvc"
                        label="CVC"
                        required
                        placeholder="CVC"
                        {...fieldProps("cvc", "password", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="firstName"
                        label="First Name"
                        required
                        placeholder="First Name"
                        {...fieldProps("fname", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="lastName"
                        label="Last Name"
                        required
                        placeholder="Last Name"
                        {...fieldProps("lname", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="address"
                        label="Address"
                        required
                        placeholder="Address"
                        {...fieldProps("address", "text", {
                            fields,
                            setFields
                        })}
                    />
                </div>
                <div className="col-md-6">
                    <TextField
                        key="city"
                        label="City"
                        required
                        placeholder="City"
                        {...fieldProps("city", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="state"
                        label="State"
                        required
                        placeholder="State"
                        {...fieldProps("state", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="zip"
                        label="Zip"
                        required
                        placeholder="Zip"
                        {...fieldProps("zipcode", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="country"
                        label="Country"
                        required
                        placeholder="Country"
                        {...fieldProps("country", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="email"
                        label="Email"
                        required
                        placeholder="Email"
                        {...fieldProps("email", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="phone"
                        label="Phone"
                        required
                        placeholder="Phone"
                        {...fieldProps("phone", "text", {
                            fields,
                            setFields
                        })}
                    />
                    <TextField
                        key="months"
                        label="Months"
                        required
                        placeholder="Months"
                        {...fieldProps("months", "text", {
                            fields,
                            setFields: handleSetFields.bind(
                                this,
                                setFields,
                                "months"
                            )
                        })}
                    />
                </div>
            </div>
            <Error
                text={
                    subscribeError ? JSON.stringify(subscribeError) : errorText
                }
            />
            <div className="d-flex flex-row justify-content-between">
                <button
                    type="button"
                    className="btn btn-danger w-25"
                    onClick={() => onClose()}
                    data-dismiss="modal"
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-success w-25"
                    onClick={e =>
                        handleSubscribe(
                            {
                                setSubmit,
                                fields,
                                subscribe,
                                subscribeProcessing,
                                userId
                            },
                            e
                        )
                    }
                    disabled={subscribeProcessing}
                    data-dismiss="modal"
                >
                    {subscribeProcessing
                        ? "Creating the subscription"
                        : "Subscribe"}
                </button>
            </div>
        </BaseDialog>
    );
}

function handleSetFields(setFields, key, value) {
    const newValue = { ...value };
    newValue[key] = value[key].replace(/[^0-9]+/g, "");
    if (key === "cardNumber") {
        let currentValue = newValue.cardNumber.substring(0, 16);
        currentValue = currentValue.match(/.{1,4}/g);
        if (currentValue !== null) {
            newValue.cardNumber = currentValue.join(" ");
        }
    }
    setFields(newValue);
}

function handleSubscribe(
    {
        setSubmit,
        fields: rowFields,
        subscribe,
        subscriptionUserProcessing,
        userId
    },
    e
) {
    e.preventDefault();
    setSubmit(true);
    const fields = { ...rowFields };
    fields.cardNumber = fields.cardNumber.replace(/[^0-9]+/g, "");
    if (
        !subscriptionUserProcessing &&
        Object.keys(validatePaymentFields(fields)).length === 0
    ) {
        subscribe(userId, fields);
    }
}
