import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {Dashboard} from 'components/Dashboard';
import {Splash} from 'components/Widgets/Splash';
import {HeavyRotation} from 'components/Widgets/HeavyRotation';
import {Tracks} from 'components/Widgets/Tracks'; 
import {AddTracks} from 'components/Widgets/AddTracks';
import {Artist} from 'components/Widgets/Artist';
import {Feedback} from 'components/Widgets/Feedback';
import {DJUsers} from 'components/Widgets/DJUsers';
import {DJUserDetails} from 'components/Widgets/DJUserDetails';
import {CuratedSets} from 'components/Widgets/CuratedSets';
import {RunScripts} from 'components/Widgets/RunScripts';
import {DeclinedTransactions} from 'components/Widgets/DeclinedTransactions';
import {InvalidUsers} from 'components/Widgets/InvalidUsers';
import {PaypalTransaction} from 'components/Widgets/PaypalTransaction';
import {PaypalHistory} from 'components/Widgets/PaypalHistory';
import {TrackProcess} from 'components/Widgets/Tracks';
import {SubscriptionDetails} from 'components/Widgets/SubscriptionDetails';
import {AddBanner} from 'components/Widgets/Banner'; 
import {Banners} from 'components/Widgets/Banner'; 
export default function BaseUserRoutes() {
    return [
            <Route exact path="/" component={Dashboard}/>,
            <Route path="/splash" component={Splash}/>,
            <Route path="/heavyRotation" component={HeavyRotation}/>,
            <Route path="/tracks" component={Tracks}/>,
            <Route path="/addTracks" component={AddTracks}/>,
            <Route path="/artist/:id" component={Artist}/>,
            <Route path="/feedback" component={Feedback}/>,
            <Route path="/djusers" component={DJUsers}/>,
            <Route path="/djuserdetails/:id" component={DJUserDetails}/>,
            <Route path="/runScripts" component={RunScripts}/>,
            <Route path="/curatedSets" component={CuratedSets}/>,
            <Route path="/declinedTransactions" component={DeclinedTransactions}/>,
            <Route path="/invalidusers" component={InvalidUsers}/>,
            <Route path="/paypaltransaction" component={PaypalTransaction}/>,
            <Route path="/paypalhistory" component={PaypalHistory}/>,
            <Route path="/processemotions" component={TrackProcess}/>,
            <Route path="/subscdetails/:id" component={SubscriptionDetails}/>,
            <Route path="/banner/add" component={AddBanner}/>,
            <Route path="/banner/all" component={Banners}/>,
            <Redirect to='/'/>
    ];
}

BaseUserRoutes.propTypes = {};
