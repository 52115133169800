import dayjs from "dayjs";

export function getUpdatedVideoState({ state, result }) {
    let newState = { ...state };
    if (state.artistResult && state.artistResult.coverVideo) {
        newState.artistResult.coverVideo = result.coverVideo;
    }
    return newState;
}

export function getUpdatedTrackState({ state, result }) {
    let newState = { ...state };
    if (state.artistResult && state.artistResult.track) {
        newState.artistResult.track.forEach(track => {
            if (result.track.id === track.id)
                track.status = result.track.status;
        });
    }
    return newState;
}

export function getUpdatedArtistState({ state, result, key }) {
    let newState = { ...state };
    if (state.artistResult && typeof state.artistResult === "object") {
        newState.artistResult[key] = result;
    }
    return newState;
}

export function normailezeHeavyRotationResult(result) {
    const { list, count } = result;

    const normalizedList = list.map(elem => {
        const normalizedElem = {};
        ["id", "name", "active", "period"].map(
            key => (normalizedElem[key] = elem[key])
        );

        const { heavyRotationStartDate, heavyRotationEndDate } = elem;

        if (heavyRotationEndDate && heavyRotationStartDate) {
            normalizedElem.datesForHeavyRotation = `
            ${dayjs(heavyRotationStartDate).format("DD/MM/YY")} -
            ${dayjs(heavyRotationEndDate).format("DD/MM/YY")}`;
        } else {
            normalizedElem.datesForHeavyRotation = "-";
        }

        return normalizedElem;
    });

    return { list: normalizedList, count };
}

export function getPathForHeavyRotation(data) {
    const { offset, limit, name, id, active } = data;

    let path = `/artists?limit=${Number.parseInt(
        limit
    )}&offset=${Number.parseInt(offset)}`;
    if (id) {
        path += `&id=${id}`;
    }
    if (name) {
        path += `&name=${name}`;
    }
    if (active !== "Not set" && active !== null) {
        path += `&active=${active}`;
        path += `&visible=${active}`;
    }
    return path;
}

export function getPathForDJUserList(data) {
    const {
        offset,
        limit,
        id,
        email,
        username,
        djName,
        loginid,
        migId,
        authEmail,
        paypalEmail,
        billingName,
        billingAddress,
        authSubId,
        paypalSubId
    } = data;

    let path = `/admins/getDJList/?limit=${Number.parseInt(
        limit
    )}&offset=${Number.parseInt(offset)}`;
    if (id) {
        path += `&id=${id}`;
    }
    if (username) {
        path += `&username=${username}`;
    }
    if (email) {
        path += `&email=${email}`;
    }
    if (loginid) {
        path += `&loginid=${loginid}`;
    }
    if (djName) {
        path += `&djName=${djName}`;
    }
    if (migId) {
        path += `&migId=${migId}`;
    }
    if (authEmail) {
        path += `&authEmail=${authEmail}`;
    }
    if (paypalEmail) {
        path += `&paypalEmail=${paypalEmail}`;
    }
    if (billingName) {
        path += `&billingName=${billingName}`;
    }
    if (billingAddress) {
        path += `&billingAddress=${billingAddress}`;
    }
    if (authSubId) {
        path += `&authSubId=${authSubId}`;
    }
    if (paypalSubId) {
        path += `&paypalSubId=${paypalSubId}`;
    }

    return path;
}

export function getInvalidUsersListPath(data) {
    const { offset, limit } = data;

    let path = `/admins/invalidusers/?limit=${Number.parseInt(
        limit
    )}&offset=${Number.parseInt(offset)}`;

    return path;
}

export function getPathForDJUserDetails(id) {
    let path = `/admins/getDJList/?id=${id}`;
    return path;
}

export function normalizeDJUserDetailsResult(result) {
    Object.keys(result.list[0]).map(e => {
        if (result.list[0][e] === null) {
            result.list[0][e] = "";
        }
        return result.list[0][e];
    });
    return {
        ...result.list[0],
        active: result.list[0]["allow_user"],
        password: ""
    };
}

export function normalizeDJUserListResult(result) {
    return { list: result.list.map(normalizeUser), count: result.count };
}

// export function normalizeInvalidUsersListResult(result) {
//     return { list: result.list.map(normalizeUser), count: result.count };
// }

export function getPathForDJUserTransHistory(data) {
    const {
        offset,
        limit,
        id,
        transactionId,
        type,
        startDate,
        endDate,
        transactionStatus,
        invoiceNumber,
        lastFourCardDigit,
        amount,
        subscriptionId,
        userId
    } = data;

    let path = `/admins/getDJTransHistory/?limit=${Number.parseInt(
        limit
    )}&offset=${Number.parseInt(offset)}`;
    if (id) {
        path += `&id=${id}`;
    }
    if (userId) {
        path += `&userId=${userId}`;
    }
    if (startDate) {
        path += `&startDate=${startDate}`;
    }
    if (endDate) {
        path += `&endDate=${endDate}`;
    }

    return path;
}

export function normalizeDJUserTransHistoryResult(result) {
    const filteredResult =
        result.list &&
        result.list.map(elem => {
            return {
                ...elem,
                submitDate: dayjs(elem.submitDate).format(
                    "YYYY-MM-DD hh:mm:ss"
                )
            };
        });
    return { list: filteredResult, count: result.count };
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function normalizeUser(user) {
    const {
        email,
        fullName,
        login,
        baseUserId,
        djName,
        allow_user,
        phone,
        date_added,
        payment_type
    } = user;

    return {
        id: baseUserId,
        email,
        fullName,
        login,
        djName,
        allow_user,
        phone,
        date_added,
        payment_type
    };
}
