import React, { useContext, useState, useEffect } from 'react';

import { TracksContext } from 'context/TracksContext';
import {Button} from "components/common/ControlElems";
import axios from 'axios';

const KEEP_PERCENTAGE = 0.15; // keep only 15% of audio file
let localProcess = sessionStorage.getItem('process');
let essentia = null;
let featureExtractionWorker = null;
let inferenceWorkers = {};
const modelNames = ['danceability', 'mood_sad', 'mood_happy', 'mood_aggressive', 'mood_relaxed'];
let inferenceResultPromises = [];

export default function TrackProcess() {
    const queryParams = new URLSearchParams(window.location.search);
    const year = queryParams.get('year');
    const id = queryParams.get('id');
    
    console.log(year,"year---------")
    console.log(id,"id---------")
    let {
        getTracksWithoutEmotions, 
        tracksResultNew, 
        updateTracksEmotions, 
        // updateTracksEmotionsRes, 
        // getTracksCountWithOrWithoutEmotions, 
        // tracksCountWithOrWithoutEmotions
     } = useContext(TracksContext);
    const [process,setProcess] = useState((localProcess !==  null) ? JSON.parse(localProcess) : {status: -1, expire: Date.now()});
    const [processResult, setProcessResult] = useState([]);
    const [pendingTracks, setPendingTracks] = useState(0);
    const [doneTracks, setDoneTracks] = useState(-1);
    const [isNewTrackFetching, setIsNewTrackFetching] = useState(false);
    const [isProcessFinished, setIsProcessFinished] = useState(false);
    const [isWorkerReady, setIsWorkerReady] = useState(false);
    const initTrackProcess = (process.status === 1) ? ['Track processing started'] : [];
    const [trackProcess, setTrackProcess] = useState(initTrackProcess);

     useEffect(()=>{
        const fetchTracks = async () => {
            // Initiate track API 
            if (typeof tracksResultNew === 'undefined') {
                if(isWorkerReady === false) 
                {
                    createInferenceWorkers();
                    setIsWorkerReady(true);
                }
                if(sessionStorage.getItem('tracksResultNew') === null && isNewTrackFetching === false){
                    setIsNewTrackFetching(true);
                    await getTracksWithoutEmotions({sortFields: { year: year, id: id}});
                    // getTracksCountWithOrWithoutEmotions();
                }
            }else if (typeof tracksResultNew !== 'undefined' && tracksResultNew && tracksResultNew.list && tracksResultNew.list.length > 0) {
                let temTracksResultNew ={
                    ...tracksResultNew,
                    list: tracksResultNew.list.map(item => ({ ...item, isProcessed: false })),
                  };
                sessionStorage.setItem('tracksResultNew', JSON.stringify(temTracksResultNew));
                console.log("tracksResultNew---------setted");
            }else if (typeof tracksResultNew !== 'undefined' && tracksResultNew && tracksResultNew.list && tracksResultNew.list.length === 0) {
                setTrackProcess(["No pending track to process"]);
                console.log("There is no one track pending to process");
            }
        }
        sessionStorage.setItem('processResultPendingCount', 0);
        fetchTracks();
        
        let tracksResultNewSS = (sessionStorage.getItem('tracksResultNew') !== null) ? JSON.parse(sessionStorage.getItem('tracksResultNew')) : null;
        
        if(isWorkerReady === true && tracksResultNewSS !== null && new Date(process.expire) > new Date(Date.now()) && process.status === 1) {
            let tracksResultNewSSPendingTracks = {
                list: tracksResultNewSS.list.filter(item => item.isProcessed === false),
                awsS3BaseUrl: tracksResultNewSS.awsS3BaseUrl
            }
            console.log(tracksResultNewSSPendingTracks.list,"tracksResultNewSSPendingTracks");
            (tracksResultNewSSPendingTracks.list.length > 0) && (startProcessing(tracksResultNewSSPendingTracks))
        }else if(isWorkerReady === true && typeof tracksResultNew !== 'undefined' && process.status === -1){
            (tracksResultNew.list.length !== 0) && (setProcess({status:0,expire:Date.now()}));
            (tracksResultNew.list.length === 0) && setTrackProcess(["No pending track to process"])
            console.log(trackProcess,"trackProcess");
        }else{
            console.log(isWorkerReady === true ,"tracksResultNewSSPendingTracks---------else1");
            console.log(tracksResultNewSS !== null,tracksResultNewSS != null,"tracksResultNewSSPendingTracks---------else2");
            console.log(new Date(process.expire) > new Date(Date.now()),"tracksResultNewSSPendingTracks---------else3");
            console.log(process.status === 1,"tracksResultNewSSPendingTracks---------else4");
            
            console.log(typeof tracksResultNewSS, tracksResultNewSS, sessionStorage.getItem('tracksResultNew'), "tracksResultNewSSPendingTracks---------else2222");
        }
     },[isWorkerReady,tracksResultNew]);

    useEffect(() => {
            if (isProcessFinished === true) {
                setTrackProcess(prevState => ['Process Finished', ...prevState]);
            }
    }, [isProcessFinished]);

    useEffect(()=>{
        if(sessionStorage.getItem('process') === null && sessionStorage.getItem('tracksResultNew') !== null){
            let currentTime = Date.now();
            let tenMinutesLater = currentTime + 15 * 60 * 1000; // 10 minutes in milliseconds
            sessionStorage.setItem('process', JSON.stringify({status: 0, expire: new Date(tenMinutesLater)}));
            setProcess({status: 0, expire: new Date(tenMinutesLater)});
        }
    },[])

//     useEffect(() => {
//         if (typeof updateTracksEmotionsRes !== 'undefined') {
//             // getTracksCountWithOrWithoutEmotions();
//         }
// }, [updateTracksEmotionsRes]);

// useEffect(() => {
//     if (typeof tracksCountWithOrWithoutEmotions !== 'undefined') {
//         setDoneTracks(tracksCountWithOrWithoutEmotions.totalCountWithEmotions[0].count);
//         setPendingTracks(tracksCountWithOrWithoutEmotions.totalCountWithoutEmotions[0].count);
//     }
// }, [tracksCountWithOrWithoutEmotions]);

    const collectPredictions = async (trackVersionId) => {
        if (inferenceResultPromises.length === modelNames.length) {
            Promise.all(inferenceResultPromises).then(async (predictions) => {
                const allPredictions = {};
                Object.assign(allPredictions, ...predictions);
                inferenceResultPromises = [] // clear array
                let tracksResultNewSS = (sessionStorage.getItem('tracksResultNew') !== null) ? JSON.parse(sessionStorage.getItem('tracksResultNew')) : null;
                if(tracksResultNewSS.list && tracksResultNewSS !== null){
                    tracksResultNewSS.list = tracksResultNewSS.list.map((elm,i) => {
                        if(elm.id === trackVersionId){
                            elm.isProcessed = true;
                            elm["trackVersionId"] = trackVersionId;
                        }
                        return elm;
                    });

                    let tracksResultNewSSPendingTracks = {
                        list: tracksResultNewSS.list.filter(item => item.isProcessed === false)
                    }

                    sessionStorage.setItem('tracksResultNew',JSON.stringify(tracksResultNewSS)); 

                    if(tracksResultNewSSPendingTracks.list.length === 0){
                        updateTracksEmotions(tracksResultNewSS.list).then(async ()=>{
                            
                            sessionStorage.removeItem('tracksResultNew');
                            window.location.reload();
                        })
                    }else{
                        let currentTime = Date.now();
                        let tenMinutesLater = currentTime + 10 * 60 * 1000; // 10 minutes in milliseconds
                        sessionStorage.setItem('process',JSON.stringify({status:1,expire:new Date(tenMinutesLater)}));
                        window.location.reload();
                    }
                }else{
                    console.log("tracksResultNew is not a sesstion storage");
                }
            })
        }
    }

    const createInferenceWorkers = () => {
        let result = [];
        modelNames.forEach((n) => {
            inferenceWorkers[n] = new Worker('mood-script/src/inference.js');
            inferenceWorkers[n].postMessage({
                name: n
            });
            inferenceWorkers[n].onmessage = function listenToWorker(msg) {
                // listen out for model output
                if (msg.data.predictions) {
                    const trackVersionId = msg.data.trackVersionId;
                    const preds = msg.data.predictions;
                    // emmit event to PredictionCollector object
                    inferenceResultPromises.push(new Promise((res) => {
                        res({ [n]: preds });
                    }));
                    collectPredictions(trackVersionId);
                    result[n] = preds;
                    let newProcessResult = processResult;
                    //newProcessResult[trackVersionId][n];
                    newProcessResult[trackVersionId][n] = preds;
                    setProcessResult(newProcessResult);
                    let mess = `${n} predictions--: `+preds+ `for track version id - `+trackVersionId;
                    setTrackProcess(prevState => [mess, ...prevState]);
                    let pendingTask = sessionStorage.getItem('processResultPendingCount'); 
                    pendingTask = pendingTask-1;
                    sessionStorage.setItem('processResultPendingCount', pendingTask);
                    (pendingTask === 0) && (setIsProcessFinished(true) && inferenceWorkers.terminate() && (inferenceWorkers = {}))
                    let tracksResultNewSS = (sessionStorage.getItem('tracksResultNew') !== null) ? JSON.parse(sessionStorage.getItem('tracksResultNew')) : null;
                    if(tracksResultNewSS !== null && tracksResultNewSS.list){
                        tracksResultNewSS.list = tracksResultNewSS.list.map((elm,i) => {
                            if(elm.id === trackVersionId){
                                elm[n] = preds;
                            }
                            return elm;
                        });
                        sessionStorage.setItem('tracksResultNew',JSON.stringify(tracksResultNewSS));                        
                    }else{
                        console.log("tracksResultNew is not an session storage");
                    }
                }
            };
        });
        return result;
    }

    const createFeatureExtractionWorker = async function (trackVersionId) {
        let mess = `Feature extracting started for track version id - `+trackVersionId
        setTrackProcess(prevState => [mess, ...prevState]);
        featureExtractionWorker = new Worker('mood-script/src/featureExtraction.js');
        featureExtractionWorker.onmessage = function listenToFeatureExtractionWorker(msg) {
            // feed to models
            if (msg.data.features) {
                let interval = 0;
                modelNames.forEach( async (n) => {        
                    let mess = `Send feature extracting request of ${n} for track version id - `+trackVersionId
                    setTrackProcess(prevState => [mess, ...prevState]);
                    // send features off to each of the models
                    inferenceWorkers[n].postMessage({
                        features: msg.data.features,
                        trackVersionId: trackVersionId,
                        interval: interval,
                    });
                    interval = interval+1000;
                });
                msg.data.features = null;
            }
            // free worker resource until next audio is uploaded
            featureExtractionWorker.terminate();
            featureExtractionWorker = null;
        }
        mess = `Feature extracting done for track version id - `+trackVersionId
        setTrackProcess(prevState => [mess, ...prevState]);
    }

    const setResultState = async function (trackId,models) {
        let trackModelsArr = [];
        trackModelsArr['trackVersionId'] = trackId;
        let oldState = processResult;
        oldState[trackId] = [];
        models.map((item) => {
            trackModelsArr[item] = [];
            return true;
        })
        oldState[trackId] = trackModelsArr;
        setProcessResult(oldState);
        return;
    }

    const decodeFile = async function(arrayBuffer, trackVersionId) {
        try {
            const AudioContext = window.AudioContext || window.AudioContext;
            const audioCtx = new AudioContext();
            await audioCtx.resume();
            let mess = `File decodeAudioData started for track version id - ` + trackVersionId;
            setTrackProcess(prevState => [mess, ...prevState]);
        
            const audioBuffer = await audioCtx.decodeAudioData(arrayBuffer);
            mess = `File decodeAudioData2 started for track version id - ` + trackVersionId;
            setTrackProcess(prevState => [mess, ...prevState]);
        
            const prepocessedAudio = preprocess(audioBuffer);
            await audioCtx.suspend();
        
            if (essentia) {
                // Additional code related to essentia
            }
      
            // reduce amount of audio to analyse
            let audioData = shortenAudio(prepocessedAudio, KEEP_PERCENTAGE, true, trackVersionId);
        
            // send for feature extraction
            createFeatureExtractionWorker(trackVersionId);
            featureExtractionWorker.postMessage({
                audio: audioData.buffer
            }, [audioData.buffer]);
        
            audioData = null;
        } catch (error) {
            console.error('Failed to decode and process audio:', error);
            setTimeout( async () => {
                let currentTime = Date.now();
                let tenMinutesLater = currentTime + 15 * 60 * 1000; // 10 minutes in milliseconds
                sessionStorage.setItem('process',JSON.stringify({status:1,expire:new Date(tenMinutesLater)}))
                window.location.reload();
            });
        }
      };

    const stopProcecing = async () => {
        sessionStorage.setItem('process',JSON.stringify({status:-1,expire:Date.now()}));
        sessionStorage.removeItem('tracksResultNew');
        window.location.reload();
    }

    const startProcessing = async function (PendingTracks = []) {
        console.log(PendingTracks,PendingTracks.length,typeof PendingTracks.length, typeof PendingTracks, "PendingTracks");
        if(PendingTracks.length === 0 || typeof PendingTracks.length == 'undefined') {
            console.log(PendingTracks,"PendingTracks222222");
            let tracksResultNewSS = (sessionStorage.getItem('tracksResultNew') !== null) ? JSON.parse(sessionStorage.getItem('tracksResultNew')) : null;
            PendingTracks = {
            list: tracksResultNewSS.list.filter(item => item.isProcessed === false),
            awsS3BaseUrl: tracksResultNewSS.awsS3BaseUrl
            }
        }
        console.log(PendingTracks,"PendingTracks3333");
        
        let currentTime = Date.now();
        let tenMinutesLater = currentTime + 15 * 60 * 1000; // 10 minutes in milliseconds
        sessionStorage.setItem('process',JSON.stringify({status:1,expire:new Date(tenMinutesLater)}))
        setProcess({status:1,expire:new Date(tenMinutesLater)});
        setTimeout( async () => {
            const processResultPendingCount = parseInt(modelNames.length)*parseInt(PendingTracks.list.length);
            sessionStorage.setItem('processResultPendingCount', processResultPendingCount);
            console.log(
                PendingTracks.list &&
                PendingTracks.list.length > 0 &&
                typeof PendingTracks.list[0] === 'object', "-------------")
            if (
                PendingTracks.list &&
                PendingTracks.list.length > 0 &&
                typeof PendingTracks.list[0] === 'object'
              ) {
                  let item = PendingTracks.list[0];
                  let awsS3BaseUrl = PendingTracks.awsS3BaseUrl;
                  let trackVersionId = item.id;
                  //let trackVersionfileName = item.id+item.fileName;
                  let trackVersionS3Path = encodeURI(awsS3BaseUrl + '/' + item.fileName);
                  await setResultState(trackVersionId, modelNames);
                  await processEmotions(trackVersionS3Path, trackVersionId);
                
              }
            (PendingTracks.list && PendingTracks.list.length === 0) 
            && setTrackProcess(["No pending track to process"])
            && setProcess({status: -1,expire: Date.now()})
            && (sessionStorage.setItem('process',JSON.stringify({status: -1,expire: Date.now()})))

        }, 5000);
    }

    const processEmotions = async function (url, trackVersionId) {
        console.log(url, "url---------" + trackVersionId);
        let mess = `Process started for track version id - ` + trackVersionId;
        setTrackProcess(prevState => [mess, ...prevState]);
    
        try {
            const response = await axios({
                method: 'GET',
                // url: 'mood-script/1(UK%20Hip%20Hop)%20Mist,%20Steel%20Bangaz,%20Stefflon%20Don%20&%20Sidhu%20Moose%20Wala%20-%2047%20(Dirty).mp3',
                url: url,
                responseType: 'blob',
            });
            console.log(response,"response----------");
            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            url = URL.createObjectURL(blob);
            const ab = await blob.arrayBuffer();
            decodeFile(ab, trackVersionId);
            console.log(blob, url, ab);
        } catch (error) {
            setUprocessableVersion(trackVersionId);
            console.error('---Error fetching MP3 file:', error);
        }
    }
    
    const setUprocessableVersion = (trackVersionId)=>{
        let sesTracksResultNew = JSON.parse(sessionStorage.getItem('tracksResultNew'));
        let newResults = sesTracksResultNew.list.filter(elm => elm.id !== trackVersionId);
        sessionStorage.setItem('tracksResultNew',JSON.stringify({list: newResults, awsS3BaseUrl: sesTracksResultNew.awsS3BaseUrl}));
        let TracksUnprocesable = (localStorage.getItem('TracksUnprocesable') != null) ? JSON.parse(localStorage.getItem('TracksUnprocesable')) : [];
        console.log(TracksUnprocesable,"TracksUnprocesable1111111")
        TracksUnprocesable.push(trackVersionId);
        console.log(TracksUnprocesable,"TracksUnprocesable222222")
        localStorage.setItem('TracksUnprocesable',JSON.stringify(TracksUnprocesable))
        window.location.reload();
    }

    const preprocess = (audioBuffer)=> {
        if (audioBuffer instanceof AudioBuffer) {
            const mono = monomix(audioBuffer);
            // downmix to mono, and downsample to 16kHz sr for essentia tensorflow models
            return downsampleArray(mono, audioBuffer.sampleRate, 16000);
        } else {
            throw new TypeError("Input to audio preprocessing is not of type AudioBuffer");
        }
    }
    
    const monomix = (buffer)=> {
        // downmix to mono
        let monoAudio;
        if (buffer.numberOfChannels > 1) {
            console.log('mixing down to mono...');
            const leftCh = buffer.getChannelData(0);
            const rightCh = buffer.getChannelData(1);
            monoAudio = leftCh.map( (sample, i) => 0.5 * (sample + rightCh[i]) );
        } else {
            monoAudio = buffer.getChannelData(0);
        }
    
        return monoAudio;
    }
    
    const downsampleArray = (audioIn, sampleRateIn, sampleRateOut)=> {
        if (sampleRateOut === sampleRateIn) {
          return audioIn;
        }
        let sampleRateRatio = sampleRateIn / sampleRateOut;
        let newLength = Math.round(audioIn.length / sampleRateRatio);
        let result = new Float32Array(newLength);
        let offsetResult = 0;
        let offsetAudioIn = 0;
    
        console.log(`Downsampling to ${sampleRateOut} kHz...`);
        while (offsetResult < result.length) {
            let nextOffsetAudioIn = Math.round((offsetResult + 1) * sampleRateRatio);
            let accum = 0,
                count = 0;
            for (let i = offsetAudioIn; i < nextOffsetAudioIn && i < audioIn.length; i++) {
                accum += audioIn[i];
                count++;
            }
            result[offsetResult] = accum / count;
            offsetResult++;
            offsetAudioIn = nextOffsetAudioIn;
        }
    
        return result;
    }
    
    
    const shortenAudio = (audioIn, keepRatio=0.5, trim=false, trackVersionId)=> {
        /* 
            keepRatio applied after discarding start and end (if trim == true)
        */
        let mess = `shortenAudio started for track version id - `+trackVersionId
        setTrackProcess(prevState => [mess, ...prevState]);
        if (keepRatio < 0.15) {
            keepRatio = 0.15 // must keep at least 15% of the file
        } else if (keepRatio > 0.66) {
            keepRatio = 0.66 // will keep at most 2/3 of the file
        }
    
        if (trim) {
            const discardSamples = Math.floor(0.1 * audioIn.length); // discard 10% on beginning and end
            audioIn = audioIn.subarray(discardSamples, audioIn.length - discardSamples); // create new view of buffer without beginning and end
        }
    
        const ratioSampleLength = Math.ceil(audioIn.length * keepRatio);
        const patchSampleLength = 187 * 256; // cut into patchSize chunks so there's no weird jumps in audio
        const numPatchesToKeep = Math.ceil(ratioSampleLength / patchSampleLength);
    
        // space patchesToKeep evenly
        const skipSize = Math.floor( (audioIn.length - ratioSampleLength) / (numPatchesToKeep - 1) );
    
        let audioOut = [];
        let startIndex = 0;
        for (let i = 0; i < numPatchesToKeep; i++) {
            let endIndex = startIndex + patchSampleLength;
            let chunk = audioIn.slice(startIndex, endIndex);
            audioOut.push(...chunk);
            startIndex = endIndex + skipSize; // discard even space
        }
        mess = `shortenAudio done for track version id - `+trackVersionId
        setTrackProcess(prevState => [mess, ...prevState]);
        return Float32Array.from(audioOut);
    }

    return (
        <>
        <div className="col-xl-12">
            <div className="m-portlet">
                <div className="m-portlet__head w-100">
                    <div className="m-portlet__head-caption w-100">
                        <div className="m-portlet__head-title d-flex flex-row justify-content-between w-100">
                            <h3 className="m-portlet__head-text">Track emotions extract process</h3>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-3 pb-xl-2">
                    <div className="w-25 m-2">
                        <div className="col-md-12">Pending versions : {(pendingTracks !== 0) ? pendingTracks : 'Fetching...'}</div>
                    </div>
                    <div className="w-25 m-2">
                        <div className="col-md-12">Process completed : {(doneTracks !== -1) ? doneTracks : 'Fetching...'}</div>
                    </div>
                    <div className="w-25 m-2">
                        <div className="col-md-12">Pending tasks : {sessionStorage.getItem('processResultPendingCount')}</div>
                    </div>
                    <div className="w-25 mr-2 ml-2 mb-3 mt-0">
                        <div className="col-md-12">
                            {(process.status === 0) && (
                               <Button
                               label="Start Process"
                               onClick={() => startProcessing({})}
                              />
                            )}
                            {(process.status === 1) && (
                                <Button
                                    label="Stop Process"
                                    onClick={stopProcecing}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className='processListWrap pl-xl-5 pt-xl-4 pb-xl-4'>
                    {(trackProcess.map((item,index) => (
                        <p key={index}>{item}</p>
                    ))
                    )}
                </div>   
            </div>
        </div> 
        </>
    )
}