import React, {useContext, useCallback} from 'react';
import {Select} from 'components/common/Form';
import {TracksContext} from 'context/TracksContext';
import classnames from "classnames";
import {CATEGORY_ID, GENRE_ID} from "../../../helpers/constants";

export default function SelectGenreAndCategory({onChange, isWrong, genreId, categoryId}) {
    const {genresResult: {genres, categories}} = useContext(TracksContext);

    const changeFields = useCallback((field, event) => {
        const value = event.target.value === ''
            ? undefined
            : parseInt(event.target.value);
        const newFields = {[field]: value};
        onChange(newFields);
    }, [onChange]);

    const curatedCategories = categories.filter(category => category.kind === 'curated');

    return (<>
        <Select name="Category"
                label="Category:"
                className={classnames('w-100', {wrongField: isWrong && !categoryId})}
                value={categoryId}
                options={[{value: '', text: 'Any'}, ...curatedCategories.map(category => {
                    return {value: category.id, text: category.name}
                })]}
                onChange={(event) => changeFields(CATEGORY_ID, event)}
        />
        <Select name="Genre"
                label="Genre:"
                className={classnames('w-100', {wrongField: isWrong && !genreId})}
                value={genreId}
                options={[{value: '', text: 'All'}, ...genres.map((genre, index) => {
                    if (!genre) return null
                    return {value: index + 1, text: genre}
                }).filter(elem => elem)]}
                onChange={(event) => changeFields(GENRE_ID, event)}
        />
    </>)
}
