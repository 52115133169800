import React, {useContext, useState, useEffect, useRef} from 'react';
import {default as TableWithPaginator} from './TableWithPaginator';
import {TracksContext} from 'context/TracksContext';
import {TextField, Select} from 'components/common/Form';
const DEFAULT_FILTERS = {searchQuery: '', genre: '', sortField: 'createdAt', type: 'audio', offset: 0, limit: 5};

export default function TrackList({setIsBannerTracksUpdating = false}) {
    const { trackFetching, tracksResult, getTracks, deleteTrackResult, genresResult:{genres}} = useContext(TracksContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const {list} = tracksResult;
    const listWithVersions = list.map(elem => {
        const {artiste, bpm, genre, songName, id} = elem;
        elem = {artiste, bpm, genre, songName, id, subTable: null};
        return elem;
    });

    const timer = useRef(null);

    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => getTracks(fields), 1000);
        return () => clearTimeout(timer.current);
    }, [fields]);

    useEffect(() => {
        if (deleteTrackResult) {
            getTracks(fields);
        }
    }, [deleteTrackResult]);

    return (
        <div className="p-4 m-3 track-list">            
            <h5 className="text-center text-danger mt-3">Please select the tracks for banner. *</h5>
            <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                <TextField
                    value={fields.searchQuery}
                    name="Search"
                    label="Search"
                    type="text"
                    placeholder="Search"
                    className="w-25 mt-3 mr-5"
                    onChange={(event) => handleSetFields('searchQuery', fields, event, setFields)}/>
                <Select name="Sort Field"
                        label="Sort Field"
                        className="w-25 mr-5"
                        value={fields.sortField}
                        options={[{value: 'createdAt', text: 'Latest Releases'}, {value: 'downloads', text: 'Top TrackList'}]}
                        onChange={(event) => handleSetFields('sortField', fields, event, setFields)}/>
                <Select name="Genre"
                        label="Genre"
                        className="w-25 mr-5"
                        value={fields.genre}
                        options={[{value: '', text: 'All'}, ...genres.map(genre => {return {value: genre, text: genre}})]}
                        onChange={(event) => handleSetFields('genre', fields, event, setFields)}/>
            </div>
            <TableWithPaginator
                tableWidth={['100px','400px','300px','100px', '100px', '100px']}
                tableElems={['id', 'songName', 'artiste', 'key', 'bpm', 'genre']}
                //tableLabel="TrackList222"
                headers={['id', 'Song Name', 'Artist', 'Key', 'BPM', 'Genre']}
                // headButton={headTableButton}
                data={listWithVersions}
                offset={fields.offset}
                limit={fields.limit}
                count={tracksResult.count}
                options={[{value: 5, text: '5'}, {value: 10, text: '10'}, {value: 20, text: '20'}, {value: 50, text: '50'}]}
                offsetOnChange={(value) => handleSetFields('offset', fields, {target: {value}}, setFields)}
                isDataFetching={trackFetching}
                limitOnChange={(event) => handleSetFields('limit', fields, event, setFields)}
                setIsBannerTracksUpdating={setIsBannerTracksUpdating}
                />
        </div>
    )
}

function handleSetFields(key, fields, event, handler) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = value;
    if (key !== 'offset') newFields.offset = 0;
    handler(newFields);
}