import React, {useContext, useState} from 'react';

import {TableWithPaginator, WidgetHead} from 'components/common/DisplayElems';
import {TransactionsContext} from 'context/TransactionsContext';
import withRouter from 'components/helpers/withRouter';

import './DeclinedTransactions.css'
import {Datepicker} from "../../common/Form";
import {Button} from "../../common/ControlElems";

function DeclinedTransactionsComponent({history: routeHistory}) {
    const [date, setDate] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const {
        getCanceledSubscriptionsList,
        subscriptionsList,
        subscriptionsCount,
        cancelSubscriptions
    } = useContext(TransactionsContext);

    const handleDateChange = (newDate) => {
        const normalData = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0, 0));
        setDate(normalData);
        getCanceledSubscriptionsList({timestamp: normalData.getTime()});
    };

    const refreshData = () => {
        getCanceledSubscriptionsList({timestamp: date.getTime()});
    };

    const handleCancelAll = () => {
        cancelSubscriptions(subscriptionsList.map(el => el.dbSubscriptionId), refreshData);
    };

    const handleCancel = (id) => {
        cancelSubscriptions([id], refreshData);
    };

    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                <WidgetHead label="Declined Transactions"/>
                <WidgetHead label="Filter"/>
                <div className="d-flex flex-row align-items-center justify-content-sm-start pl-xl-4 pt-xl-4 pr-xl-4">
                    <Datepicker
                        label="Active from"
                        value={date}
                        onChange={handleDateChange}
                    />
                    <Button
                        className="ml-auto"
                        label="cancel all"
                        disabled={!date}
                        onClick={handleCancelAll}/>
                </div>
                <TableWithPaginator
                    buttons={[
                        {
                            label: "Edit",
                            action: elem =>
                                routeHistory.push("/djuserdetails/" + elem.id),
                            className: "btn-primary w-60"
                        },
                        {
                            label: "Cancel",
                            action: elem => handleCancel(elem.dbSubscriptionId),
                            className: "btn-primary w-60"
                        }
                    ]}
                    tableElems={["id", "email", "djName", "fullName", "login", "address", "phone", "migration_id"]}
                    tableLabel="DJUsers"
                    headers={[
                        "Id",
                        "Email",
                        "DjName",
                        "FullName",
                        "Login",
                        "Address",
                        "Phone",
                        "Migration_id"
                    ]}
                    data={subscriptionsList}
                    offset={offset}
                    limit={limit}
                    count={subscriptionsCount}
                    options={[
                        {value: 5, text: "5"},
                        {value: 10, text: "10"},
                        {value: 20, text: "20"},
                        {value: 50, text: "50"}
                    ]}
                    offsetOnChange={value => setOffset(value)}
                    limitOnChange={event => setLimit(event.target.value)}
                />
            </div>
        </div>
    )
}

export default withRouter(DeclinedTransactionsComponent);
