import React, { useState, useEffect } from "react";

import {Table} from 'components/common/DisplayElems';

const versionsButtonsStyle = {
    maxWidth: '1.5rem',
    minWidth: '1rem',
    padding: '0',
    borderRadius: '1.5rem',
    maxHeight: '1.5rem'
};

export default function AddedTracks({curatedDetailedTracksResult, mainTableButtons = [], subTableButtons = []}) {
    const [showVersion, setShowVersion] = useState(null);
    let isTrackWithShownVersionsExist = false;
    const curatedTracksWithVersions = curatedDetailedTracksResult.map(elem => {
        elem.genreName = elem.genre.name;
        elem.isHidden = elem.isDeleted.toString();
        if (showVersion && elem.id === showVersion.id) {
            isTrackWithShownVersionsExist = true;
            elem.subTable = elem.trackVersions
                .map(version => {
                    version.isHidden = version.isDeleted.toString();
                    return version;
                })
                .filter(filterTrackVersions.bind(this, showVersion));
        } else {
            elem.subTable = null;
        }
        return elem;
    });

    useEffect(() => {
        if (!isTrackWithShownVersionsExist) { setShowVersion(null); }
    }, [isTrackWithShownVersionsExist]);

    return (
        <Table
            tableWidth={['100px', '400px', '300px', '100px', '100px', '100px', '100px', '500px']}
            tableElems={['id', 'songName', 'artiste', 'key', 'bpm', 'genreName', 'isHidden']}
            buttons={[...['A', 'O', 'I', 'R'].map(label => {
                return {
                    label,
                    action: (elem) => onShowVersions(showVersion, {id: elem.id, type: label}, setShowVersion),
                    className: 'btn-primary',
                    style: versionsButtonsStyle
                };
            }), ...mainTableButtons]}
            tableLabel="Added tracks"
            headers={['id', 'Song Name', 'Artist', 'Key', 'BPM', 'Genre', 'Hidden', 'Actions']}
            data={curatedTracksWithVersions}
            subTable={{
                tableElems: ['id', 'fileName', 'versionType', 'key', 'bpm', 'genre', 'isHidden'],
                tableWidth: ['100px', '400px', '300px', '100px', '100px', '100px', '100px', '500px'],
                background: "lightgrey",
                buttons: subTableButtons
            }}
        />
    );
}

function onShowVersions(currentShowVersion, newState, setShowVersion) {
    if (currentShowVersion && currentShowVersion.id === newState.id && currentShowVersion.type === newState.type) {
        setShowVersion(null);
    } else {
        setShowVersion(newState);
    }
}

function filterTrackVersions({type}, elem) {
    const O_FILTER = [
        "Dirty",
        "Clean",
        "Acapella",
        "Acapella Clean",
        "Acapella Dirty",
        "Instrumental",
        "Radio",
        "Radio Edit"
    ];
    const I_FILTER = ["Intro Clean", "Intro Dirty"];
    const R_FILTER = [
        "Remix Clean",
        "Remix Dirty",
        "Remix Instrumental",
        "Remix Acapella",
        "Remix Super Clean"
    ];
    switch (type) {
        case "A":
            return true;
        case "O":
            return O_FILTER.includes(elem.versionType) && !elem.subversionType;
        case "I":
            return I_FILTER.includes(elem.versionType) || (elem.subversionType && elem.versionType !== elem.subversionType);
        case "R":
            return R_FILTER.includes(elem.name);
        default:
            break;
    }
}
