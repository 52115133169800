import React, { useState, useContext, useEffect, useRef } from "react";
import { ContentContext } from "../../../context/ContentContext";
import styles from "../SubscriptionDetails/SubscriptionDetails.module.scss";
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import qs from 'qs';
import moment from 'moment';
import {decode as base64_decode, encode as base64_encode} from 'base-64';

const pconfig = require('../../../paypal.config.json');

export default function SubscriptionDetails(props) {
    const { getTransHistory , subscriptionResult, subscriptionProcessing} = useContext(ContentContext);
    //const {list} = tracksResult;
    const [paypalToken, setPaypalToken] = useState(0);
    const [transactionsHistory, setTransactionsHistory] = useState(0);

    const subscriptionID = props.match.params.id;
    // const from = moment(props.match.params.from).utc().format();
    // const to = moment(props.match.params.to).utc().format();
    
    const timer = useRef(null);
    const accessKey = base64_encode(`${pconfig.paypal_user}:${pconfig.paypal_pass}`);
    

    const getPayPalToken = async (accessKey)=>{
        var data = qs.stringify({'grant_type': 'client_credentials'});
        const config  : AxiosRequestConfig =  {
        method: 'post',
        url: 'https://api.sandbox.paypal.com/v1/oauth2/token',
        headers: { 
            'Authorization': `Basic ${accessKey}`, 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
        };

        axios(config).then(async function (response) {
            const token =  await response.data.access_token;
            setPaypalToken(token);
            return token;
        }).catch(function (error) {
        });

    }


    const get_payments_by_subscription_id = async (subscptId,token) =>{
        await getPayPalToken(accessKey);

        const config  : AxiosRequestConfig =  {
            method: 'get',
            url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscptId}/transactions?start_time=2022-01-21T07:50:20.940Z&end_time=2022-04-21T07:50:20.940Z`,
            //url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${subscptId}/transactions?start_time=${from}&end_time=${to}`,
            headers: { 
              'Authorization': `Bearer ${paypalToken}`
            }
        };          
        axios(config).then(function (response) {
            setTransactionsHistory(response.data.transactions)
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    
    useEffect( () => {
         const run  = async () => {
            await  getPayPalToken(accessKey);
         }
        if(paypalToken === 0){
            run();
        }
        
     }, [paypalToken])
     
     useEffect( () => {
        const run  = async () => {
            await get_payments_by_subscription_id(subscriptionID, paypalToken);
        }
        run();
     }, [paypalToken]);
    

    return (
        <div>
            {paypalToken === 0 &&
                <em><b>Loading data....</b></em>
            }
            
            {paypalToken !== 0 &&  transactionsHistory !== 0 && transactionsHistory !== undefined ? (
                <table className="table m-table m-table--head-bg-success">
                    <thead>
                        <tr>
                            <th>Payer Name</th>
                            <th>Eamil</th>
                            <th>Transaction Id</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Created Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionsHistory.map((item, index) =>
                            <tr>
                                <th>{item.payer_name.given_name} {item.payer_name.given_surname}</th>
                                <th>{item.payer_email}</th>
                                <th>{item.id}</th>
                                <th>{item.amount_with_breakdown.net_amount.currency_code} {item.amount_with_breakdown.net_amount.value}</th>
                                <th>{item.type}</th>
                                <th>{item.status}</th>
                                <th>{item.time}</th>
                            </tr>
                        )}
                    </tbody>
                </table>
            ): paypalToken !== 0 && transactionsHistory === undefined &&  (
                <p><b>No Result Found!</b></p>
            )
            }
           
        </div>
    );
}

function handleSetFields(key, fields, event, handler) {
    // const newFields = { ...fields };
    // const value = event.target.value;
    // newFields[key] = parseInt(value);
    // if (key !== "offset") newFields.offset = 0;
    // handler(newFields);
}
