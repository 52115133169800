import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {Paginator} from 'components/common/ControlElems';
import {Table} from 'components/common/DisplayElems';

TableWithPaginator.propTypes = {
    headers: PropTypes.array.isRequired,
    tableElems: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    tableLabel: PropTypes.string,
    headButton: PropTypes.object,
    buttons: PropTypes.array,
    subTable: PropTypes.object
};

export default function TableWithPaginator(params) {
    const {isDataFetching, offset, limit, count, options,
        offsetOnChange, limitOnChange, ...tableProps} = params;
    const topRange = Number.parseInt(offset) + Number.parseInt(limit);
    return [
        <Table
            key="table"
            isDataFetching={isDataFetching}
            {...tableProps}/>,
        <div key="paginator" className="row pl-xl-4 pr-xl-4">
            <div key="info" className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="m_table_1_info" role="status" aria-live="polite">
                    Showing  {Number.parseInt(offset) + 1} to {topRange < count ? topRange : count} of {count} entries
                </div>
            </div>
            <div key="paginator" className="col-sm-12 col-md-7 d-flex flex-row align-items-center justify-content-end">
                <Paginator
                    count={count}
                    offset={Number.parseInt(offset)}
                    limit={Number.parseInt(limit)}
                    options={options}
                    offsetOnChange={offsetOnChange}
                    limitOnChange={limitOnChange}/>
            </div>
        </div>
    ]
}
