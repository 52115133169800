import React, {useContext, useState, useEffect, useRef} from 'react';
import {ContentContext} from "context/ContentContext";
import {TextField} from 'components/common/Form';


const LINKS = [
    {type: 'facebook', label: 'Facebook'},
    {type: 'snapchat', label: 'Snapchat'},
    {type: 'website', label: 'Website'},
    {type: 'twitter', label: 'Twitter'},
    {type: 'instagram', label: 'Instagram'},
    {type: 'linkedIn', label: 'Linked In'},
    {type: 'soundCloud', label: 'Sound Cloud'}
];

export default function SocialLinks() {
    const {artistResult, updateSocialLinks} = useContext(ContentContext);
    const [links, setLinks] = useState(getDefaultValue(artistResult, LINKS));
    const timer = useRef(null);

    useEffect(updateOnTimer.bind(this, links, timer, updateSocialLinks, artistResult),[links]);

    return (
        <div className="col-xl-6">
            {LINKS.map(link => {
                return (
                    <TextField
                        key={link.type}
                        label={link.label}
                        placeholder={link.label}
                        defaultValue={link.link}
                        onChange={event => handleSetFields({value: event.target.value, link, links, setLinks})}/>);
            })}
        </div>
    )
}

function updateOnTimer(links, timer, updateSocialLinks, artist) {
    if (timer.current === null) {
        timer.current = undefined;
        return;
    }

    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
        const result ={};
        links.map(elem => {
            result[elem.type] = elem.link;
        });
        updateSocialLinks({...result, artistId: artist.id});
    }, 2000);

    return () => clearTimeout(timer.current);
}

function handleSetFields({value, link, links, setLinks}) {
    let newLinks = links.map(elem => {
        const newLink = {...elem};
        if (newLink.type === link.type) {
            newLink.link = value;
        }
        return newLink;
    });

    setLinks(newLinks);
}

function getDefaultValue(artistResult, LINKS) {
    const {socialLink} = artistResult;
    const result = LINKS.map(link => {
        const currentValue = (socialLink && socialLink[link.type]) || "";
        link.link = currentValue;
        return link;
    });
    return result;
}
