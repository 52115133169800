import React, {useContext, useState, useEffect, useMemo} from 'react';
import {TableWithPaginator, WidgetHead} from 'components/common/DisplayElems';
import {CuratedSetContext} from 'context/CuratedSetContext';
import {TracksContext} from 'context/TracksContext';
import {TextField} from 'components/common/Form';
import {DeleteCuratedSetDialog} from "components/common/Dialogs";
import withRouter from 'components/helpers/withRouter';
import {AddCuratedSetDialog} from "../../common/Dialogs";
import TracksContainer from "./TracksContainer";
import SelectGenreAndCategory from "../../common/ControlElems/SelectGenreAndCategory";

const DEFAULT_FILTERS = {searchQuery: '', genreId: undefined, categoryId: undefined, offset: 0, limit: 5};

let timer;

function CuratedSetComponent() {
    const {
        curatedSetResult, getCuratedSets,
        deleteCuratedSet, createCuratedSet, editCuratedSet, setCuratedActivity
    } = useContext(CuratedSetContext);

    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const [deletedList, setDeletedList] = useState([]);
    const [changedActivityList, setChangedActivityList] = useState([]);
    const [[dialogType, currentCuratedSet], editElement] = useState([null, null]);

    const {genresResult: {genres, categories}} = useContext(TracksContext);
    const getCategoryName = (categoryId) => {
        const foundCategory = categories.filter(category => category.id === categoryId);
        return (foundCategory[0] && foundCategory[0].name) || 'No category';
    };
    const getGenreName = (genreId) => {
        return genres[genreId - 1] || 'No genre';
    };

    const curatedSetList = useMemo(() => {
        if (!curatedSetResult) {
            return [];
        }

        const normalizedCuratedSetResult = curatedSetResult.list.map(elem => {
            const changedActivity = changedActivityList.filter(set => set.id === elem.id);

            return {
                ...elem,
                categoryName: getCategoryName(elem.categoryId),
                genreName: getGenreName(elem.genreId),
                createdAt: new Date(elem.createdAt).toLocaleString('en-US'),
                lastChanged: new Date(elem.lastChanged).toLocaleString('en-US'),
                active: changedActivity.length > 0
                    ? changedActivity[changedActivity.length - 1].active
                    : elem.active
            };
        });

        return normalizedCuratedSetResult.filter(elem => !deletedList.includes(elem.id));
    }, [deletedList, changedActivityList, curatedSetResult]);

    useEffect(() => {
        clearTimeout(timer);
        getCuratedSets(fields);
        timer = setTimeout(() => getCuratedSets(fields), 1000);
        return () => clearTimeout(timer);
    }, [fields, currentCuratedSet, deletedList]);

    const handleApproveDeletingSet = (curatedSetId) => {
        deleteCuratedSet({curatedSetId});
        setDeletedList([...deletedList, curatedSetId]);
        handleCloseDeleteDialog();
    };
    const handleCloseDeleteDialog = () => {
        editElement([null, null]);
    }

    return (
        <div className="col-xl-12">
            {dialogType !== 'Tracks' && <div className="m-portlet">
                <WidgetHead label="FRP Media"/>
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                    <TextField
                        value={fields.searchQuery}
                        name="Search"
                        label="Search"
                        type="text"
                        placeholder="Search"
                        className="w-25 mr-5 mt-3"
                        onChange={(event) => handleSetFields('searchQuery', fields, event, setFields)}
                    />
                    <div className='d-flex flex-row w-50'>
                        <SelectGenreAndCategory onChange={(data) => setFields({...fields, ...data})}
                                            genreId={fields.genreId}
                                            categoryId={fields.categoryId}/>
                    </div>
                </div>
                <TableWithPaginator
                    tableWidth={['50px', '300px', '150px', '150px', '150px', '150px', '150px', '500px']}
                    buttons={[
                        {
                            label: elem => elem.active ? 'Hide' : 'Publish',
                            action: elem => {
                                setCuratedActivity({
                                    curatedSetId: elem.id,
                                    curatedActivity: !elem.active
                                });
                                setChangedActivityList(
                                    [...changedActivityList, {id: elem.id, active: !elem.active}]
                                );
                            },
                            className: elem => elem.active ? 'btn-primary' : 'btn-danger'
                        },
                        {
                            label: 'Edit',
                            action: elem => editElement(['Edit', {
                                curatedSetId: elem.id, ...elem
                            }]),
                            className: 'btn-primary'
                        },
                        {
                            label: 'Tracks',
                            action: elem => editElement(['Tracks', {
                                curatedSetId: elem.id, ...elem
                            }]),
                            className: 'btn-primary'
                        },
                        {
                            label: 'Delete',
                            action: elem => editElement(['Delete', {
                                curatedSetId: elem.id, ...elem
                            }]),
                            className: 'btn-primary'
                        }
                    ]}
                    tableElems={['id', 'name', 'categoryName', 'genreName', 'createdAt', 'lastChanged']}
                    tableLabel="Curated sets"
                    headers={['id', 'name', 'category', 'genre', 'createdAt', 'lastChanged', 'Actions']}
                    headButton={{
                        label: 'Add curated set', onClick: () => {
                            editElement(['Edit', {}])
                        }
                    }}
                    data={curatedSetList}
                    offset={fields.offset}
                    limit={fields.limit}
                    count={curatedSetResult ? curatedSetResult.count : 0}
                    options={[{value: 5, text: '5'}, {value: 10, text: '10'},
                        {value: 20, text: '20'}, {value: 50, text: '50'}]}
                    offsetOnChange={(value) => handleSetFields('offset', fields, {target: {value}}, setFields)}
                    limitOnChange={(event) => handleSetFields('limit', fields, event, setFields)}/>
            </div>}

            {dialogType === 'Edit' &&
                <AddCuratedSetDialog onClose={() => editElement([null, null])}
                                 onCreate={(data) => {
                                     if (data.curatedSetId) {
                                         editCuratedSet(data)
                                     } else {
                                         createCuratedSet(data);
                                     }
                                     editElement([null, null])
                                 }}
                                 curatedSet={currentCuratedSet}/>}

            {dialogType === 'Tracks' &&
                <TracksContainer onClose={() => editElement([null, null])} curatedSet={currentCuratedSet}/>}

            {dialogType === 'Delete' &&
                <DeleteCuratedSetDialog
                    curatedSet={currentCuratedSet}
                    onClose={handleCloseDeleteDialog}
                    onApprove={handleApproveDeletingSet}
                />
            }
        </div>
    )
}

function handleSetFields(key, fields, event, handler) {
    const newFields = {...fields};
    const value = event.target.value;
    newFields[key] = (key === 'offset' || key === 'limit') ? parseInt(value) : value;
    if (key !== 'offset') newFields.offset = 0;
    handler(newFields);
}


export default withRouter(CuratedSetComponent);
