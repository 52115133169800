import React, { useContext, useState, useEffect } from "react";
import { TableWithPaginator, WidgetHead } from "components/common/DisplayElems";
import { TextField, Select, Datepicker } from "components/common/Form";
import { ContentContext } from "context/ContentContext";
import { Button, Error } from "components/common/ControlElems";
import BillingDialog from "./BillingDialog";
import DeactivateDialog from "components/common/Dialogs/DeactivateDialog";
import UpdateStatus from "./DetailsConstants";
import styles from "./DJUserDetails.module.scss";
import classnames from "classnames";
import _ from "lodash";

const DEFAULT_FILTERS = {
    login: "",
    fullName: "",
    email: "",
    facebookUrl: "",
    twitterUrl: "",
    instagramUrl: "",
    snapchatUrl: "",
    company: "",
    street: "",
    country: "",
    city: "",
    state: "",
    region: "",
    phone: "",
    active: 0
};

const DEFAULT_TRANS_FILTER = {
    id: null,
    transactionId: null,
    type: "",
    startDate: "1970-01-01",
    endDate: new Date().toISOString(),
    transactionStatus: "",
    invoiceNumber: "",
    lastFourCardDigit: "",
    amount: "",
    subscriptionId: "",
    userId: -1,
    offset: 0,
    limit: 5
};

export default function DJUserDetails({
    match: {
        params: { id }
    }
}) {
    const {
        djUserDetailsResult,
        getDJUserDetails,
        forceUpdatePassword,
        forceUpdatePasswordResult,
        updateProfileById,
        updateProfileByIdResult,
        djUsersTransHistoryResult,
        djUsersTransHistoryProcessing,
        getDJUserTransHistory
    } = useContext(ContentContext);
    const [fields, setFields] = useState(DEFAULT_FILTERS);
    const [transFields, setTransFields] = useState(DEFAULT_TRANS_FILTER);
    const [password, setPassword] = useState({ new: "", confirm: "" });
    const [commonError, setCommonError] = useState("");
    const [infoChanged, setInfoChanged] = useState(false);
    const [billingDialog, setBillingDialog] = useState(false);
    const [deactivateDialog, setDeactivateDialog] = useState(false);
    useEffect(() => {
        setFields(DEFAULT_FILTERS);
        getDJUserDetails(id);
        setTransFields({ ...DEFAULT_TRANS_FILTER, userId: id });
    }, [id]);

    useEffect(() => {
        if (transFields.userId > 0) {
            getDJUserTransHistory(transFields);
        }
    }, [transFields.userId, transFields.limit, transFields.offset]);

    useEffect(() => {
        if (djUserDetailsResult) {
            setFields(djUserDetailsResult);
            setInfoChanged(false);
        }
    }, [djUserDetailsResult]);

    return (
        <div className="col-xl-12">
            <div className="m-portlet">
                <WidgetHead
                    label="DJ User Detail"
                    button={{
                        label:
                            updateProfileByIdResult ===
                            UpdateStatus.UPDATE_PROCESSING
                                ? "Saving..."
                                : "Save",
                        onClick: e =>
                            handleUpdateUser(
                                e,
                                id,
                                fields,
                                updateProfileById,
                                setDeactivateDialog
                            ),
                        disabled:
                            !infoChanged ||
                            updateProfileByIdResult ===
                                UpdateStatus.UPDATE_PROCESSING
                    }}
                />
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                    <div className={classnames(styles["left-panel"])}>
                        <TextField
                            value={fields.login}
                            name="Login"
                            label="Login"
                            type="string"
                            placeholder="Login"
                            onChange={event =>
                                handleSetFields(
                                    "login",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.fullName}
                            name="FullName"
                            label="FullName"
                            type="text"
                            placeholder="FullName"
                            onChange={event =>
                                handleSetFields(
                                    "fullName",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.email}
                            name="E-mail"
                            label="E-mail"
                            type="text"
                            placeholder="E-mail"
                            onChange={event =>
                                handleSetFields(
                                    "email",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.facebookUrl}
                            name="Facebook"
                            label="Facebook"
                            type="text"
                            placeholder="Facebook"
                            onChange={event =>
                                handleSetFields(
                                    "facebookUrl",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.twitterUrl}
                            name="Twitter"
                            label="Twitter"
                            type="string"
                            placeholder="Twitter"
                            onChange={event =>
                                handleSetFields(
                                    "twitterUrl",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.street}
                            name="Street"
                            label="Street"
                            type="text"
                            placeholder="Street"
                            onChange={event =>
                                handleSetFields(
                                    "street",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.country}
                            name="Country"
                            label="Country"
                            type="text"
                            placeholder="Country"
                            onChange={event =>
                                handleSetFields(
                                    "country",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.city}
                            name="City"
                            label="City"
                            type="string"
                            placeholder="City"
                            onChange={event =>
                                handleSetFields(
                                    "city",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                    </div>
                    <div className={classnames(styles["right-panel"])}>
                        <TextField
                            value={fields.instagramUrl}
                            name="Instagram"
                            label="Instagram"
                            type="text"
                            placeholder="Instagram"
                            onChange={event =>
                                handleSetFields(
                                    "instagramUrl",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.snapchatUrl}
                            name="Snapchat"
                            label="Snapchat"
                            type="text"
                            placeholder="Snapchat"
                            onChange={event =>
                                handleSetFields(
                                    "snapchatUrl",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.company}
                            name="Company"
                            label="Company"
                            type="text"
                            placeholder="Company"
                            onChange={event =>
                                handleSetFields(
                                    "company",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.state}
                            name="State"
                            label="State"
                            type="text"
                            placeholder="State"
                            onChange={event =>
                                handleSetFields(
                                    "state",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.region}
                            name="Region"
                            label="Region"
                            type="text"
                            placeholder="Region"
                            onChange={event =>
                                handleSetFields(
                                    "region",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <TextField
                            value={fields.phone}
                            name="Phone"
                            label="Phone"
                            type="text"
                            placeholder="Phone"
                            onChange={event =>
                                handleSetFields(
                                    "phone",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                        <Select
                            name="Status"
                            label="Status"
                            value={fields.active}
                            options={[
                                { value: 0, text: "Inactive" },
                                { value: 1, text: "Active Normal" },
                                { value: 10, text: "Active Super" }
                            ]}
                            onChange={event =>
                                handleSetFields(
                                    "active",
                                    fields,
                                    event,
                                    setFields,
                                    djUserDetailsResult,
                                    setInfoChanged
                                )
                            }
                        />
                    </div>
                </div>
                <WidgetHead label="Billing" />
                <div className="pl-xl-4 pt-xl-4 col-md-6 ">
                    <Button
                        className="mt-4"
                        label={"Charge"}
                        disabled={forceUpdatePasswordResult === "Processing"}
                        onClick={event => setBillingDialog(true)}
                    />
                </div>
                <WidgetHead label="Reset Password" />
                <div className="pl-xl-4 pt-xl-4 col-md-6 ">
                    <TextField
                        value={password.new}
                        name="Password"
                        label="Password"
                        type="text"
                        placeholder="Password"
                        onChange={event =>
                            setPassword({
                                new: event.target.value,
                                confirm: password.confirm
                            })
                        }
                    />
                    <TextField
                        value={password.confirm}
                        name="ConfirmPassword"
                        label="ConfirmPassword"
                        type="text"
                        placeholder="ConfirmPassword"
                        onChange={event =>
                            setPassword({
                                confirm: event.target.value,
                                new: password.new
                            })
                        }
                    />
                    <Error
                        text={
                            forceUpdatePasswordResult
                                ? forceUpdatePasswordResult
                                : commonError
                        }
                    />
                    <Button
                        className="mt-4"
                        label={
                            forceUpdatePasswordResult === "Processing"
                                ? "Updating..."
                                : "Reset Password"
                        }
                        disabled={forceUpdatePasswordResult === "Processing"}
                        onClick={event =>
                            handleResetPassword(
                                event,
                                id,
                                password,
                                setCommonError,
                                forceUpdatePassword
                            )
                        }
                    />
                </div>
                <WidgetHead
                    label="Filter"
                    button={{
                        label: djUsersTransHistoryProcessing
                            ? "Searching..."
                            : "Search",
                        onClick: e => getDJUserTransHistory(transFields),
                        disabled: djUsersTransHistoryProcessing
                    }}
                />
                <div className="d-flex flex-row justify-content-sm-start pl-xl-4 pt-xl-4">
                    <div className={classnames(styles["left-panel"])}>
                        <Datepicker
                            value={transFields.startDate}
                            name="startDate"
                            label="Start Date"
                            placeholder="Start Date"
                            onChange={date =>
                                handleDatePicker(
                                    "startDate",
                                    transFields,
                                    date,
                                    setTransFields
                                )
                            }
                        />
                        <Datepicker
                            value={transFields.endDate}
                            name="endDate"
                            label="End Date"
                            placeholder="End Date"
                            onChange={date =>
                                handleDatePicker(
                                    "endDate",
                                    transFields,
                                    date,
                                    setTransFields
                                )
                            }
                        />
                    </div>
                    <div className={classnames(styles["right-panel"])} />
                </div>
                <div className="pl-xl-4 pt-xl-4">
                    {
                        <TableWithPaginator
                            tableElems={[
                                "transactionId",
                                "userName",
                                "email",
                                "address",
                                "city",
                                "state",
                                "country",
                                "type",
                                "submitDate",
                                "transactionStatus",
                                "lastFourCardDigit",
                                "amount",
                                "subscriptionId",
                            ]}
                            tableLabel="Transaction"
                            headers={[
                                "TransId",
                                "Name",
                                "Email",
                                "Address",
                                "City",
                                "State",
                                "Country",
                                "Type",
                                "Date",
                                "Status",
                                "LastDigit",
                                "Amount",
                                "SubId",
                            ]}
                            data={djUsersTransHistoryResult.list}
                            offset={transFields.offset}
                            limit={transFields.limit}
                            count={djUsersTransHistoryResult.count}
                            options={[
                                { value: 5, text: "5" },
                                { value: 10, text: "10" },
                                { value: 20, text: "20" },
                                { value: 50, text: "50" }
                            ]}
                            offsetOnChange={value =>
                                handleSetTransFields(
                                    "offset",
                                    transFields,
                                    { target: { value } },
                                    setTransFields
                                )
                            }
                            limitOnChange={event =>
                                handleSetTransFields(
                                    "limit",
                                    transFields,
                                    event,
                                    setTransFields
                                )
                            }
                        />
                    }
                </div>
            </div>
            {billingDialog && (
                <BillingDialog
                    onClose={event => setBillingDialog(false)}
                    userId={id}
                    transHistory={djUsersTransHistoryResult}
                    setUserInfo={setFields}
                    userInfo={fields}
                />
            )}
            {deactivateDialog && (
                <DeactivateDialog
                    onClose={event => setDeactivateDialog(false)}
                    onDeactivate={event =>
                        handleDeactivate(
                            id,
                            fields,
                            updateProfileById,
                            setDeactivateDialog
                        )
                    }
                />
            )}
        </div>
    );
}

function handleResetPassword(e, id, password, handler, forceUpdatePassword) {
    if (password.new.length < 6) {
        handler("Password must be more than 6 characters");
        return;
    }
    if (password.new !== password.confirm) {
        handler("Password does not matched!");
        return;
    }
    handler("");
    forceUpdatePassword({ id, password: password.new });
}

function handleSetFields(
    key,
    fields,
    event,
    handler,
    initState,
    setInfoChanged
) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = value;
    if (key === "active") newFields[key] = parseInt(value);
    setInfoChanged(!_.isEqual(newFields, initState));
    handler(newFields);
}

function handleSetTransFields(key, fields, event, handler) {
    const newFields = { ...fields };
    const value = event.target.value;
    newFields[key] = value;
    if (key !== "offset") newFields.offset = 0;
    handler(newFields);
}

function handleDatePicker(key, fields, date, handler) {
    const newFields = { ...fields };
    const value = `${date.getFullYear()}-${date.getMonth() +
        1}-${date.getDate()}`;
    newFields[key] = value;
    if (key !== "offset") newFields.offset = 0;
    handler(newFields);
}

function handleUpdateUser(
    event,
    id,
    fields,
    updateProfileById,
    setDeactivateDialog
) {
    const { allow_user, active } = fields;
    if ((allow_user === 10 || allow_user === 1) && active === 0) {
        setDeactivateDialog(true);
        return;
    }
    updateProfileById(id, fields);
}

function handleDeactivate(id, fields, updateProfileById, setDeactivateDialog) {
    setDeactivateDialog(false);
    updateProfileById(id, fields);
}
