import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from "react-router-dom";
import {Button} from "components/common/ControlElems";
import {AuthContext} from "context/AuthContext";

import {TracksContext} from 'context/TracksContext';

const TRACK_UPLOAD_STATUSES = {
    upload: { text: 'Uploading...', color: 'blue'},
    inQueue: { text: 'Waiting to upload', color: 'yellow'},
    requiredInfo: { text: 'Requires updated info to upload', color: 'orange'},
    error: { text: 'Failed to upload', color: 'red'}
};

Navbars.propTypes = {
    user: PropTypes.object
};

export default function Navbars({children, user}) {
    let result = children;
    const {logout} = useContext(AuthContext);
    const {tracksToUpload} = useContext(TracksContext);

    if (user) {
        result = (<div>
            <header
                id="m_header"
                className="m-grid__item m-header" m-minimize-offset="200"
                m-minimize-mobile-offset="200">

                <div className="m-container m-container--fluid m-container--full-height">
                    <div className="m-stack m-stack--ver m-stack--desktop">
                        <div className="m-stack__item m-brand  m-brand--skin-dark ">
                            <div className="m-stack m-stack--ver m-stack--general">
                                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                                    <a href="javascript:;" id="m_aside_left_minimize_toggle"
                                       className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block">
                                        <span></span>
                                    </a>
                                    <a href="javascript:;" id="m_aside_left_offcanvas_toggle"
                                       className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block">
                                        <span></span>
                                    </a>
                                    <a href="javascript:;" id="m_aside_header_topbar_mobile_toggle"
                                       className="m-brand__icon m--visible-tablet-and-mobile-inline-block">
                                        <i className="flaticon-more"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="m-stack__item m-stack__item--fluid m-header-head" id="m_header_nav">
                            <div id="m_header_topbar"
                                 className="m-topbar  m-stack m-stack--ver m-stack--general m-stack--fluid  mr-xl-5">
                                <div className="m-stack__item m-stack__item--middle m-topbar__nav-wrapper">
                                    <Button
                                        label="Log Out"
                                        onClick={logout}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
				<button className="m-aside-left-close  m-aside-left-close--skin-dark "
                        id="m_aside_left_close_btn">
                    <i className="la la-close"/>
				</button>

				<div
                    id="m_aside_left"
                    className="m-grid__item	m-aside-left  m-aside-left--skin-dark ">
					<div
                        id="m_ver_menu"
                        className="m-aside-menu  m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark "
                        m-menu-vertical="1"
                        m-menu-scrollable="1"
                        m-menu-dropdown-timeout="500"
                        style={{position: 'relative'}}>

						<ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
							<li
                                className="m-menu__item  m-menu__item--active"
                                aria-haspopup="true">
                                <NavLink
                                    to="/"
                                    className="m-menu__link ">
                                    <i className="m-menu__link-icon flaticon-line-graph"/>
                                    <span className="m-menu__link-title">
                                        <span className="m-menu__link-wrap">
                                            <span className="m-menu__link-text">Dashboard</span>
									    </span>
                                    </span>
                                </NavLink>
							</li>
							<li className="m-menu__item  m-menu__item--submenu"
                                aria-haspopup="true"
                                m-menu-submenu-toggle="hover">
                                <NavLink to="/banner/all" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">All Banners</span>
                                </NavLink>
                                <NavLink to="/banner/add" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Add Banner</span>
                                </NavLink>
                                <NavLink to="/splash" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Splash</span>
                                </NavLink>
                                <NavLink to="/heavyRotation" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Heavy Rotation</span>
                                </NavLink>
                                <NavLink to="/feedback" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Feedback profile</span>
                                </NavLink>
                                <NavLink to="/tracks" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">{getTracksStatus(tracksToUpload)}</span>
                                </NavLink>
                                <NavLink to="/djusers" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">DJ Users</span>
                                </NavLink>
                                <NavLink to="/runScripts" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Run Scripts</span>
                                </NavLink>
                                <NavLink to="/declinedTransactions" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Declined transactions</span>
                                </NavLink>
                                <NavLink to="/curatedSets" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Curated Sets</span>
                                </NavLink>
                                <NavLink to="/invalidusers" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Invalid Users</span>
                                </NavLink>
                                <NavLink to="/paypaltransaction" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Paypal Transaction</span>
                                </NavLink>
                                <NavLink to="/paypalhistory" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Transactions History</span>
                                </NavLink>
                                <NavLink to="/processemotions" className="m-menu__link m-menu__toggle">
                                    <span className="m-menu__link-text">Process Emotions</span>
                                </NavLink>
							</li>
						</ul>
					</div>
				</div>

				<div className="m-grid__item m-grid__item--fluid m-wrapper">
                    <div className="m-content ">
                        {children}
                    </div>
				</div>
			</div>
        </div>);
    }

    return result;
}

function getTracksStatus(tracksToUpload) {
    if (tracksToUpload.length === 0) {
        return 'Tracks';
    }
    const errorCount = tracksToUpload.reduce((result, elem) => {
        if (elem.status && elem.status === TRACK_UPLOAD_STATUSES.error.text) {
            result += 1;
        }
        return result
    }, 0);

    const uploadCount = tracksToUpload.reduce((result, elem) => {
        if (elem.metadata && elem.metadata.status === TRACK_UPLOAD_STATUSES.upload.tex) {
            result += 1;
        }
        return result
    }, 0);

    const waitingCount = tracksToUpload.reduce((result, elem) => {
        if (elem.metadata && elem.metadata.status === TRACK_UPLOAD_STATUSES.inQueue.tex) {
            result += 1;
        }
        return result
    }, 0);

    const invalidCount = tracksToUpload.reduce((result, elem) => {
        if (elem.metadata && elem.metadata.status === TRACK_UPLOAD_STATUSES.requiredInfo.tex) {
            result += 1;
        }
        return result
    }, 0);

    return <div>Tracks<br/>
        {uploadCount > 0 && <span style={{marginLeft: '0.25pm'}}>U:{uploadCount}</span>}
        {waitingCount > 0 && <span style={{marginLeft: '0.25pm'}}>W:{waitingCount}</span>}
        {invalidCount > 0 && <span style={{marginLeft: '0.25pm'}}>R:{invalidCount}</span>}
        {errorCount > 0 && <span style={{marginLeft: '0.25pm'}}>E:{errorCount}</span>}
    </div>

}
