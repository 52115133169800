import React, {useState, useContext, useEffect} from 'react';

export default function VideoList({artistResult, setDeletedElem, updateArtistVideo}) {
    return (
        <div key="coverVideos" className="m-list col-lg-6">
            {artistResult && artistResult.coverVideo.map(coverVideo => {
                return (<div
                    key={coverVideo.id}
                    className=" m-xl-2 m-list-badge__item mr-xl-5 d-flex flex-row justify-content-between align-items-center">
                    <div>
                        <span>Link: </span>
                        <span>{coverVideo.link}</span>
                    </div>
                    <div className="mr-xl-4 flex-shrink-0 d-flex flex-row justify-content-sm-start align-items-center">
                        <button
                            className="btn btn-danger mr-xl-5"
                            onClick={() => setDeletedElem(coverVideo)}>Remove</button>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    checked={Boolean(coverVideo.active)}
                                    onChange={() => {
                                        updateArtistVideo({
                                            id: coverVideo.id,
                                            artistId: artistResult.id,
                                            active: !coverVideo.active});
                                    }}
                                    className="checkbox form-check-input"/>
                                    Make the cover video
                            </label>
                        </div>
                    </div>
                </div>);
            })}
        </div>
    )
}
