import React, {useState, useCallback} from 'react';
import {TextField, Select, Checkbox} from 'components/common/Form';
import {Button} from "components/common/ControlElems";

import scriptsData from "./scriptsData";

const componentFromType = {
    [TextField.type]: TextField,
    [Checkbox.type]: Checkbox
};

const defaultScriptName = 'Not set';

function translateScriptsDataToOptions() {
    return Object.keys(scriptsData).map(scriptName => {
        return {value: scriptName, text: scriptName}
    })
}

function renderFields(fields, args, updateArgs) {
    return fields.map((field, index) => {
        const FieldComponent = componentFromType[field.type];
        return <FieldComponent
            key={index}
            value={args[index]}
            label={field.name}
            className="w-25 mr-5"
            placeholder={field.name}
            onChange={(event) => {
                updateArgs(event, field.type, index)
            }}
        />
    })
}

export default ({runScript, isProcessing, stopScript}) => {
    const [scriptName, setScriptName] = useState(defaultScriptName);
    const [args, setArgs] = useState([]);

    const onRunScript = useCallback(() => {
        runScript({scriptName, args});
        setScriptName(defaultScriptName);
        setArgs([]);
    }, [scriptName, args]);

    const updateArgs = useCallback((event, fieldType, index) => {
        const newArgs = [...args];
        switch (fieldType) {
            case Checkbox.type:
                newArgs[index] = !args[index];
                break;
            case TextField.type:
                newArgs[index] = event.target.value;
                break;
            default:
                throw new Error('unknown fieldType');
        }

        setArgs(newArgs)
    });

    const fields = scriptsData[scriptName] && scriptsData[scriptName].inputFields;
    const isButtonDisabled = (scriptName === defaultScriptName) && !isProcessing;

    return <>
        <div className="runScriptDiv">
            <Select name="Active"
                    label="Active"
                    className=""
                    value={scriptName}
                    options={[{value: defaultScriptName, text: defaultScriptName}, ...translateScriptsDataToOptions()]}
                    onChange={(event) => {
                        setScriptName(event.target.value)
                    }}/>
            <Button
                className='runScriptButton'
                label={isProcessing ? 'Stop script' : 'Run script'}
                disabled={isButtonDisabled}
                onClick={isProcessing ? stopScript : onRunScript}/>
        </div>
        {fields && renderFields(fields, args, updateArgs)}
    </>
}
