import React, {useState, useCallback} from 'react';
import classnames from 'classnames';

import {BaseDialog} from "components/common/Dialogs";
import styles from "./AddCuratedSetDialog.module.scss";
import {UploadButton} from 'components/common/ControlElems';
import {TextField} from 'components/common/Form';
import SelectGenreAndCategory from "../ControlElems/SelectGenreAndCategory";
import {CATEGORY_ID, GENRE_ID} from "../../../helpers/constants";

export default function CreateCuratedSetDialog({onClose, onCreate: onSave, curatedSet = {}}) {
    const [imageSrc, setImageSrc] = useState(curatedSet.image);
    const [curatedSetData, setCuratedSetData] = useState({...curatedSet, files: !!curatedSet.image});
    const [wrongFields, setWrongFields] = useState({genreId: false, categoryId: false, name: false, files: false});

    const fieldChecker = key => {
        return curatedSetData[key] === undefined || curatedSetData[key] === false;
    };

    const onPressCreate = useCallback(() => {
        const currentWrongFields = {...wrongFields};
        Object.keys(currentWrongFields).forEach(key => {
            currentWrongFields[key] = fieldChecker(key);
        });
        setWrongFields(currentWrongFields);

        if (Object.values(currentWrongFields).includes(true)) return;
        onSave(curatedSetData);
    }, [curatedSetData, wrongFields]);

    const isFieldWrong = useCallback(key => wrongFields[key] && fieldChecker(key), [wrongFields, curatedSetData]);

    const safeNewFieldValue = useCallback(data => {
        setCuratedSetData({...curatedSetData, ...data});
    }, [curatedSetData]);

    const setNewImage = useCallback(({target: {files}}) => {
        const reader = new FileReader();
        reader.onload = function () {
            setImageSrc(reader.result);
        };
        reader.readAsDataURL(files[0]);
        safeNewFieldValue({files});
    }, [curatedSetData]);

    return (
        <BaseDialog
            id="curatedDialog"
            label="Curated set editor"
            classes={styles["custom-modal-style"]}
            onClose={onClose}>
            <div className="d-flex form-group justify-content-between">
                <div className="col-md-4">
                    <h4>Avatar</h4>
                    <div>
                        <img
                            key={curatedSet.image || 'nokey'}
                            className={classnames("img-thumbnail rounded m-widget3__img", {wrongField: isFieldWrong('files')})}
                            style={{height: '200px', minWidth: '200px'}}
                            src={imageSrc}/>
                        <UploadButton
                            label="Upload new"
                            onChange={setNewImage}/>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className={styles.header}>
                        <h4>Properties</h4>
                    </div>
                    <TextField
                        value={curatedSetData.name}
                        name="Name"
                        label="Name"
                        type="text"
                        placeholder="Name"
                        className={classnames({wrongField: isFieldWrong('name')})}
                        onChange={({target: {value}}) => {
                            safeNewFieldValue({name: value})
                        }}
                    />
                    <SelectGenreAndCategory
                        genreId={curatedSetData.genreId}
                        categoryId={curatedSetData.categoryId}
                        isWrong={isFieldWrong(GENRE_ID) || isFieldWrong(CATEGORY_ID)}
                        onChange={(data) => safeNewFieldValue(data)}
                    />
                </div>
            </div>

            <div className="d-flex flex-row justify-content-between">
                    <button
                        type="button"
                        className="btn btn-primary w-25"
                        onClick={() => onClose()}
                        data-dismiss="modal">
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="btn btn-danger w-25"
                        onClick={() => onPressCreate()}
                        data-dismiss="modal">
                        {curatedSet.curatedSetId ? 'Edit' : 'Create'}
                    </button>
            </div>

        </BaseDialog>)
}
