import React from 'react';

export default function Head({label, button}) {
    return (
        <div className="m-portlet__head w-100">
                <div className="m-portlet__head-caption w-100">
                    <div className="m-portlet__head-title d-flex flex-row justify-content-between w-100">
                        <h3 className="m-portlet__head-text">
                            {label}
                        </h3>
                        {button && button.label && 
                            <button
                                className="btn btn-primary"
                                onClick={button.onClick}
                                disabled={button.disabled}>
                                {button.label}
                            </button>
                        }
                    </div>
                </div>
            </div>
    );
}
