import React from 'react';
import PropTypes from 'prop-types';

Container.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default function Container({handleSubmit, className, children}) {
    return (
        <form className={className + " m-form"} onSubmit={handleSubmit} noValidate="novalidate">
            {children}
        </form>
    )
}
