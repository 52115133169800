import React, { useEffect, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

Dialog.propTypes = {
    children: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    classes: PropTypes.string
};

export default function Dialog({ children, onClose, label, id, classes }) {
    return (
        <div
            className={classnames("modal show")}
            aid={id}
            arial-model="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0, 0.3)" }}
        >
            <div
                className={classnames("modal-dialog modal-dialog-centered", {
                    [classes]: classes
                })}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{label}</h4>
                    </div>
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
}
