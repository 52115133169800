import React, {useContext, useState, useEffect} from 'react';
import {Select, Checkbox} from "components/common/Form";
import {Tabs} from "components/common/ControlElems";
import {WidgetHead} from 'components/common/DisplayElems';
import {FeedbackContext} from "context/FeedbackContext";


const STARS = ['1', '2', '3', '4', '5'];
const OPTIONS = [{text: "LOW", value: "low"}, {text: "MEDIUM", value: "medium"}, {text: "HIGH", value: "high"}];
const FEEDBACK_OPTIONS = [
    {text: "1", value: "1"},
    {text: "2", value: "2"},
    {text: "3", value: "3"},
    {text: "4", value: "4"},
    {text: "5", value: "5"}];

const FEEDBACK_CHECKBOXES = [
    {text: "Radio play", value: "radioPlay"},
    {text: "Club play", value: "clubPlay"},
    {text: "Gig play", value: "gigPlay"},
    {text: "Push more", value: "pushMore"},
    {text: "Make video", value: "makeVideo"},
    {text: "Buy", value: "buy"}
]


export default function Feedback() {
    const {feedbackProfilesResult, getFeedbacks, updateFeedback, starsResult, getStars, updateStar} = useContext(FeedbackContext);

    useEffect(() => {
        getFeedbacks();
        getStars();
    }, []);

    const [currentTab, setCurrentTab] = useState('low');

    return (
        <div className="col-xl-12">
            <div className="m-portlet pd-xl-5 pb-xl-5">
                <WidgetHead label="Feedback profile"/>
                <div className="m-xl-4">
                    {STARS.map(star => {
                        return (
                            <div
                                key={star}
                                className="m-xl-4 d-flex flex-row align-items-center justify-content-start">
                                <span>{`${star} star${star > 1 ? 's' : ' '}`}</span>
                                <Select
                                    className="ml-xl-4"
                                    options={OPTIONS}
                                    value={getStarValue(starsResult, star)}
                                    onChange={(event => updateStar({value: event.target.value, type: star}))}/>
                            </div>
                        );
                        }
                    )}
                </div>
                <div className="m-xl-5">
                    <Tabs
                        tabs={OPTIONS}
                        className="mb-xl-5"
                        onChoose={setCurrentTab}
                        current={currentTab}/>
                    <FeedbackProfile
                        updateFeedback={updateFeedback}
                        feedbackProfile={getCurrentFeedbackProfile(feedbackProfilesResult, currentTab)}
                    />
                </div>
            </div>
        </div>
    )
}

function FeedbackProfile({feedbackProfile, updateFeedback}) {
    return (
        <div>
            <div className="d-flex flex-row justify-content-start align-items-lg-start flex-wrap">
                    {['vocal', 'beat', 'dj'].map(type => {
                        return (
                            <div
                                key={type}
                                className="m-xl-4 d-flex flex-column align-items-center justify-content-start">
                                <span>{type.toUpperCase()}</span>
                                <Select
                                    className="ml-xl-4"
                                    options={FEEDBACK_OPTIONS}
                                    value={feedbackProfile[type]}
                                    onChange={(event => {
                                        updateFeedback({value: event.target.value, key: type, type: feedbackProfile.type});
                                    })}/>
                            </div>
                        );
                        }
                    )}
                    {FEEDBACK_CHECKBOXES.map(checkbox => {
                        return (
                            <div
                                key={checkbox.value}
                                className="m-xl-4 d-flex flex-column align-items-center justify-content-start">
                                <span>{checkbox.text}</span>
                                <Checkbox
                                    className="ml-xl-4"
                                    value={feedbackProfile[checkbox.value]}
                                    onChange={ value => {
                                        updateFeedback({value: !feedbackProfile[checkbox.value], key: checkbox.value, type: feedbackProfile.type})
                                    }}/>
                            </div>

                        )
                    })}
                </div>
        </div>
    );
}

function getCurrentFeedbackProfile(feedbackProfilesResult, currentTab) {
    let result = {};
    if (feedbackProfilesResult) {
        const feedbackProfile = feedbackProfilesResult.find(elem => elem.type === currentTab);
        result = feedbackProfile ? feedbackProfile  : result;
    }

    return result;
}

function getStarValue(stars, star) {
    let result = 'low';
    if (stars) {
        const starObject = stars.find(elem => elem.type === star);
        result = starObject ? starObject.feedbackProfileType : result;
    }

    return result;
}


